import logo1 from "../images/logoremove.png";
import present1 from "../images/human22.png";
import {AiOutlineSchedule} from "react-icons/ai";
import OnAir from "./onair";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import useFectch from "../adminpage/createbackend";
import {mainaxios} from "../../utility/axios"
import Programs from "../directpages/programs";
// import Player from "../../player";
const ModeHeader=()=>{

  
    // const {data,isPending,error} = useFectch("programs/getPrograms")
    // console.log("programs>>>>>>>>>",data)
    
    const [programs, setPrograms] = useState([]);
    const [currentProgram, setCurrentProgram] = useState(null);
    // const [error, setError] = useState(null);
    
        

    useEffect(()=>{
        mainaxios.get("programs/getPrograms")
        .then(response=>{
          
          if (!response.statusText=== "OK"){
            throw Error("Could not fetch data")
          }
          setPrograms(response.data)
          
        })
        .catch(error =>{
       
        })
       },[]);

       useEffect(() => {
        const updateCurrentProgram = () => {
        const now = new Date();
        const currentTime = now.getHours() + ':' + now.getMinutes();
        
        if (Array.isArray(programs)) {
        const currentProgram = programs.find(
        (program) =>
        currentTime >= program.programStartTime &&
        currentTime <= program.programEndTime
        );
        setCurrentProgram(currentProgram);
        } else {
        console.error("programs is not an array");
        }
        };
        
        updateCurrentProgram();
        const updateInterval = setInterval(() => {
        updateCurrentProgram();
        }, 1000);
        
        return () => clearInterval(updateInterval); // Clean up the interval on component unmount
        }, [programs]);
        

      
            
return( 
    <>

         <div className="container-header">
           <div className="all-modeprogram">
            <div className="second-div-container">
                <NavLink to="/" className='navigateicon-home'> <img className="logo1" src={logo1} /></NavLink>
                <div className=" presenter22">
                    {
                        currentProgram ? (

                           <div> <img className="img-present" src={currentProgram.programpresenters && currentProgram.programpresenters.map((image) => (image.image))} /></div>

                        ) : (
                            <img className="img-present" src={present1} />
                        )
                    }

                </div>


            </div>

             
                  <OnAir />  
             
            </div>
           
        </div> 
    </>
)

}
export default ModeHeader
