
import { RiArrowDropDownLine } from "react-icons/ri"
import { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import useFectch from "./createbackend";
import { mainaxios, fbaxios } from "../../utility/axios"
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdOutlineCancel } from "react-icons/md"


function ViewPodcast() {

  const [action, setAction] = useState('');
  const [show, setShow] = useState(false)
  const [lgShow, setLgShow] = useState(false);

  const [allJobs, setAllJobs] = useState([]);

  const actionButton = () => {
    setAction(!action)


  }

  const { data, isPending, error } = useFectch('jobs/getJobApplications');


  const update = () => {

    mainaxios.get("jobs/getJobApplications")
      .then((res) => {
        setAllJobs(res.data);
      });
  };
  useEffect(update, [allJobs]);



  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const notify = () => toast("Processing your request...");


  // delete applicant
  const [applicantId, setApplicantId] = useState('')

  const toggleDelete = (applicant) => {

    setApplicantId(applicant.jobApplicationId)

    setLgShow(true)
  }
  const [errorMess, setErrorMess] = useState("");
  const [successMessage, setSuccessMessage] = useState("");


  const handleSubmitDelete = (event) => {
    notify()
    event.preventDefault();
    mainaxios.put(`jobs/removeJobApplication/${applicantId}`)
      .then(response => {


        if (response) {
          setSuccessMessage("Applicant Removed Successfully");
         
        } else {

          setErrorMess(data.message);
        }
      })
      .catch(error => {
        console.log(error);
      });

    setLgShow(false)

    handleSubmitDelete();
  }


  const closeErrorClick = (() => {
    setErrorMess(false)
  })


  useEffect(() => {

    let timeout;
    if (successMessage) {
      timeout = setTimeout(() => {
        setSuccessMessage(false);
      }, 4000)
    }
    return () => {
      clearTimeout(timeout);
      
    };

  }, [successMessage])





  return (

    <>


      <div>
        <ul className="action-bar">
          <li>Home /</li>
          <li>Jobs Applied/</li>
          <li className="createem">View Applied Jobs</li>
        </ul>
      </div>

      <h2 className="viewemployee-head">Applicant's list</h2>

      {
        successMessage && <div >

          <button className="btn btn-success toast-success-btn">{successMessage}</button>

        </div>
      }
      {errorMess && <div >
        <button className="btn btn-danger toast-success-btn">{errorMess} <span onClick={closeErrorClick} className="remove-erroricon"><MdOutlineCancel size={25} /></span></button>

      </div>
      }


      <Table striped bordered hover size="sm" className='adaogidi-table'>
        <thead>


          <tr>
            <th>S/N</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email Address</th>
            <th>Applicant's number</th>
            <th>Position</th>
            <th>Company Name</th>


            <th>Cv</th>
            <th>Time Applied</th>




            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {isPending && <div className="loaderless"> <div className="lds-ripple"> Loading... <div></div><div></div></div>

          </div>}
          {
           allJobs && allJobs.map((applicant, i) => (


              <tr key={applicant.jobApplicationId} >
                <td> {i + 1} </td>
                <td>{applicant.firstName}</td>
                <td>{applicant.lastName}</td>
                <td>{applicant.emailAddress}</td>
                <td>{applicant.phoneNumber}</td>
                <td>{applicant.jobPosition}</td>
                <td>{applicant.companyName}</td>
                <td  ></td>
                <td>{moment(applicant.createdDateTime).format('llll')}</td>



                <Dropdown className="d-inline mx-2 firstline-drop " autoClose="outside"  >
                  <Dropdown.Toggle id="dropdown-autoclose-outside">
                    Action
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdownshow-d">

                    <Dropdown.Item className="sideside" onClick={() => toggleDelete(applicant)} >Remove Applicant</Dropdown.Item>

                  </Dropdown.Menu>
                </Dropdown>
              </tr>




            ))
          }
          {/* {isPending && <div className="loaderless"> <div className="lds-ripple"> Loading... <div></div><div></div></div>

</div>} */}
        </tbody>
      </Table>


      <form onSubmit={handleSubmitDelete}>
        <Modal
          size="sm"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Remove Applicant
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are You Sure You Want to Remove this Applicant?</p>
            <button className="btn-remove btn btn-danger" type="submit" onClick={handleSubmitDelete} >Remove Applicant</button>
          </Modal.Body>


        </Modal>
      </form>
    </>
  );
}

export default ViewPodcast;





