
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import Footer from '../layouts/footer';
import { AiOutlineClose, AiOutlineWarning } from 'react-icons/ai';
import ProfileNav from './profilenav';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { useEffect, useRef, useState } from 'react';
import { MdOutlineCancel, MdPerson3 } from 'react-icons/md';
import PayButton from '../PayButton';
import { PaystackButton } from 'react-paystack';
import { Toaster, toast } from 'react-hot-toast';
import { mainaxios, fbaxios } from '../../utility/axios';
// import axios from '../../utility/axios';
import { id } from 'date-fns/locale';
import { clearCart, decrease } from '../../redux/counter';
import useFectch from '../adminpage/createbackend';
import axios from 'axios';
import { IoMdCheckmark, IoMdCheckmarkCircleOutline } from 'react-icons/io';
import BookinPageNav from './booking-page-nav';
import ModeHeader from '../layouts/modeheader';
import Changer from '../layouts/changer';
import { FaShoppingCart } from 'react-icons/fa';
import moment from 'moment';
import OrderReceipt from './receipt';
import ReactToPrint from "react-to-print";
import html2canvas from 'html2canvas';
import { CiSaveDown2 } from 'react-icons/ci';
import { CgProfile } from "react-icons/cg";

// import {AiOutlineClose } from "styled-icons/material/Close";
// import { Menu } from "styled-icons/material/Menu";



let renderCount = 0;




const BookingPage = (() => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  renderCount++;


  const [states, setStates] = useState([])
  const [localGvt, setLocalGvt] = useState([])


  const [showPage, setShowPage] = useState(false);
  const publicKey = "pk_test_0a9e6a807ac7b5d5cc2ccb0a25e5aa429f45a849"
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [additionNumber, setAdditionNumber] = useState();
  const [emailAddress, setEmailAddress] = useState();
  const [deliveryAddress, setDeliveryAddress] = useState();
  const [addtionInfo, setAddtionInfo] = useState();
  const [state, setState] = useState();
  const [city, setCity] = useState('');
  const [amount, setAmount] = useState("");
  const [payonDelivery, setPayOnDelivery] = useState("");
  const [onlinePayment, setOnlinePayment] = useState("")



  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [show, setShow] = useState(false)
  const [lgShow, setLgShow] = useState(false);
  const [newLga, setNewLga] = useState([])
  const [stateName, setStateName] = useState('')
  const [payConfirm, setPayConfirm] = useState("")
  const [orderId, setOrderId] = useState({})
  const [activeState, setActiveState] = useState(false)
  const [pickup, setPickup] = useState("")
  const [paid, setPaid] = useState("Paid")
  const [showform, setShowForm] = useState(true)
  const [productLoad, setProductLoad] = useState([])




  const dispatch = useDispatch();

  const handleClearCart = (() => {
    dispatch(clearCart())
  })

  const handleConPayment = (() => {
    setOrderId(orderId)
    setPayConfirm("Paid")
  })

  // currency format//
  const symbol = Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: 'NGN'
  })
    .formatToParts(0)
    .filter(part => part.type === 'currency')
    .map(part => part.value)
    .join('')
  // 
  const { items, totalAmount} = useSelector((state) => state.cart);

  const handleFirstModal = (() => {
    setModalShow(true);
    setLgShow(false);


  })

  const scrolltop = (() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"

    });
  })
const userData = JSON.parse(localStorage.getItem("ecomUserData"));
// console.log("userData ", userData.state );

  const currentDate = new Date();

  const formatedDate = currentDate.toISOString()

  let componentRef = useRef();

  const navigate = useNavigate()

  const handleCancel = (() => {

    navigate("/ecommerceupper", {})
  })



  const saveDivAsImage = () => {
    const divToCapture = document.getElementById('controlPrint');
    html2canvas(divToCapture).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');

      // Create a link element
      const downloadLink = document.createElement('a');
      downloadLink.href = imgData;
      downloadLink.download = 'div_snapshot.png';
      downloadLink.click();
    });
  };


  useEffect(() => {
    setIsPending(true)
    mainaxios.get(`ecommerce/getUsersProfile_orderEcommerce/${userData.userId}`)
      .then(response => {

        if (!response.statusText === "OK") {
          throw Error("Could not fetch data")
        }
        setIsPending(false)
        // setData(response.data)
        setProductLoad(response.data);
        setError(null)

      })
      .catch(error => {
        setError(error.message)
        setIsPending(false)
        console.log(error)
      })
  }, []);

  const update = () => {

    mainaxios.get(`ecommerce/getUsersProfile_orderEcommerce/${userData.userId}`)
      .then((res) => {
        setProductLoad(res.data);
      
      });
  };
 
  // useEffect(update, [productLoad]);
  // console.log("productLoad",productLoad);
  const firstItem =  productLoad.find(x =>  x!== undefined);

  // const [firstObject] = productLoad

  // const displayemail = firstObject

  const getId = (() => {
    // update()
    setOrderId(firstItem.orderId)
    setPayConfirm("Paid")
    })
   
   
   
  let order;   
  order = orderId;

  const handleConfirmPayment = async () => {
     
    
    const confirm = {
      paymentStatus: payConfirm
    }
    await mainaxios.put(`ecommerce/confirmPaymentStatus/${order}`, confirm)
      .then((res) => {
        console.log("res", res)

        if (res.data.success) {
          setIsPendings(false);



        } else {
          setIsPendings(false);


        }
      }).catch((err) => {
        setIsPendings(false);
        console.log("err", err)

      })


  }
  const [referenceCode, setReferenceCode] = useState("") 
const [confirmPay, setConfirmPay] = useState()

  const Navigate = useNavigate();
  const handlePaystackSuccessAction = (reference) => {
    handleConfirmPayment();
    // console.log("reference",reference);
    setPaid("Paid");
    setShowForm(false);
    handleClearCart();
    setShow(false);
    setSuccess(true);
    setReferenceCode(reference.reference)
    // Navigate("/mainprofile", { state: {} });
    // setConfirmPay("Paid")
  }

  const [alert, setAlert] = useState()
  const handlePaystackCloseAction = () => {
    Navigate("/accountpage", { state: {} });
  };

  const [buttonDisable, setButtonDisable] = useState(true)

  const componentProps = {
    email: emailAddress,
    amount: totalAmount * 100,

    metadata: {

      fullname: firstName,
      phone: phoneNumber,
      additionNumber,
      deliveryAddress,
      addtionInfo,
      state,
      city
    },

    publicKey: "pk_test_0a9e6a807ac7b5d5cc2ccb0a25e5aa429f45a849 ",
    text: 'PAY NOW',
    onSuccess: (reference) => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };
  
  const location = useLocation()

  const handlePayOnDelivery = (() => {
    setShowForm(false)
    setPickup("Pay on Pickup")
    handleClearCart()
    setShow(false)
    setSuccess(true)
  })

  const [success, setSuccess] = useState(false)




  const loggedInCheck = (() => {
    if (userData == 1) {
      return <div>{userData.firstName} logged in!</div>
    }
  })

  useEffect(() => {
    const checkLogin = () => {
      if (userData.statuss === "active") {
        setShowPage(true);
        setEmailAddress(userData.emailAddress)
        loggedInCheck()
        setActiveState(true)
      } else {
        Navigate("/signinpage", {})
      }

    };
    {
      checkLogin() ? <Navigate to="*" /> :
        <Navigate to="/bookingpage" />
    }

  }, []);

  if (activeState === false) {
    Navigate("/*", {})
  }

  const [isPendingState, setIsPendingState] = useState(false)
  useEffect(() => {
    const getState = async () => {
      setIsPendingState(true)
      const resState = await axios.get('https://ipms.intellims.com:5300/api/v1/public/states')

      const relState = await resState.data
      setStates(await relState)
    }

    getState()
  }, [])


  useEffect(() => {
    const getLocalG = async () => {
      setIsPendingState(true)
      const localLga = await axios.get(
        "https://ipms.intellims.com:5300/api/v1/public/local_govt"
      );

      const localGvt = await localLga.data;

      setLocalGvt(localGvt)

    };
    getLocalG()
  }, [])



  const getMainLGA = (e) => {

    const stateId = e.target.value


    const filterLga = localGvt && localGvt.filter((lga) => (
      lga.state_id === stateId
    ))

    setNewLga(filterLga);
  };
  const onChangeHandler = (e) => {
    setState()
    const newStateId = e.target.value
    setState(newStateId)
    const newState = states && states.find((stat) => stat.stateId === newStateId);

    setStateName(newState.name)


  }

  const [disabled, setDisabled] = useState(false)
  const [isPendings, setIsPendings] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");



  const notify = () => toast("Saving order...");


  


  useEffect(() => {
    setIsPending(true)
    fbaxios.get("products/menus/djW7G5kgB43wqdh?locationId=Njw9pXwT3BYIUMc")
      .then(response => {

        if (!response.statusText === "OK") {
          throw Error("Could not fetch data")
        }
        setIsPending(false)
        setData(response.data)

        setError(null)
        // console.log("data>>>>>>>>>",data)
      })
      .catch(error => {
        setError(error.message)
        setIsPending(false)
        console.log(error)
      })
  }, []);





  const selectedFieldItem = []



  const itemsAll = items && items.map((element) => (
    element.fields.map((element) => (

      element.fieldItems.map((field) => (
        selectedFieldItem.push(field)
      ))
    ))
  ))

  //  get token
  const [token, setToken] = useState("")
  const handleGetToken = async () => {

    const detail = {
      password: "ebeonadi",
      email: "ebeonadi@gmail.com"
    }

    await fbaxios.post("auth/login", detail)
      .then((res) => {
      
        setToken(res.data.token)
      }).catch((err) => {

      })
  }
  useEffect(() => {
    handleGetToken()
  }, [])
  
 


  const mainData = {
    serviceStaff: "null", typeOfService: "sales", menuId: data.menuId, openedTableRoomId: "S757FqdcYVMSoRC", registerId: "nzOdjpcqJ59NRU1",
    locationId: "Njw9pXwT3BYIUMc", hotelId: data.hotelId, selectedProductQty: selectedFieldItem,
    products: items && items.map((item) => (
      {
        comboId: item.comboId, comboType: item.comboType, menuId: item.menuId, comboName: item.comboName,
        comboPrice: item.price, price: item.price, hotelId: item.hotelId, fields: item.fields.map((fields) => (
          {
            fieldId: fields.fieldId, fieldName: fields.fieldName, fieldType: fields.fieldType, fulfillmentType: fields.fulfillmentType,
            defaultCheckBox: fields.defaultCheckBox,
            orderNumber: fields.orderNumber, itemRequired: fields.itemRequired, printOnInvoice: fields.printOnInvoice,
            fieldItems: fields.fieldItems.map((field) => (
              {
                fieldItemId: field.fieldItemId, productId: field.productId, productName: field.productName,
                adjustmentPrice: field.adjustmentPrice, quantity: field.quantity,
                currentQuantity: field.currentQuantity, productLocation: field.productLocation, active: field.active
              }
            ))
          }
        )),

        singleCombo: true, quantity: item.quantity, lineTotal: totalAmount
      }

    )),
    totalAmount: totalAmount
  }

  const handleSubmitData = async () => {

    setDisabled(true)
    setIsPendings(true);
    await fbaxios.post("orders/placeOrder", mainData, {
      headers: {
        'x-access-token': token
      }
    })
      .then((res) => {
        console.log("res", res)

        if (res.data.success) {
          setIsPendings(false);
          handleShow()
          // setSuccess(res.data.message)
          setButtonDisable(false)
          setDisabled(false)
          

        } else {
          setIsPendings(false);

          setDisabled(false)
          handleShow()
        }

      }).catch((err) => {
        setIsPendings(false);
        console.log("err", err)
        setErrorMessage("");
        setDisabled(false)
      })
    scrolltop()
  }

const handleSubmited = async (data) => {
    setDisabled(true)
    setIsPendings(true);
    const datas = data.state = stateName
    const fullData = {
      ...data,
      products: items.map((item) => (
        {
          productId: item.comboId, productName: item.comboName,
          picture: item.picture, quantity: item.quantity,
          price: item.price, description: item.description

        }
      ))
    }
    scrolltop()
    await mainaxios.post("ecommerce/createOrder_ebeonadiEcommerce", fullData)
      .then((res) => {
        // console.log("res2", res)

        if (res.data.success) {
          setIsPendings(false);
          localStorage.setItem("logData", JSON.stringify({ fullData }))
          setButtonDisable(false)
          setDisabled(false)
         
        } else {
          setIsPendings(false);
          
          setDisabled(false)
        }

      }).catch((err) => {
        setIsPendings(false);
        // console.log("err", err)
        setErrorMessage("Something went wrong. Please try again later.");
        setDisabled(false)
      })
    update();
    handleSubmitData();
  }

  const closeErrorClick = (() => {
    setErrorMessage(false)
    setSuccess(false)
  })


  useEffect(() => {
    handleGetToken()
  }, [])

  const orderData = JSON.parse(localStorage.getItem("logData"));


    
  
  return (

    <>

      {errorMessage && <div >
        <button className="btn btn-danger toast-success-btn">{errorMessage} <span onClick={closeErrorClick} className="remove-erroricon"><MdOutlineCancel size={25} /></span></button>

      </div>
      }


      <main className='mainbookinpage-all'>
        <div>
          <ModeHeader />
          <Changer />
        </div>
        <div>
          <BookinPageNav />
        </div>
        {
          success &&
          <div className="alert success-alert">
            <h3> <IoMdCheckmarkCircleOutline /> You have successfully placed your order</h3>
            <a className="close" onClick={closeErrorClick}><MdOutlineCancel /></a>
            <Link className='linkRemoveBook' to="/mainprofile">  <span className="closeHomeGo"><CgProfile size={30} /> <span className='hide_ViewProNav'>view profile</span></span></Link>
          </div>
        }

        {isPendings &&
          <div style={{ display: isPendings ? 'flex' : 'none' }} className='modaled'>
            <div className='modal-contents'>
              <div className='loadered'></div>
              <div className='modal-texted'> <span className='processing_Div'>Processing please wait...</span></div>
            </div>
          </div>

        }

        {
          showform ? (

            <div className='booking-container'>

              <div className='bookingform-main'>
                <Form className='booking-form-container' onSubmit={handleSubmit((data, event) => {
                  event.target.reset()
                  setDisabled(true)
                  // console.log("see data",data);
                  handleSubmited(data)
                })} >

                
                  <Row className="mb-3">
                    <Form.Group hidden>
                      {/* <Form.Label className='lb'>First name</Form.Label> */}
                      <Form.Control placeholder="Enter your name"
                        {...register("firstName")}

                        value={userData.firstName}

                      />
                    </Form.Group>

                    <Form.Group   hidden>
                      {/* <Form.Label className='lb' >State <span className="star-required">*</span></Form.Label> */}
                      <Form.Control 
                     {...register("state")}
                     value={userData.state}
                      />
                    </Form.Group>



                    <Form.Group as={Col} controlId="formGridPassword" hidden>
                      <Form.Label className='lb'>Last name</Form.Label>
                      <Form.Control placeholder="Last name.."
                        {...register("lastName")}

                        value={userData.lastName}
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword" hidden>
                      <Form.Label className='lb'>Total Amount</Form.Label>
                      <Form.Control
                        {...register("amount")}

                        value={totalAmount}
                      />
                    </Form.Group>

                  </Row>

                  <Row className="mb-3 " hidden>
                    <Form.Group as={Col} controlId="formGridState">
                      <Form.Label className='lb' >Phone number</Form.Label>
                      <Form.Control type="number" placeholder="Phone number..."
                        {...register("phoneNumber")}
                        value={userData.phoneNumber}

                        className='controlinput-num'
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridCity">
                      <Form.Label className='lb'>Additional number</Form.Label>
                      <Form.Control type="number" placeholder=" Enter additional..."
                        {...register("otherNumber")}


                      />
                    </Form.Group>



                    <Form.Group as={Col} controlId="formGridZip">
                      <Form.Label className='lb'>Email Address</Form.Label>
                      <Form.Control type="email" placeholder=" Enter your email..."

                        value={userData.emailAddress}
                        {...register("emailAddress")}
                      />
                    </Form.Group>
                  </Row>


                


                    
                    <Form.Group as={Col} controlId="formGridCity"  hidden>
                     

                      <Form.Control placeholder=" Enter your email..."

                        value={userData.city}
                        {...register("localGovernment")}
                      />

                    </Form.Group>



                 
                  <Form.Group className="mb-3" controlId="formGridAddress1">
                    <Form.Label className='lb'>Pick Up Address </Form.Label>
                    <Form.Control placeholder="Enter your delivery address..." value="Ebeonadi radio station, Ogidi" readOnly
                      {...register("deliveryAddress", { required: "This field  is required" })}


                    />
                    <span className="cum-error">{errors.deliveryAddress?.message}</span>
                  </Form.Group>



                  <Form.Group className="mb-3 
                   tikTakTest
                 "controlId="formGridAddress2">
                    <Form.Label className='lb'>Additional Information(optional) </Form.Label>
                    <Form.Control placeholder="Enter additional info..." className='controlinput-numM '

                      {...register("additionalInfo")}
                    />

                  </Form.Group>


                  <Form.Group hidden className="mb-3" controlId="formGridAddress2">


                    <Form.Control className='controlinput-num'

                      {...register("userId")}
                      value={userData.userId}
                    />
                  </Form.Group>

                  <br />
                  <br />
                  <br />
                  <br />
              <button type='submit' disabled={disabled}>
                    {
                      !isPendings ? <a className="bn13 "  >Order Now<FaShoppingCart /></a> : <span> <span>Saving please wait</span><span className="loader-load"></span></span>
                    }
                  </button>

                  <Link to="/accountpage"><Button variant="secondary" type="cancel" className='m-2'>
                    Cancel
                  </Button></Link>
                </Form>
              </div>

              <div className="sidedetails-container">
                <p>Order summary</p>
                <hr />
                <p className='total-amount-sid'>Total Amount: {symbol} {totalAmount} </p>
                <hr />
                <div style={{ textAlign: "left" }}>
                  <h5>You are about to purchase</h5>
                  {
                    items.map((item) => (
                      <div>
                        <p><IoMdCheckmark /> {item.comboName}</p>
                      </div>

                    ))
                  }
                  <hr />
                  <p className='pwarning'>(Complete the steps in order to proceed)</p>
                  <p className='pwarning2'> <AiOutlineWarning /> By proceeding  you are automatically accepting the Terms & Conditions</p>

                </div>
              </div>
            </div>

            ) : (

              

            <div className="receipt-content" ref={(el) => (componentRef = el)} id="controlPrint">
              <div className="contai bootstrap snippets bootdey">
                <div className="row">
                  <div className="col-md-12">
                    <div className="invoice-wrapper">
                      <div className="intro">
                        Hi <strong>{userData.firstName}  {userData.lastName}</strong>,
                        <br />
                        This is the receipt for the product(s) ordered on <span style={{ color: "darkorange" }}> Ebeonadi website </span>
                      </div>

                      <div className="payment-info">
                        <div className="row">
                          <div className="col-sm-3">
                            <span>Order Code</span>
                            <strong>
                              {firstItem?.orderCode && firstItem?.orderCode}
                            </strong>
                          </div>
                          <div className="col-sm-3 text-right">
                            <span>Order Date/Time</span>
                            <strong>{moment(formatedDate).format('llll')}</strong>
                          </div>
                          <div className="col-sm-3 text-right">
                            <span>Payment Status</span>
                            <strong> {pickup || paid} </strong>
                          </div>
                          <div className="col-sm-3 text-right">
                            <span>Reference code</span>
                            <strong> {referenceCode || null } </strong>
                          </div>
                        </div>
                      </div>

                      <div className="payment-details">
                        <div className="row">
                          <div className="col-sm-6">
                            <span>Customer</span>
                            <strong>
                              {userData.firstName}  {userData.lastName}
                            </strong>
                            <p>
                              {userData.city} <br />
                              {userData.state} <br />
                              {userData.phoneNumber} <br />

                              <a href="#">
                                {userData.emailAddress}
                              </a>
                            </p>
                          </div>
                          <div className="col-sm-6 text-right">
                            <span>Pick up address</span>
                            <strong>
                              Ebeonadi Radio station
                            </strong>
                            <p>
                              Residency hotel, <br />
                              Ogidi, <br />
                              Anambra State, <br />
                              Nigeria <br />
                              <a href="#">
                                ebeonadiradio@gmail.com
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="line-items">


                        <table className="table table-bordered" id='tableReceiptMobile'>
                          <thead>
                            <tr>
                              <th scope="col">N/A</th>
                              <th scope="col">Product Name</th>
                              <th scope="col">Quantity</th>
                              <th scope="col">Price</th>
                            </tr>
                          </thead>
                          <tbody>

                            {
                              orderData.fullData.products.map((item, i) => (
                                <tr>
                                  <td>{i + 1} </td>
                                  <td>{item.productName}</td>
                                  <td>{item.quantity}</td>
                                  <td>{symbol}{item.price} </td>

                                </tr>

                              ))
                            }



                          </tbody>
                        </table>


                        <strong className="field grand-total">
                          Total: <span>{symbol}{orderData.fullData.amount}  </span>
                        </strong>


                        <div className="total text-right">
                          <p className="extra-notes">
                            <strong>Compliment</strong>
                            Thank you for choosing <span style={{ color: "darkorange" }}> Ebeonadi products</span> . We believe you are satisfied by our services.
                          </p>
                        

                        </div>
                       

                        <div className="print">
                          <a >
                            <i className="fa fa-print"></i>
                            <ReactToPrint
                              trigger={() => <span>Print this receipt</span>}
                              content={() => componentRef}
                            />

                          </a>
                          <Button onClick={saveDivAsImage} style={{ background: "white", marginLeft: "1%", color: "black" }}> <CiSaveDown2 style={{ color: "black" }} /> Save</Button>
                          <Button style={{ marginLeft: "1%" }} variant="secondary" onClick={handleCancel}>Cancel</Button>
                        </div>

                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>


          )

        }

      <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header >
            <Modal.Title>Payment option</Modal.Title>
          </Modal.Header>
          <Modal.Body>

          </Modal.Body>
          <Modal.Footer>
            <button className="button-6" id='confirmorder' role="button" onClick={getId}> <PaystackButton  {...componentProps} className='paystacbtn' /></button>
            <button id="showcontact-btn" className="button-6" role="button" onClick={handlePayOnDelivery} >PAY ON DELIVERY</button>
          </Modal.Footer>
        </Modal>


        <Modal
          size="lg"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Pay when the product is delivered
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>PLACE YOUR ORDER?</p>
            <div className='place-order-warnContainer'>
              <button className="btn-remove btn btn-primary" type="submit" onClick={() => handleFirstModal()} >Order</button>
              <button className="btn-remove btn btn-secondary" type="submit" onClick={() => setLgShow(false)}  >Cancel</button>
            </div>
          </Modal.Body>


        </Modal>

        <Modal
          size="lg"
          show={modalShow}
          onHide={() => setModalShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>

          </Modal.Header>
          <Modal.Body>
            <h4>Thank you for choosing Ebeonadi e-commerce!</h4>
            <p>
              You have successfully completed your order, feel free to explore some other related products
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Link to="/mainprofile"><Button >Check out your profile <MdPerson3 /> </Button></Link>
            <Link to="/ecommerceupper"> <Button className='btn btn-secondary' onClick={() => setModalShow(false)}>Close</Button></Link>
          </Modal.Footer>
        </Modal>

       
        {scrolltop()}
        <Footer />
      </main>
    </>
  )
})
export default BookingPage