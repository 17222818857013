// import Newsfeed from "../layouts/newsfeed"
// import ShowMissed from "../news/showmissed"
import Monday from "./daysprogram/monday"
import MainNews from "./secondheader"
import {AiFillFacebook} from "react-icons/ai";
import {AiFillInstagram} from "react-icons/ai";
import {AiOutlineTwitter}from "react-icons/ai";
import {FaTiktok} from "react-icons/fa";
import {AiFillYoutube} from "react-icons/ai";
import Footer from "../layouts/footer";
import { useState } from "react";
import Tuesday from "./daysprogram/tuesday";
import Wednesday from "./daysprogram/wednesday";
import Thursday from "./daysprogram/thursday";
import Friday from "./daysprogram/friday";
import Saturday from "./daysprogram/saturday";
import Sunday from "./daysprogram/sunday";
import ModeHeader from "../layouts/modeheader";
import FirstHeader from "../layouts/firstheader";
import Changer from "../layouts/changer";
import ShowMissed from "../news/showmissed";
import Newsfeed from "../layouts/newsfeed";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import advertimage1 from "../images/images2.jpg";
import advertimage2 from "../images/images.png"
import FirstSideAdvert from "./firstsideadvert";
import SecondSideAdvert from "./secondsideadvert";
import Allprograms from "./daysprogram/allprograms";
import Button from 'react-bootstrap/Button';
import CollapsibleExample from "../layouts/secondheader";
import NavScrollExample from "../layouts/secondheader";

const Schedule = ()=>{

  const [scheduleShow, setscheduleShow] = useState("");
  const [all, setAll] = useState("");
  

  const showSchedule = (first)=>{
    console.log('first',first);
    setscheduleShow(first);
    componentShow();
  }
   

   
//  const toggleMonday =()=>{
// setMondays(!mondays)

//  }

   const componentShow = () =>{
    if (scheduleShow ==='monday') {
      return(
        <Monday/>
      )

    } else if(scheduleShow ==='tuesday'){
      return(
         <Tuesday/>
      )
    }else if(scheduleShow ==='wednesday'){
      return(
        <Wednesday/>
      )
    }else if(scheduleShow ==='thursday'){
      return(
        <Thursday/>
      )
    }else if(scheduleShow ==='friday'){
      return(
        <Friday/>
      )
    }else if(scheduleShow ==='saturday'){
      return(
        <Saturday/>
      )
    }else if(scheduleShow ==='sunday'){
      return(
        <Sunday/>
      )
    }else if(scheduleShow === 'all'){
      return(
        <Allprograms/>
      )
    }
    else{
      return(
       <Allprograms/>
      )
    }
  
}
  return(
        <>
       
        <Changer/>
        <ModeHeader/>
        {/* <NavScrollExample/> */}
         <FirstHeader/> 
         <Newsfeed/>   
     <MainNews/>   
     <h1 className="schedule-head"> Weekly Programs</h1>
     <div className="schedule-bodyoverflow">

 

  <div className="schedule-container"> 

  
    
    <div className="scheduleday-BTn">
    <button className="button-30" role="button" onClick={() =>showSchedule('all')} >All Programs</button>
    <button className="button-30" role="button" id="hideday-display" onClick={() => showSchedule('monday')}>Monday</button>
    <button className="button-30" role="button" id="hideday-display" onClick={() => showSchedule('tuesday')}>Tuesday</button>
    <button className="button-30" role="button" id="hideday-display" onClick={() => showSchedule('wednesday')}>Wednesday</button>
    <button className="button-30" role="button" id="hideday-display"  onClick={() => showSchedule('thursday')} >Thursday</button>
    <button className="button-30" role="button" id="hideday-display"  onClick={() => showSchedule('friday')}>Friday</button> 
    <button className="button-30" role="button" id="hideday-display" onClick={() => showSchedule('saturday')}>Saturday</button>
     <button className="button-30" role="button" id="hideday-display" onClick={() => showSchedule('sunday')} >Sunday</button> 
    
    
    </div>
    
    {componentShow()}
  
    
  </div>
      <div className="testside">
      
         <div className="advertspace-container"> 

      <div className="first-side-advertcontainer">
    <FirstSideAdvert/>
          
          </div>
         
          <div  className="second-side-advertcontainer">
        <SecondSideAdvert/>
            
          </div>

         </div> 


      </div>
       </div>
<div>
  <Footer/>
  </div>
 
  
    </>
    )
}
export default Schedule