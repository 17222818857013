import { MDBBtn, MDBContainer, MDBCard, MDBCardBody, MDBCardImage, MDBRow, MDBCol, MDBIcon, MDBInput, MDBCheckbox }
  from 'mdb-react-ui-kit';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import EcommerceNav from './ecommerce_nav';
import Footer from '../layouts/footer';
import { useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import { mainaxios, fbaxios } from "../../utility/axios";
import { useDispatch, useSelector } from 'react-redux';
import { Watch } from 'react-loader-spinner';


import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { GiCheckMark } from 'react-icons/gi';
import ModeHeader from '../layouts/modeheader';
import EcoNAvSearch from './econavsearch';


let renderCount = 0;

const ResetPassword = (() => {


  const { register, handleSubmit, formState: { errors } } = useForm();
  renderCount++;
  console.log(errors)

  const handleSubmitClick = (() => {
    handleSubmit()

  })

  const password = useRef({});
  password.current = Watch("password", "");

  const navigate = useNavigate()
  const [isPendings, setIsPendings] = useState(false);
  const [errorMessage, setErrorMessage] = useState(' ')
  const [disabled, setDisabled] = useState(true)
  const [userId, setUserId] = useState("")

  const { items } = useSelector((state) => state.cart);

  const tokenData = JSON.parse(localStorage.getItem("tokenData"));

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmited = async (data) => {
    setUserId(tokenData.userId)
    setDisabled(false);
    setIsPendings(true);


    await mainaxios.put(`ecommerce/updatePassword/${userId}`, data)

      .then((res) => {
        console.log("res", res)
        if (res.data) {
          setIsPendings(false);
          setDisabled(true);
          handleShow()
        } else {
          setIsPendings(false);

        }

      }).catch((err) => {
        console.log("err", err)
        setIsPendings(false);
        setDisabled(true);
      })


  }
  const scrolltop = (() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"

    });
  })


  return (

    <>
      <main className='mainbookinpage-all' id='mainbookinpage-allId'>

        <div>
          <ModeHeader />
        </div>
        <div>
          <EcoNAvSearch />
        </div>




        <MDBContainer fluid className='signupcontainer'>

          <MDBRow className='d-flex justify-content-center align-items-center h-100'>
            <MDBCol col='12'>

              <MDBCard className='bg-white my-5 mx-auto' style={{ borderRadius: '1rem', maxWidth: '500px' }}>
                <form onSubmit={handleSubmit((data, event) => {
                  event.target.reset()
                  handleSubmited(data)


                })} >
                  <MDBCardBody className='p-5 w-100 d-flex flex-column'>

                    <h2 className="fw-bold mb-2 text-center">Reset password</h2>
                    <p className="test4reset">Enter and confirm new password </p>
                    <label>Password <span className="star-required">*</span></label>
                    <MDBInput wrapperClass='mb-4 w-100' id='formControlLg' type='password' name='password' size="lg"  {...register("newPassword", {
                      required: "You must specify a password", minLength: {
                        value: 6,
                        message: "Password must have atlest 6 characters"
                      }
                    })} />
                    <span className="cum-error">{errors.password?.message}</span>

                    <label>Confirm password <span className="star-required">*</span></label>
                    <MDBInput wrapperClass='mb-4 w-100' id='formControlLg' type="password" name="password_repeat" size="lg"
                    //  {...register( "password_repeat",{
                    //                       validate: value =>
                    //                       value === password.current || "The password does not match"
                    //                       })}
                    />
                    {/* <span className="cum-error">{errors.password_repeat?.message}</span> */}

                    <button size='lg' className='login-btn-login w-100 btnlogin ' type='submit'
                      onClick={disabled}
                    >
                      <span>Reset password</span>{""}
                      {
                        isPendings && <span className="loader-load"></span>
                      }
                    </button>

                    {errorMessage && <div className="errlogin">{errorMessage}</div>}


                  </MDBCardBody>

                </form>
              </MDBCard>

            </MDBCol>
          </MDBRow>

        </MDBContainer>


        <Modal show={show} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title> <GiCheckMark style={{ color: "green" }} />  Password changed</Modal.Title>
          </Modal.Header>
          <Modal.Body>You have succesfully changed your password</Modal.Body>
          <Modal.Footer>

            <Link to="/signinpage"> <Button variant="primary" onClick={handleClose}>
              login
            </Button>
            </Link>
          </Modal.Footer>
        </Modal>


        <div>
          <Footer />
        </div>
      </main>

    </>
  )
})
export default ResetPassword