import { createContext, useEffect, useState } from "react";
import { Dropdown, Table } from "react-bootstrap"
import { fbaxios } from "../../../utility/axios";
import { VscDiffAdded } from "react-icons/vsc";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
// import Dropdown from 'react-bootstrap/Dropdown'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import CreateProduct from "./create-product";
import { Link, useNavigate } from "react-router-dom";
import { HiOutlineSearch } from "react-icons/hi";
import { TbListDetails } from "react-icons/tb";
import { FcViewDetails } from "react-icons/fc";





const ViewCompany = (() => {
  const [error, setError] = useState(null);
  const [datas, setDatas] = useState([]);
  const [isPending, setIsPending] = useState(true);
  const [details, setDetails] = useState({})
  const [showEdit, setShowEdit] = useState("");
  const [cashId, setCashId] = useState("");
  const [hideTable, setHideTable] = useState(true)
  const [dropdown, setDropdown] = useState(false);
  const [search, setSearch] = useState("");
  const toggleOpen = () => {
    // product.comboId 
    setDropdown(!dropdown)
  };
  const [show, setShow] = useState(false);
  const ShortedLinkContext = createContext('');
  const handleClose = () => setShow(false);

  const handleShow = (product) => {
    setDetails(product)
    setShow(true)

  }
  const holdDisplay = ((all) => {
    setShowEdit(all)
    handleShowEdit()

  })

  const sendData = ((product) => {
    holdDisplay("edit")
    localStorage.setItem("fbData", JSON.stringify(product))
    handleShowEdit()
    setHideTable(false)
  })

  const handleShowEdit = (() => {
    if (showEdit === "edit") {
      return (
        <CreateProduct details={details} />
      )
    } else {
      return null;
    }
  })


  useEffect(() => {
    setIsPending(true)
    fbaxios.get("products/menus/djW7G5kgB43wqdh?locationId=Njw9pXwT3BYIUMc")
      .then(response => {

        if (!response.statusText === "OK") {
          throw Error("Could not fetch data")
        }
        setIsPending(false)
        setDatas(response.data)

        setError(null)

      })
      .catch(error => {
        setError(error.message)
        setIsPending(false)
        console.log(error)
      })
  }, []);

  const data = datas.products

  const navigate = useNavigate();

  const handleSelectedProduct = (product) => {

    // navigate(`/goodsdetails/${product.comboId}`,{state: {product, name: ''}})
    // setProductId(product.comboId)
  }


  const handleSearchDisplay = ((event) => {
    setSearch(event.target.value)
  })

  const currentOrderDisplay = data && data.filter((order) => (
    order.comboName && order.comboName.toLowerCase().includes(search) || order.subCategoryName
    && order.subCategoryName
      .toLowerCase().includes(search)

  ))


  // const use = useId()
  // console.log("use>>>",use);
  const searchBtn = (() => {
    if (currentOrderDisplay) {
      return currentOrderDisplay
    }
  })


  const symbol = Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: 'NGN'
  })
    .formatToParts(0)
    .filter(part => part.type === 'currency')
    .map(part => part.value)
    .join('')


  return (
    <>
      <div>
        <ul className="action-bar">
          <li>Home /</li>
          <li>E-commerce /</li>
          <li className="createem">View Fb Products</li>
        </ul>
      </div>


      {handleShowEdit()}
      {
        hideTable &&

        <div >
          <h2 className="viewemployee-head">View Fb Products</h2>


          <div className="search_box" id="searchDrive">
            <div className="search">
              <div className="select_area">
                <i className="fas fa-map-marker-alt map_icon"></i>
                <div className="text">Search</div>
              </div>
              <div className="line"></div>
              <div className="text_and-icon">
                <input type="text" className="search_text" id="search_text" placeholder="Search by product name or sub category name ..." onChange={handleSearchDisplay} />
                <i className=" search_icon" onClick={searchBtn}><HiOutlineSearch />  </i>
              </div>
            </div>
          </div>


          <Table striped bordered hover size="sm" className='adaogidi-table'>
            <thead>


              <tr>
                <th>S/N</th>
                <th>Product Name</th>
                <th>Product Price</th>
                <th>Category name</th>
                <th>description</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>

              {isPending && <div className="loaderless"> <div className="lds-ripple"> Loading... <div></div><div></div></div>

              </div>}



              {
                currentOrderDisplay && currentOrderDisplay.map((product, i) => (
                  <tr key={product.comboId}>
                    <td  > {i + 1} </td>
                    <td>{product.comboName}</td>
                    <td> {symbol}{product.price} </td>
                    <td>{product.subCategoryName}</td>
                    <td>{product.description.substring(0, 50)}... </td>




                    {/* <td className="text-right" key={product.comboId}> */}

                    {/* <Dropdown className="  firstline-drop " autoClose="outside" > */}
                    <div className="side_iconBarDisplay" >
                      <div onClick={() => sendData(product)} className="side_iconBarDisplayDiv">
                        <VscDiffAdded size={25} />
                      </div>
                      <div onClick={() => handleShow(product)} className="side_iconBarDisplayDiv">
                        <FcViewDetails size={25} />
                      </div>
                    </div>


                  </tr>


                ))
              }






            </tbody>
          </Table>
        </div>
      }

      <Modal show={show} onHide={handleClose} >
        <Modal.Header closeButton>
          <Modal.Title>Product details</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <p className="detailsAd-show">Product name: <span style={{ fontWeight: "lighter" }}> {details.comboName} </span> </p>
          <p className="detailsAd-show">Subcategory name: <span style={{ fontWeight: "lighter" }}> {details.subCategoryName} </span>  </p>
          <p className="detailsAd-show">Product price: <span style={{ fontWeight: "lighter" }}> {details.price} </span>  </p>
          <p className="detailsAd-show">description: <span style={{ fontWeight: "lighter" }}>{details.description} </span>  </p>

          <p className="detailsAd-show">Image: <span style={{ fontWeight: "lighter" }}> <img className="imageControl-modal" src={details.picture} /> </span>  </p>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

        </Modal.Footer>
      </Modal>




    </>
  )
})
export default ViewCompany