


const ForgotPasswordTemplate = (() => {


  return (


    <>

      <section className="body_template">
        <div className="innerBody-template">
          <h2 className="temp_header">Password Reset</h2>
          <div >
            <img className="Template_img" src="https://www.shoviv.com/blog/wp-content/uploads/2018/09/PST-Password-Recovery-Open-Password-Protected-PST-File.png" />

          </div>
          <h2 className="userName-emailTemp">Hello! Emeka</h2>
          <div>
            You requested that the password for your <span style={{ color: "darkorange" }}> Ebeonadi e-commerce</span> account be reset.

          </div>

          <div className="Btn_tempDiv">

            <button className="button-66" role="button">Reset password</button>
          </div>

          <div className="reuestPassw_text">
            If you didn't request this, you can ignore this email or let us know. Your password won't change untill you create a new password.

          </div>
          <br />
          <div align="center" className="socialbtns">
            <ul className="ulA-tag">
              <li><a href="#" className="fa fa-lg fa-facebook"></a></li>
              <li><a href="#" className="fa fa-lg fa-twitter"></a></li>
              <li><a href="#" className="fa fa-lg fa-whatsapp"></a></li>


              <li><a href="#" className="fa fa-lg fa-linkedin"></a></li>
              <li><a href="#" className="fa fa-lg fa-instagram"></a></li>
              <li><a href="#" className="fa fa-lg fa-youtube"></a></li>
            </ul>
          </div>
        </div>
      </section>

    </>
  )
})
export default ForgotPasswordTemplate