
import {IoMdArrowDropdown} from "react-icons/io"
import {BiRadio}from "react-icons/bi"
import {TbAntenna, TbArrowBadgeRight}from "react-icons/tb"

import MobileNav from "./mobilenav"
import { BsBagPlusFill, BsCart4} from "react-icons/bs"
import {ImNewspaper} from "react-icons/im"

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";

import { AiOutlineMenu } from "react-icons/ai";
import { RiArrowUpSFill } from "react-icons/ri"



const FirstHeader=()=>{

  const [isNavVisible, setNavVisibility] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 700px)");
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []
  );

  const handleMediaQueryChange = mediaQuery => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };
  
  const toggleNav = () => {
    setNavVisibility(!isNavVisible);

   };
   
return(
        <>
            <div className="first-nav-container">


            <header className="Header-fornav">
            <NavLink className="testing-text" to="/">EBEONADI</NavLink>
      <CSSTransition
        in={!isSmallScreen || isNavVisible}
        timeout={350}
        classNames="NavAnimation"
        unmountOnExit
        >
        <ul className="Nav-fornav">
          <li  className="homehov"  ><BiRadio className="radio-icon" id="radio-iconhide"/> <span className="ebeTap">Ebeonadi</span> Radio<IoMdArrowDropdown className="drop-drop-mobile" />  
          
           <li className="letname-test">
                            <NavLink to="/schedule" className="prog-nav" ><li ><TbArrowBadgeRight/>  Program Synopsis</li></NavLink>

                           
                            <NavLink to="/promsgames" className="proms-btn"><li><TbArrowBadgeRight/> Company News/Promo</li></NavLink>
                           
                            <NavLink className="proms-btn" to="/listenlive"><li ><TbArrowBadgeRight/>  Call-in Numbers</li></NavLink>
                            <NavLink to="/advertrate" className="advert-nav"><li  ><TbArrowBadgeRight/>  Advert Rates</li></NavLink>
                           
                            <NavLink 
                           
                            to="#"
                             className="proms-btn" ><li className="ada-ogidi-disable"> <TbArrowBadgeRight/> Ada Ogidi Pageant</li></NavLink>
                            <NavLink to="/aboutus" className="proms-btn"><li  > <TbArrowBadgeRight/> About Ebeonadi Radio</li></NavLink>
          </li>
          </li>

          <li  className="homehover2" id="news-homehover2" > <ImNewspaper className="radio-icon" id="radio-iconhide" />  News <IoMdArrowDropdown  className="drop-drop-mobile"/>  
          <li className="letname-test2">
          <NavLink to="/newspage" className="proms-btn" ><li ><TbArrowBadgeRight/>  General News</li></NavLink>
                                <NavLink to="/localnews" className="proms-btn"><li > <TbArrowBadgeRight/> Local News</li></NavLink>
                                 <NavLink to="/entertainmentnews" className="proms-btn"><li > <TbArrowBadgeRight/> Entertainment News</li></NavLink>
                                 <NavLink to="/sportsnews" className="proms-btn"><li > <TbArrowBadgeRight/> Sports News</li></NavLink>
                                 <NavLink to="/politicalnews" className="proms-btn"><li > <TbArrowBadgeRight/> Politics News</li></NavLink>
                                 <NavLink to="/culturalnews" className="proms-btn"><li > <TbArrowBadgeRight/>  Culture </li></NavLink>
          </li> 
          </li>
          <NavLink to="/ecommerceupper" className="eComNave" ><li className="eComNaveText" ><BsCart4 className="radio-icon"/>E-commerce</li></NavLink>
          <li  className="homehover3"> <BsBagPlusFill className="radio-icon" size={20}  id="radio-iconhide" /> Jobs/Vacancies<IoMdArrowDropdown className="drop-drop-mobile"/> 
          <li  className="letname-test3">
          <NavLink to="/getjobs" className="proms-btn"><li ><TbArrowBadgeRight/> Find Job</li></NavLink>
                                 <NavLink to="/postjob" className="proms-btn" ><li ><TbArrowBadgeRight/> Post Job Vacancies</li></NavLink>
                                  <NavLink to="/loginpage" className="proms-btn" ><li ><TbArrowBadgeRight/> Admin</li></NavLink> 
                                      
                                 </li>
          </li>
    </ul>

      </CSSTransition>
      <button onClick={toggleNav} className="Burger">
        <AiOutlineMenu className="icon-drop-test"/>
      </button>
    </header>
    
</div>


          
        </>
    )
}
export default FirstHeader