import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Link, useNavigate } from 'react-router-dom';

import Footer from '../layouts/footer';
import { AiOutlineClose, AiOutlineWarning } from 'react-icons/ai';
import ProfileNav from './profilenav';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { useEffect, useRef, useState } from 'react';
import { MdOutlineCancel, MdPerson3 } from 'react-icons/md';
import PayButton from '../PayButton';
import { PaystackButton } from 'react-paystack';
import { Toaster, toast } from 'react-hot-toast';

import { mainaxios, fbaxios } from '../../utility/axios';
import { id } from 'date-fns/locale';
import { clearCart, decrease } from '../../redux/counter';
import GuestOrderNav from './guestOrderNav';
import useFectch from '../adminpage/createbackend';
import { IoMdCheckmark, IoMdCheckmarkCircleOutline } from 'react-icons/io';
import Alert from "react-bootstrap/Alert";
import { TbHomeMove } from 'react-icons/tb';
import axios from 'axios';
import ModeHeader from '../layouts/modeheader';
import Changer from '../layouts/changer';
import { FaShoppingCart } from 'react-icons/fa';
import { TiArrowBackOutline } from 'react-icons/ti';
import moment from 'moment';
import OrderReceipt from './receipt';
import ReactToPrint from "react-to-print";
import html2canvas from 'html2canvas';
import { CiSaveDown2 } from 'react-icons/ci';

let renderCount = 0;

const GuestOrder = (() => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  renderCount++;


  const [states, setStates] = useState([])
  const [localGvt, setLocalGvt] = useState([])


  const [showPage, setShowPage] = useState(false);
  const publicKey = "pk_test_0a9e6a807ac7b5d5cc2ccb0a25e5aa429f45a849"
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [additionNumber, setAdditionNumber] = useState();
  const [emailAddress, setEmailAddress] = useState();
  const [deliveryAddress, setDeliveryAddress] = useState();
  const [addtionInfo, setAddtionInfo] = useState();
  const [state, setState] = useState();
  const [city, setCity] = useState('');
  const [amount, setAmount] = useState("");
  const [payonDelivery, setPayOnDelivery] = useState("");
  const [onlinePayment, setOnlinePayment] = useState("")
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [showform, setShowForm] = useState(true)
  const [pickup, setPickup] = useState("")
  const [paid, setPaid] = useState("Paid")
  const [orderId, setOrderId] = useState("")
  const [payConfirm, setPayConfirm] = useState("")
  const handleClose = () => setShow(false);
  const [modalShow, setModalShow] = useState(false);
  const [show, setShow] = useState(false)
  const [lgShow, setLgShow] = useState(false);
  const [newLga, setNewLga] = useState([])
  const [stateName, setStateName] = useState('')

  const [disabled, setDisabled] = useState(false)
  const [isPendings, setIsPendings] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [productLoad, setProductLoad] = useState([])
  const [errorMessage, setErrorMessage] = useState("");


  const dispatch = useDispatch();

  const handleClearCart = (() => {
    dispatch(clearCart())
  })

  // currency format//
  const symbol = Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: 'NGN'
  })
    .formatToParts(0)
    .filter(part => part.type === 'currency')
    .map(part => part.value)
    .join('')
  // 
  const { items, totalAmount } = useSelector((state) => state.cart);

  const handleFirstModal = (() => {
    setModalShow(true);
    setLgShow(false);


  })

  const scrolltop = (() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"

    });
  })



  // update
  const orderData = JSON.parse(localStorage.getItem("orderData"));
  const currentDate = new Date();

  const formatedDate = currentDate.toISOString()

  let componentRef = useRef();

  const navigate = useNavigate()

  const handleCancel = (() => {

    navigate("/ecommerceupper", {})
  })


  const saveDivAsImage = () => {
    const divToCapture = document.getElementById('controlPrint'); 
    html2canvas(divToCapture).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');

      // Create a link element
      const downloadLink = document.createElement('a');
      downloadLink.href = imgData;
      downloadLink.download = 'div_snapshot.png';
      downloadLink.click();
    });
  };


  useEffect(() => {
    setIsPending(true)
    mainaxios.get("ecommerce/getAllOrders_ebeonadiEcommerce")
      .then(response => {

        if (!response.statusText === "OK") {
          throw Error("Could not fetch data")
        }
        setIsPending(false)
        // setData(response.data)
        setProductLoad(response.data);
        setError(null)

      })
      .catch(error => {
        setError(error.message)
        setIsPending(false)
        console.log(error)
      })
  }, []);

  const update = () => {

    mainaxios.get("ecommerce/getAllOrders_ebeonadiEcommerce")
      .then((res) => {
        setProductLoad(res.data);
      });
  };
  //  useEffect(update, [productLoad]);
  const firstItem =  productLoad.find(x =>  x!== undefined);
     
  const getId = (() => {
    // update()
    setOrderId(firstItem.orderId)
    setPayConfirm("Paid")
  })

  let order;   
  order = orderId;
  // console.log("order>>>>>",order);

   const handleConfirmPayment = async () => {
    const confirm = {
      paymentStatus: payConfirm
    }
    await mainaxios.put(`ecommerce/confirmPaymentStatus/${order}`, confirm)
      .then((res) => {
        console.log("res", res)

        if (res.data.success) {
          setIsPendings(false);
        } else {
          setIsPendings(false);
        }
      }).catch((err) => {
        setIsPendings(false);
        console.log("err", err)

      })


  }

 const [referenceCode, setReferenceCode] = useState("")  
  const [confirmPay, setConfirmPay] = useState()

  const Navigate = useNavigate();

  const handlePaystackSuccessAction = (reference) => {
    handleConfirmPayment()
    setPaid("Paid")
    setShowForm(false)
    handleClearCart()
    setShow(false)
    setSuccess(true)
    setReferenceCode(reference.reference)
    
  }



  const handlePaystackCloseAction = () => {
    Navigate("/accountpage", { state: {} });
  };

  const [buttonDisable, setButtonDisable] = useState(true)
  const handleShow = () => setShow(true);



  const componentProps = {
    email: emailAddress,
    amount: totalAmount * 100,

    metadata: {

      fullname: firstName,
      phone: phoneNumber,
      additionNumber,
      deliveryAddress,
      addtionInfo,
      state,
      city
    },

    publicKey: "pk_test_0a9e6a807ac7b5d5cc2ccb0a25e5aa429f45a849 ",
    text: 'PAY NOW',
    onSuccess: (reference) => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };

  const handlePayOnDelivery = (() => {
    setShowForm(false)
    setPickup("Pay on Pickup")
    handleClearCart()
    setShow(false)
    setSuccess(true)
  })

  const [success, setSuccess] = useState(false)

  useEffect(() => {
    const getState = async () => {
      const resState = await axios.get('https://ipms.intellims.com:5300/api/v1/public/states')

      const relState = await resState.data
      setStates(await relState)
    }

    getState()
  }, [])


  useEffect(() => {
    const getLocalG = async () => {
      const localLga = await axios.get(
        "https://ipms.intellims.com:5300/api/v1/public/local_govt"
      );

      const localGvt = await localLga.data;

      setLocalGvt(localGvt)

    };
    getLocalG()
  }, [])



  const getMainLGA = (e) => {
    const stateId = e.target.value


    const filterLga = localGvt && localGvt.filter((lga) => (
      lga.state_id === stateId
    ))

    setNewLga(filterLga);
  };
  const onChangeHandler = (e) => {
    setState()
    const newStateId = e.target.value
    setState(newStateId)
    const newState = states && states.find((stat) => stat.stateId === newStateId);

    setStateName(newState.name)


  }
const notify = () => toast("Saving order...");
  useEffect(() => {
    setIsPending(true)
    fbaxios.get("products/menus/djW7G5kgB43wqdh?locationId=Njw9pXwT3BYIUMc")
      .then(response => {

        if (!response.statusText === "OK") {
          throw Error("Could not fetch data")
        }
        setIsPending(false)
        setData(response.data)

        setError(null)
        // console.log("data>>>>>>>>>",data)
      })
      .catch(error => {
        setError(error.message)
        setIsPending(false)
        console.log(error)
      })
  }, []);


  const selectedFieldItem = []



  const itemsAll = items && items.map((element) => (
    element.fields.map((element) => (

      element.fieldItems.map((field) => (
        selectedFieldItem.push(field)
      ))
    ))
  ))

  //  get token
  const [token, setToken] = useState("")
  const handleGetToken = async () => {

    const detail = {
      password: "ebeonadi",
      email: "ebeonadi@gmail.com"
    }

    await fbaxios.post("auth/login", detail)
      .then((res) => {
       
        setToken(res.data.token)


      }).catch((err) => {

      })
  }
  useEffect(() => {
    handleGetToken()
  }, [])




  const mainData = {
    serviceStaff: "null", typeOfService: "sales", menuId: data.menuId, openedTableRoomId: "S757FqdcYVMSoRC", registerId: "nzOdjpcqJ59NRU1",
    locationId: "Njw9pXwT3BYIUMc", hotelId: data.hotelId, selectedProductQty: selectedFieldItem

    ,
    products: items && items.map((item) => (
      {
        comboId: item.comboId, comboType: item.comboType, menuId: item.menuId, comboName: item.comboName,
        comboPrice: item.price, hotelId: item.hotelId, fields: item.fields.map((fields) => (
          {
            fieldId: fields.fieldId, fieldName: fields.fieldName, fieldType: fields.fieldType, fulfillmentType: fields.fulfillmentType,
            defaultCheckBox: fields.defaultCheckBox,
            orderNumber: fields.orderNumber, itemRequired: fields.itemRequired, printOnInvoice: fields.printOnInvoice,
            fieldItems: fields.fieldItems.map((field) => (
              {
                fieldItemId: field.fieldItemId, productId: field.productId, productName: field.productName,
                adjustmentPrice: field.adjustmentPrice, quantity: field.quantity,
                currentQuantity: field.currentQuantity, productLocation: field.productLocation, active: field.active
              }
            ))
          }
        )),

        singleCombo: true, quantity: item.quantity, lineTotal: totalAmount
      }

    )),
    totalAmount: totalAmount
  }



  const handleSubmitData = async () => {
    setDisabled(true)
    setIsPendings(true);
    await fbaxios.post("orders/placeOrder", mainData, {
      headers: {
        'x-access-token': token
      }
    })
      .then((res) => {
        // console.log("res", res)

        if (res.data.success) {
          setIsPendings(false);
          // setSuccess(res.data.message)
          setButtonDisable(false)

          setDisabled(false)
          handleShow()
        } else {
          setIsPendings(false);
          // setErrorMessage("Something went wrong, please try again");
          setDisabled(false)
        }

      }).catch((err) => {
        setIsPendings(false);
        console.log("err", err)
        setErrorMessage("Something went wrong, please try again");
        setDisabled(false)
      })

  }

  const handleSubmited = async (data) => {
    setDisabled(true)
    setIsPendings(true);
    setEmailAddress(data.emailAddress)

    let datas = data.state = stateName
    const fullData = {
      ...data,
      products: items.map((item) => (
        {
          productId: item.comboId, productName: item.comboName,
          picture: item.picture, quantity: item.quantity,
          price: item.price, description: item.description

        }
      ))
    }
    scrolltop()

    await mainaxios.post("ecommerce/createOrder_ebeonadiEcommerce", fullData)
      .then((res) => {
        // console.log("res", res)

        if (res.data.success) {

          setIsPendings(false);
          localStorage.setItem("orderData", JSON.stringify({ fullData }))

          setButtonDisable(false)
          setDisabled(false)

        } else {
          setIsPendings(false);
          // setErrorMessage("Something went wrong. Please try again later.");
          setDisabled(false)
        }

      }).catch((err) => {
        setIsPendings(false);
        console.log("err", err)
        setErrorMessage("Something went wrong. Please try again later.");
        setDisabled(false)
      })

    handleSubmitData();
    update();
  }

  const closeErrorClick = (() => {
    setErrorMessage(false)
    setSuccess(false)
  })



  const goBack = (() => {
    Navigate(-1, { state: {} })

  })

  // update()

  return (

    <>

      {errorMessage && <div >
        <button className="btn btn-danger toast-success-btn">{errorMessage} <span onClick={closeErrorClick} className="remove-erroricon"><MdOutlineCancel size={25} /></span></button>

      </div>
      }





      <main className='mainbookinpage-all'>
        <div>
          <ModeHeader />
          <Changer />
        </div>
        <div>
          <GuestOrderNav />
        </div>


        <div className='iconBackBtn-div' onClick={goBack}> <TiArrowBackOutline size={35} className='iconBackBtn' /> </div>
        {
          success &&

          <div className="alert success-alert">
            <h3> <IoMdCheckmarkCircleOutline /> You have successfully placed your order</h3>
            <a className="close" onClick={closeErrorClick}><MdOutlineCancel /></a>
            <Link className='linkRemoveBook' to="/ecommerceupper">  <span className="closeHomeGo"><TbHomeMove size={35} /></span></Link>
          </div>


        }

        {isPendings &&
          <div style={{ display: isPendings ? 'flex' : 'none' }} className='modaled'>
            <div className='modal-contents'>
              <div className='loadered'></div>
              <div className='modal-texted'><span className='processing_Div'>Processing please wait...</span></div>
            </div>
          </div>

        }


        {

          showform ? (
            <div className='booking-container'>

              <div className='bookingform-main'>
                <Form className='booking-form-container' onSubmit={handleSubmit((data, event) => {
                  event.target.reset()
                  setDisabled(true)
                 
                  handleSubmited(data)
                })} >
                  <p className='filld-form'> Fill the form to place your order</p>

                  <br />

                  {/* <Row className="mb-3"> */}
                  <Form.Group as={Col} >
                    <Form.Label className='lb'>First name  <span className="star-required">*</span></Form.Label>
                    <Form.Control placeholder="Enter your name"
                      {...register("firstName", { required: "This field  is required" })}


                    />
                    <span className="cum-error">{errors.firstName?.message}</span>
                  </Form.Group>
                  <p></p>
                  <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Label className='lb'>Last name  <span className="star-required">*</span></Form.Label>
                    <Form.Control placeholder="Last name.."
                      {...register("lastName", { required: "This field  is required" })}

                    />
                    <span className="cum-error">{errors.lastName?.message}</span>
                  </Form.Group>



                  {/* </Row> */}
                  <Form.Group as={Col} controlId="formGridPassword" hidden>
                    <Form.Label className='lb'>Total Amount</Form.Label>
                    <Form.Control
                      {...register("amount")}

                      value={totalAmount}
                    />
                  </Form.Group>
                  <p></p>
                  <Row className="mb-3 " >
                    <Form.Group as={Col} controlId="formGridState">
                      <Form.Label className='lb' >Phone number  <span className="star-required">*</span></Form.Label>
                      <Form.Control type="number" placeholder="Phone number..."
                        {...register("phoneNumber", { required: "This field  is required" })}
                        // value={userData.phoneNumber}
                        // onChange={(e) =>setPhoneNumber(e.target.value) }
                        className='controlinput-num'
                      />
                      <span className="cum-error">{errors.phoneNumber?.message}</span>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridZip">
                      <Form.Label className='lb'>Email Address <span className="star-required">*</span></Form.Label>
                      <Form.Control type="email" placeholder=" Enter your email..."
                        // onChange={(e) =>emailAddress(e.target.value) }
                        //    value={userData.emailAddress}
                        {...register("emailAddress", { required: "This field  is required" })}
                      />
                      <span className="cum-error">{errors.emailAddress?.message}</span>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3 " >


                    <Form.Group as={Col} controlId="formGridState">
                      <Form.Label className='lb' >State <span className="star-required">*</span></Form.Label>
                      <Form.Select defaultValue="Choose..."
                        //  onChange={(e) =>setState(e.target.value) }
                        {...register("state")}
                        onClick={(e) => getMainLGA(e)}
                      >

                        <option>Choose...</option>
                        {
                          states.map((state) => (
                            <option key={state.stateId} value={state.stateId}> {state.name} </option>
                          ))
                        }
                    </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridCity">
                      <Form.Label className='lb'>City <span className="star-required">*</span></Form.Label>
                      <Form.Select defaultValue="Choose..."
                        setCity
                        //  onChange={(e) => setCity (e.target.value) }

                        {...register("localGovernment")}
                      >
                        <option>Choose...</option>
                        {
                          newLga && newLga.map((lga, i) => (
                            <option key={i.id}> {lga.name} </option>
                          ))
                        }

                      </Form.Select>
                    </Form.Group>



                  </Row>
                 <Form.Group className="mb-3" controlId="formGridAddress1" hidden>
                    <Form.Label className='lb'>Delivery Address </Form.Label>
                    <Form.Control
                      value="Ebeonadi radio station, Ogidi" readOnly
                      {...register("deliveryAddress")}


                    />

                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formGridAddress2">
                    <Form.Label className='lb'>Additional Information(optional) </Form.Label>
                    <Form.Control placeholder="Enter additional info..." className='controlinput-numM'

                      {...register("additionalInfo")}
                    />
                  </Form.Group>
                  <Form.Group hidden className="mb-3" controlId="formGridAddress2">

                    <Form.Control placeholder="Enter additional info..." className='controlinput-num'
                      //  onChange={(e) =>setUsersId(e.target.value) }
                      {...register("userId")}
                    //  value={userData.userId}
                    />
                  </Form.Group>




                  <Form.Group className="mb-3" id="formGridCheckbox">
                    <Form.Label className='lb'> <span className='redNote-show'>Note: </span>Please enter and save your correct contact details before you proceed</Form.Label>
                  </Form.Group>




                  <button type='submit' disabled={disabled}>
                    {
                      !isPendings ? <a className="bn13 " >Order Now<FaShoppingCart /></a> : <span> <span>Saving please wait</span><span className="loader-load"></span></span>
                    }
                  </button>
                  <Link to="/accountpage"><Button variant="secondary" type="cancel" className='m-2'>
                    Cancel
                  </Button></Link>
                </Form>
              </div>
              <div className="sidedetails-container">
                <p>Order summary</p>
                <hr />
                <p className='total-amount-sid'>Total Amount: {symbol} {totalAmount} </p>
                <hr />
                <div style={{ textAlign: "left" }}>
                  <h5>You are about to purchase</h5>
                  {
                    items.map((item) => (
                      <div>
                        <p><IoMdCheckmark /> {item.comboName}</p>
                      </div>

                    ))
                  }
                  <hr />
                  <p className='pwarning'>(Complete the steps in order to proceed)</p>
                  <p className='pwarning2'> <AiOutlineWarning /> By proceeding  you are automatically accepting the Terms & Conditions</p>





                </div>
              </div>
            </div>



          ) : (

            <div className="receipt-content" ref={(el) => (componentRef = el)} id="controlPrint">
              <div className="contai bootstrap snippets bootdey">
                <div className="row">
                  <div className="col-md-12">
                    <div className="invoice-wrapper">
                      <div className="intro">
                        Hi <strong>{orderData.fullData.firstName}  {orderData.fullData.lastName}</strong>,
                        <br />
                        This is the receipt for the product(s) ordered on <span style={{ color: "darkorange" }}> Ebeonadi website </span>
                      </div>

                      <div className="payment-info">
                        <div className="row">
                          <div className="col-sm-3">
                            <span>Order Code</span>
                            <strong>{firstItem?.orderCode && firstItem?.orderCode} </strong>
                          </div>
                          <div className="col-sm-3 text-right">
                            <span>Order Date/Time</span>
                            <strong>{moment(formatedDate).format('llll')}</strong>
                          </div>
                          <div className="col-sm-3 text-right">
                            <span>Payment Status</span>
                            <strong> {pickup || paid} </strong>
                          </div>
                          <div className="col-sm-3 text-right">
                            <span>Reference code</span>
                            <strong> {referenceCode || null} </strong>
                          </div>
                        </div>
                        
                      </div>

                      <div className="payment-details">
                        <div className="row">
                          <div className="col-sm-6">
                            <span>Customer</span>
                            <strong>
                              {orderData.fullData.firstName}  {orderData.fullData.lastName}
                            </strong>
                            <p>
                              {orderData.fullData.localGovernment} <br />
                              {orderData.fullData.state} <br />
                              {orderData.fullData.phoneNumber} <br />

                              <a href="#">
                                {orderData.fullData.emailAddress}
                              </a>
                            </p>
                          </div>
                          <div className="col-sm-6 text-right">
                            <span>Pick up address</span>
                            <strong>
                              Ebeonadi Radio station
                            </strong>
                            <p>
                              Residency hotel, <br />
                              Ogidi, <br />
                              Anambra State, <br />
                              Nigeria <br />
                              <a href="#">
                                ebeonadiradio@gmail.com
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="line-items">


                        <table className="table table-bordered" id='tableReceiptMobile'>
                          <thead>
                            <tr>
                              <th scope="col">N/A</th>
                              <th scope="col">Product Name</th>
                              <th scope="col">Quantity</th>
                              <th scope="col">Price</th>
                            </tr>
                          </thead>
                          <tbody>

                            {
                              orderData.fullData.products.map((pro, i) => (
                                <tr>
                                  <td>{i + 1} </td>
                                  <td>{pro.productName}</td>
                                  <td>{pro.quantity}</td>
                                  <td>{symbol}{pro.price} </td>

                                </tr>

                              ))
                            }
                        </tbody>
                        </table>


                        <strong className="field grand-total">
                          Total: <span>{symbol}{orderData.fullData.amount} </span>
                        </strong>


                        <div className="total text-right">
                          <p className="extra-notes">
                            <strong>Compliment</strong>
                            Thank you for choosing <span style={{ color: "darkorange" }}> Ebeonadi products</span> . We believe you are satisfied by our services.
                          </p>
                          {/* <div className="field">
                          Subtotal <span>$379.00</span>
                        </div>
                        <div className="field">
                          Shipping <span>$0.00</span>
                        </div>
                        <div className="field">
                          Discount <span>4.5%</span>
                        </div> */}

                        </div>

                        <div className="print">
                          <a >
                            <i className="fa fa-print"></i>
                            <ReactToPrint
                              trigger={() => <span>Print this receipt</span>}
                              content={() => componentRef}
                            />

                          </a>
                          <Button onClick={saveDivAsImage} style={{ background: "white", marginLeft: "1%", color: "black" }}> <CiSaveDown2 style={{ color: "black" }} /> Save</Button>
                          <Button style={{ marginLeft: "1%" }} variant="secondary" onClick={handleCancel}>Cancel</Button>
                        </div>

                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
                )
                }

      <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header >
            <Modal.Title>Payment option</Modal.Title>
          </Modal.Header>
          <Modal.Body>

          </Modal.Body>
          <Modal.Footer>
            <button className="button-6" id='confirmorder' role="button" onClick={getId}> <PaystackButton  {...componentProps} className='paystacbtn' /></button>
            <button id="showcontact-btn" className="button-6" role="button" onClick={handlePayOnDelivery} >PAY ON Pickup</button>
          </Modal.Footer>
        </Modal>


        <Modal
          size="lg"
          show={modalShow}
          onHide={() => setModalShow(true)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>

          </Modal.Header>
          <Modal.Body>
            <h4>Thank you for choosing Ebeonadi e-commerce!</h4>
            <p>
              You have successfully completed your order, feel free to explore some other related products
            </p>
            <p>
              ...We will get back to you as soon as possible!
            </p>
            <p className='warn-standd'>
              <AiOutlineWarning /> Create account with the same email address, to view your order
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Link to="/signuppage"><Button >Create account  <MdPerson3 /> </Button></Link>
            <Link to="/ecommerceupper"> <Button className='btn btn-secondary' onClick={() => setModalShow(false)}>Close</Button></Link>
          </Modal.Footer>
        </Modal>

        <Footer />
      </main>
      {scrolltop()}


    </>
  )
})
export default GuestOrder