
import { Button, Col, Dropdown, Modal, Pagination, Row, Table } from "react-bootstrap"
import useFectch from "../createbackend";
import moment from "moment";
// import { RiDeleteBin6Line } from "react-icons/ri";
import React, { useState } from 'react';
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem, MDBBtn } from 'mdb-react-ui-kit';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import { BsFillTrash3Fill } from "react-icons/bs";
import { MdOutlineDeleteForever } from "react-icons/md";
import { HiOutlineSearch } from "react-icons/hi";
import { Form } from "react-router-dom";
import { FcViewDetails } from "react-icons/fc";
import { FaTrashAlt } from "react-icons/fa";


const ViewEcomUsers = (() => {


  const { data: product, isPending, error } = useFectch('registrations/getUsers_ebeonadiEcommerce');

  const [search, setSearch] = useState("")

  const [show, setShow] = useState("");

  const handleClose = () => setShow(false);



  const [details, setDetails] = useState({})

  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPage = 10;
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = product.slice(indexOfFirstOrder, indexOfLastOrder);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleSearchDisplay = ((event) => {
    setSearch(event.target.value)
  })

  const currentOrderDisplay = currentOrders && currentOrders.filter((order) => (
    order.phoneNumber && order.phoneNumber.toLowerCase().includes(search) || order.firstName && order.firstName.toLowerCase().includes(search)

  ))
  const handleDetails = ((product) => {
    setDetails(product)
    setShow(true)
  })



  return (

    <>
      <div>
        <ul className="action-bar">
          <li>Home /</li>
          <li>E-commerce /</li>
          <li className="createem">View Ecom-Users</li>
        </ul>
      </div>
      <h2 className="viewemployee-head">List of Ecom-Users</h2>
      <div>
        {/* <Table striped bordered hover size="sm" className='adaogidi-table'> */}


        <button className="deleteMarked"> <BsFillTrash3Fill /> Delete marked</button>
        <div className="search_box">
          <div className="search">
            <div className="select_area">
              <i className="fas fa-map-marker-alt map_icon"></i>
              <div className="text">Search</div>
            </div>

            <div className="line"></div>

            <div className="text_and-icon">
              <input type="text" className="search_text" id="search_text" placeholder="Search by customer name or phone number ..." onChange={handleSearchDisplay} />
              <i className=" search_icon"><HiOutlineSearch />  </i>
            </div>
          </div>
        </div>
        <Table striped bordered hover size="sm" className='adaogidi-table' id="order-Table">
          <thead>
            <tr>
              {/* <th scope="col">
        <MdOutlineDeleteForever size={30}/>
      </th> */}
              <th>S/N</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th> Phone Number</th>
              <th>User Email</th>
              <th>State</th>
              <th>Local Government</th>
              <th>User Address</th>


              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {isPending && <div className="loaderless"> <div className="lds-ripple"> Loading... <div></div><div></div></div>

            </div>}

            {
              product && product.map((ecom, i) => (
                <tr key={ecom.userId}>
                  {/* <th scope="row">
        <div className="form-check">
          <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
        </div>
      </th> */}
                  <td> {i + 1} </td>
                  <td> {ecom.firstName} </td>
                  <td>{ecom.lastName}</td>
                  <td>{ecom.phoneNumber}</td>
                  <td>{ecom.emailAddress}</td>
                  <td>{ecom.state} </td>
                  <td>{ecom.city} </td>
                  <td>{ecom.address} </td>
                  {/* <td>{   moment( ecom.createdDateTime).format('llll') } </td> */}


                  {/* <Dropdown className=" mx-2 firstline-drop " autoClose="outside"  >
      <Dropdown.Toggle id="dropdown-autoclose-outside" >
        Action
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdownshow-d">
        
        <Dropdown.Item className="sideside" onClick={()=> handleDetails(ecom)} >Details</Dropdown.Item>
        <Dropdown.Item  className="sideside" id="delete-red" >Delete</Dropdown.Item>
        
      </Dropdown.Menu>
    </Dropdown>  */}


                  <div className="side_iconBarDisplay" >
                    <div onClick={() => handleDetails(ecom)} className="side_iconBarDisplayDiv">
                      <FcViewDetails size={25} />
                    </div>
                    <div className="side_iconBarDisplayDiv">
                      <FaTrashAlt style={{ opacity: "0.1" }} size={20} />
                    </div>

                  </div>

                  {/* <Dropdown className="  firstline-drop " autoClose="outside" >
                
                 
                   <Dropdown.Toggle split id="dropdown-split-basic" aria-label="Action" variant="secondary"  />  
                  
                  <Dropdown.Menu style={{ zIndex: "100", position: "absolute" }} >
                    <Dropdown.Item href="#/action-1" className="shiftPick"  >Details</Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="shiftPick">Delete</Dropdown.Item>
                   
                  </Dropdown.Menu>
                </Dropdown>  */}




                </tr>

              ))

            }

          </tbody>
          <div className="pagination-Content">
            <Pagination >
              {Array.from({ length: Math.ceil(product.length / ordersPerPage) }).map((_, index) => (
                <Pagination.Item
                  key={index + 1}
                  active={index + 1 === currentPage}
                  onClick={() => paginate(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          </div>
        </Table>



        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>User details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* {
                        details && details.map((data)=>( */}
            <div>
              <p className="detailsAd-show">Full Name:<span style={{ fontWeight: "lighter" }}> {details.firstName}  {details.lastName} </span>    </p>

              <p className="detailsAd-show">Phone Number:  <span style={{ fontWeight: "lighter" }}> {details.phoneNumber} </span>  </p>
              <p className="detailsAd-show">State:  <span style={{ fontWeight: "lighter" }}>{details.state} </span>  </p>
              <p className="detailsAd-show">City: <span style={{ fontWeight: "lighter" }}> {details.city}</span>   </p>
              <p className="detailsAd-show">Address:  <span style={{ fontWeight: "lighter" }}> {details.address} </span>  </p>
              <p className="detailsAd-show">Status:  <span style={{ fontWeight: "lighter" }}> {details.statuss} </span>  </p>

              <p className="detailsAd-show">Date created:  <span style={{ fontWeight: "lighter" }}> {moment(details.createdDateTime).format('llll')}</span>  </p>

            </div>

            {/* ))
                      } */}




          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>

          </Modal.Footer>
        </Modal>



      </div>

    </>
  )
})
export default ViewEcomUsers