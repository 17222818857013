

import axios from "axios";


// const baseURLEbeonadi = "http://197.210.166.58:4005/api/v1/";
// const baseURLEbeonadi = "http://10.0.5.51:3015/api/v1/"
const baseURLEbeonadi = "https://www.ebeonadi.com:5500/app3/v1"

const baseURLFb = "http://10.0.5.51:3018/api/v1/"



   
   const mainaxios = axios.create({
     baseURL:baseURLEbeonadi 
   });
   
   const fbaxios = axios.create({
     baseURL: baseURLFb
   });
   

   


export { mainaxios, fbaxios };

