import { BiDetail, BiPhoneCall, BiTimeFive } from "react-icons/bi"
import Footer from "../layouts/footer"
import EcommerceNav from "./ecommerce_nav"
import { CiLocationOn } from "react-icons/ci"
import RelatedProduct from "./relatedproduct"
import { Link, useParams, useLocation, useNavigate } from "react-router-dom"
import { useCallback, useEffect, useRef, useState } from "react"
import { CCarousel, CCarouselItem, CImage } from '@coreui/react'
import EcoNAvSearch from "./econavsearch"
import { BsCart3, BsFillPhoneLandscapeFill, BsTicketDetailed } from "react-icons/bs"
import { AiOutlineShoppingCart } from "react-icons/ai"
// import {datas} from "./itemdata"
import { addToCart } from "../../redux/counter";
import { useDispatch, useSelector } from "react-redux"
import { mainaxios, fbaxios } from '../../utility/axios';

import toast, { Toaster } from 'react-hot-toast';
import { GiCardPickup, GiReturnArrow, GiStorkDelivery } from "react-icons/gi"
import { Button, Modal } from "react-bootstrap"
import { FcCheckmark } from "react-icons/fc"
import { IoIosCheckmarkCircleOutline } from "react-icons/io"
import useFectch from "../adminpage/createbackend"
import { TiArrowBackOutline } from "react-icons/ti"
import { TbTruckDelivery, TbTruckReturn } from "react-icons/tb"
import "./categories.css"
import ModeHeader from "../layouts/modeheader"
import Changer from "../layouts/changer"
import video from "../images/jukenmall.mp4"
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { VscFeedback } from "react-icons/vsc";

const GoodsDetails = (() => {
  const history = useLocation();
  const singleProd = history.state?.product;

  // const notify = () => toast('The product has been added to your cart.', {

  //   className: 'toast-messages'});
  // const {data:datas,isPending,error} = useFectch("http://10.0.5.51:3018/api/v1/products/menus/DfUB4v5rKBZq4xH?locationId=ZVLyzRdwOI53vQp")              
  const [show, setShow] = useState(false);
  const [sideProduct, setSideProduct] = useState({})
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const [showNumberDisplay, setShowNumberDisplay] = useState("");
  const [disable, setDisable] = useState(false);
  const [addedData, setAddedData] = useState([]);
  const [data, setData] = useState([]);
  const [feedBack, setFeedBack] = useState([]);

  const items = useSelector((state) => state.items);
  const dispatch = useDispatch();
  const symbol = Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: 'NGN'
  })
    .formatToParts(0)
    .filter(part => part.type === 'currency')
    .map(part => part.value)
    .join('')


  const scrollRef = useRef();
  const scrolldown = useRef();

  const scrollRefControl = (e) => {
    e.current.scrollIntoView({
      behavior: "smooth"
    })
  }

  const scrollRefDown = (e) => {
    e.current.scrollIntoView({
      behavior: "smooth"
    })
  }

  const [showImage, setShowImage] = useState('');


  const handleAddToCart = (() => {
    setDisable(disable)
    handleShow()
  })

  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);
  const [datas, setDatas] = useState([]);
  const [alert, setAlert] = useState(false)

  const [removeAlert, setRemoveAlert] = useState('')

  const handleRemoveAlert = (() => {

    setRemoveAlert(removeAlert)
    setAlert(false)
  })

  const [useQuantity, setUseQuantity] = useState()

  useEffect(() => {
    setSideProduct(singleProd)


  }, [singleProd]);

  const [productId, setProductId] = useState('')

  useEffect(() => {
    setIsPending(true)
    fbaxios.get("products/menus/djW7G5kgB43wqdh?locationId=Njw9pXwT3BYIUMc")
      .then(response => {

        if (!response.statusText === "OK") {
          throw Error("Could not fetch data")
        }
        setIsPending(false)
        setDatas(response.data)

        setError(null)
        // console.log("data>>>>>>>>>",data)
      })
      .catch(error => {
        setError(error.message)
        setIsPending(false)
        // console.log(error)
      })
  }, []);

  const { data: number, isPending: ispendingNumber, error: errorNumber } = useFectch('programs/getCallNumbers')
  const scrolltop = (() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"

    });
  })
  const callNumber = number && number.filter((call) => (
    call.type == "E-commerce"
  ))

  const handleSelectedProduct = (data) => {
    setSideProduct(data)

  }

  const quantityAdd = sideProduct.fields

  //  const quantify = quantityAdd.map((qaunt)=>(
  //             qaunt.fieldItems.map((field)=>(
  //               field.quantity
  //             ))
  //  ))
  //  const currentQuantitys = quantityAdd.map((qaunt)=>(
  //   qaunt.fieldItems.map((field)=>(
  //     field.currentQuantity
  //   ))
  // ))

  const cartData = {
    comboId: sideProduct.comboId, comboName: sideProduct.comboName, description: sideProduct.description,
    price: sideProduct.price, picture: sideProduct.picture, comboTypeCategory: sideProduct.comboTypeCategory, menuId: sideProduct.menuId, hotelId: sideProduct.hotelId,
    comboType: sideProduct.comboType, fields: sideProduct.fields && sideProduct.fields.map((item) => (
      {
        defaultCheckBox: item.defaultCheckBox, fieldId: item.fieldId,
        fieldName: item.fieldName, fieldType: item.fieldType, fulfillmentType: item.fulfillmentType, itemRequired: item.itemRequired,
        orderNumber: item.orderNumber, printOnInvoice: item.printOnInvoice, fieldItems: item.fieldItems.map((fieldItem) => (
          fieldItem
        ))
      }
    ))


  }

  const goBack = (() => {
    navigate(-1, { state: {} })

  })


  const singleCategory = sideProduct.subCategoryName
  const singleName = sideProduct.comboName
  const products = datas.products
  const reshuffleProduct = products && products.sort(() => Math.random() - 0.50)

  const relatedProduct = products && products.filter((product) => (
    product.subCategoryName == singleCategory && product.comboName !== singleName
  ))

  const handleShowNumber = (() => {
    setShowNumberDisplay(!showNumberDisplay)
  })

  // useEffect(()=>{
  //   const disAbled = sideProduct && sideProduct.map((data)=>(
  //             data.fields.map((data)=>(
  //                  data.fieldItems.map((data)=>(
  //                        data.currentQuantity
  //                  ))
  //             ))
  //   ))
  //   if( disAbled === 0){
  //          setDisable(true)
  //   }else{
  //     return null
  //   }
  // },[]) 
  // get product details
  useEffect(() => {
    setIsPending(true)
    mainaxios.get("ecommerce/getProductDetails")
      .then(response => {

        if (!response.statusText === "OK") {
          throw Error("Could not fetch data")
        }
        setIsPending(false)
        setData(response.data)
        // setProductLoad(response.data);  
        setError(null)

      })
      .catch(error => {
        setError(error.message)
        setIsPending(false)
        console.log(error)
      })
  }, []);


  // get feedback
  useEffect(() => {
    setIsPending(true)
    mainaxios.get("ecommerce/getProductFeedbacks")
      .then(response => {

        if (!response.statusText === "OK") {
          throw Error("Could not fetch data")
        }
        setIsPending(false)
        setFeedBack(response.data)
        // setProductLoad(response.data);  
        setError(null)

      })
      .catch(error => {
        setError(error.message)
        setIsPending(false)
        console.log(error)
      })
  }, []);





  const productsId = sideProduct.comboId;


  const addedDataFilter = data && data.filter((data) => (

    data.comboId === productsId
  ))


  const feebackData = feedBack && feedBack.filter((feed) => (
    feed.productId === productsId

  ))

  const feedBackNumber = feebackData.length



  return (

    <>


      <main className="mainbookinpage-all">
        <div>
          <ModeHeader />
          <Changer />
        </div>
        <div>
          <EcoNAvSearch />
        </div>
        <div className='iconBackBtn-div' onClick={goBack}> <TiArrowBackOutline size={35} className='iconBackBtn' /> </div>




        <div className="details-container">
          <div className="goodsdetails-container-flex">
            <div className="image-details-con" key={sideProduct.comboId} >
              <CCarousel controls indicators>
                <CCarouselItem >
                  <CImage className="d-block  imageContolCorol" src={sideProduct.picture} alt="slide 1" />
                </CCarouselItem>
              </CCarousel>
              <div className="secondimages-container">
                {isPending &&
                  <div className="lds-ellipsis">Loading...<div></div><div></div><div></div><div></div></div>
                }

                {
                  addedDataFilter && addedDataFilter.map((data) => (
                    data.product_images.map((data) => (
                      <Zoom>
                        <div className="hoverStyleImage">
                          <img src={data.image} className="second-downimg" />
                        </div>
                      </Zoom>
                    ))))}

              </div>
              <div className="button-toop">

              </div>
              <div className="timedown-image-container">
                <div >
                  <h2>{sideProduct.comboName}</h2>
                </div>
              </div>
              <div className="product-detailcontainers" >
                <h2 className="product-detailhead" >Product details</h2>
                <div>
                  <h3 ref={scrollRef} >Standard features</h3>
                  <p> {sideProduct.description} </p>

                </div>

                {
                  addedDataFilter && addedDataFilter.map((more) => (
                    <div>
                      <h3> More features</h3>
                      <p> {more.productDetails} </p>

                    </div>
                  ))
                }

              </div>
            </div>


            <div className="sidedetails-containerDetails">
              <h4 className="pnamecenter-side"> {sideProduct.comboName} </h4>
              <h4> <span className="pspan-sideprice"> Price:</span> {symbol}{sideProduct.price} </h4>

              <hr />
              <Toaster
                position="top-center"
                reverseOrder={false}
              />

              {
                //  sideProduct &&
                //  sideProduct.fields[0]?.fieldItems[0]?.currentQuantity === 1 ? (
                <button
                  className="button-6"
                  //  disabled
                  role="button"
                  onClick={() => handleAddToCart(dispatch(addToCart(cartData)))}
                >
                  ADD TO CART
                </button>
                //  ) : (
                //    <button
                //      className="button-6"
                //      role="button"
                //      onClick={() => handleAddToCart(dispatch(addToCart(cartData)))}
                //    >
                //      ADD TO CART
                //    </button>
                //  )
              }


              <hr />
              <Link to="/ecommerceupper"><button id="showcontact-btn" className="button-6" role="button">Continue shopping <GiReturnArrow /> </button></Link>
              <p onClick={() => { scrollRefControl(scrollRef) }} className="checking-product-details" style={{ fontWeight: 'bold' }} > <BiDetail size={25} className="coverIcode-side" /> Product details</p>

              {/* <div className="deliverySideNote">
                <div> <TbTruckDelivery size={25} className="coverIcode-side" />  </div>
                <div> <div style={{ fontWeight: 'bold' }}> Door Delivery </div>
                  <p>Delivery fees .. Ready to be delivered within a day, when you order now.</p>
                </div>
              </div> */}

              <div className="deliverySideNote">
                <div> <GiCardPickup size={25} className="coverIcode-side" />  </div>
                <div> <div style={{ fontWeight: 'bold' }}> Pickup Station </div>
                  <p>This product can be picked up from our station immediatly after your order, delivery fees excluded.</p>
                </div>
              </div>
              <hr />

              <div className="deliverySideNote">
                <div> <BiPhoneCall size={25} className="coverIcode-side" />  </div>
                <div> <div style={{ fontWeight: 'bold' }}> Call us </div>
                  <div >Call us for instant purchase: <div onClick={handleShowNumber} style={{ fontWeight: "bold", cursor: "pointer" }} className="hidEShow"> {showNumberDisplay ? <span>Hide Number </span> : <span> Show Number</span>}    </div> {
                    showNumberDisplay && <span style={{ color: "darkorange" }} > {callNumber.map((call) => (
                      call.callNumbers
                    ))} </span>
                  }
                  </div>
                </div>
              </div>
              <hr />


            </div>
          </div>


        </div>

        {isPending &&
          <div className="lds-ellipsis">Loading...<div></div><div></div><div></div><div></div></div>
        }

        {
          addedDataFilter && addedDataFilter.map((video) => (

            <div className="videoDiv-display">
              <video width="750" height="500" controls className="DisplayVD">
                <source src={video.videos} type="video/mp4" />
              </video>
            </div>

          ))
        }

        <div>
          {
            alert &&
            <div className="form-alerts toasts" >
              <div role="alert" className="fade form-warning alert  alert-dismissible show">
                <button type="button" className="btn-close" aria-label="Close alert" onClick={() => handleRemoveAlert(removeAlert)}></button>
                <div className="d-flex align-items-center">
                  <img alt="noti-icon" src="https://blueoctopusllc.files.wordpress.com/2013/08/verificare-apia.jpg" className="me-4" />
                  <p><b className="d-flex">The product has been added to your cart</b></p>

                </div>

              </div>
              <div className="showredirection">

                <Link to="/accountpage" className="navlink-link"> <button className="showredirection1"> <AiOutlineShoppingCart /> View cart</button> </Link>
                <Link to='/ecommerceupper' className="navlink-link"> <button className="showredirection2" >Continue shopping...</button> </Link>

              </div>
            </div>

          }

        </div>
        <Modal show={show} onHide={handleClose} animation={false}>
          <Modal.Header closeButton className="title-Cart-modal">
            <Modal.Title  >Item added to your cart! <i className=""><IoIosCheckmarkCircleOutline className="iconMark" /></i> </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {

              // productData.map((good)=>(
              <div key={sideProduct.comboId} className="modalCart-Warn">
                <div >
                  <img className="picModal-add" src={sideProduct.picture} />
                </div>
                <div>
                  <h4>{sideProduct.comboName}</h4>
                  <p>Price:{symbol}{sideProduct.price}</p>
                </div>

              </div>
              //  ))
            }

          </Modal.Body>
          <Modal.Footer>
            <Link to='/ecommerceupper'  ><Button className="backButton-shopping" style={{ background: "white", color: "#030334" }}>
              Back to Shopping <GiReturnArrow />
            </Button></Link>
            <Link to="/accountpage"  > <Button className="viewCart-buttonView" style={{ background: "#030334" }} >
              View Cart <BsCart3 />
            </Button></Link>
          </Modal.Footer>
        </Modal>

        <div className="second-displayEcom">
          {/* <h5 className="hot-deal-Prices">More to love</h5> */}
          <ul className="cardsId-Mimic">

            <div>
              <div className="antialiased ">
                <h5 className="selling_Tittle">Top Selling Products</h5>
                {isPending &&
                  <div className="lds-ellipsis">Loading...<div></div><div></div><div></div><div></div></div>
                }

                {
                  reshuffleProduct && reshuffleProduct.slice(0, 5).map((data) => (
                    <div className="downItem-div"
                      onClick={() => handleSelectedProduct(data)}
                    >
                      <div className="side-controlExtra">
                        <img src={data.picture} className="imgNameSide" />
                      </div>
                      <div className="trowText">
                        {data.comboName}
                        <p></p>
                        <p>{symbol}{data.price} </p>
                      </div>
                    </div>
                  ))
                }



              </div>
            </div>
            <div className="central-CoverLine">
              <h5 className="selling_Tittle">Customer's feedback</h5>
              <div className="cutomerFeedd-feed">

                <div>

                  <div> {feedBackNumber} Review(s) </div>

                </div>
              </div>
              {
                feebackData.length === 0 ? (
                  <div style={{ color: "darkorange", textAlign: "center", padding: "5px" }}>No Review for this Product Yet</div>
                ) : (
                  <div>
                    {
                      feebackData && feebackData.slice(0, 5).map((feed) => (
                        <div className="comment_divS">
                          <h5> <VscFeedback /> {feed.user_firstName} {feed.user_lastName} </h5>
                          <p>  {feed.comments} </p>


                        </div>

                      ))

                    }
                  </div>
                )
              }


            </div>


            <div className="antialiased ">
              <h5 className="selling_Tittle">Related products</h5>

              {isPending &&
                <div className="lds-ellipsis">Loading...<div></div><div></div><div></div><div></div></div>
              }


              {
                relatedProduct && relatedProduct.length === 0 ? (
                  <div style={{ color: "red", textAlign: "center" }}> No related product to display  </div>
                ) : (
                  relatedProduct && relatedProduct.slice(0, 5).map((related) => (
                    <div className="downItem-div" onClick={() => handleSelectedProduct(related)}>
                      <div className="side-controlExtra">
                        <img src={related.picture} className="imgNameSide" />
                      </div>
                      <div className="trowText">
                        {related.comboName}
                        <p></p>
                        <p> {symbol}{related.price}</p>
                      </div>
                    </div>
                  ))
                )


              }
            </div>





          </ul>
        </div>

        {scrolltop()}

        <div><Footer /></div>
      </main>



    </>
  )
})
export default GoodsDetails