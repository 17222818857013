
import {GoPlay} from "react-icons/go";
import {CiLogin, CiMicrophoneOn} from "react-icons/ci";
import{ImClock} from "react-icons/im";
import React,{useEffect, useRef, useState} from 'react';
import{TiMediaPause} from "react-icons/ti";
import {mainaxios} from "../../utility/axios"
import Player from "./player";
import moment from "moment";
// import Player from "../player";


const OnAir = ()=>{

   
   
    const [programs, setPrograms] = useState([]);
    const [currentProgram, setCurrentProgram] = useState(null);
      
    useEffect(()=>{
        mainaxios.get("programs/getPrograms")
        .then(response=>{
          
          if (!response.statusText=== "OK"){
            throw Error("Could not fetch data")
          }
          setPrograms(response.data)
        
        })
        .catch(error =>{
       
        })
       },[]);

    
      //  useEffect(()=>{
      //   const  updateCurrentProgram =(()=>{
      //       const now = new Date()
      //       const currentTime = now.getHours() + ':' + now.getMinutes();
      //       const currentProgram = programs && programs.find(
      //          (program)=>
              
      //          currentTime >= program.programStartTime?.replace(/^0/, "") &&
      //           currentTime <= program.programEndTime?.replace(/^0/, "")
                 
      //       );
                           
      //       setCurrentProgram(currentProgram);
             
      //   })
        
      //     updateCurrentProgram()
      //   const updateInterval = setInterval(()=>{
      //   updateCurrentProgram(); 
       
      //  },1000);

      
  
      //  return () => clearInterval(updateInterval)

      //  },[programs]);



      useEffect(() => {
        const updateCurrentProgram = () => {
        const now = new Date();
        const currentTime = now.getHours() + ':' + now.getMinutes();
        
        if (Array.isArray(programs)) {
        const currentProgram = programs.find(
        (program) =>
        currentTime >= program.programStartTime?.replace(/^0/, '') &&
        currentTime <= program.programEndTime?.replace(/^0/, '')
        );
        setCurrentProgram(currentProgram);
        } else {
        // console.error("programs is not an array", programs);
        }
        };
        
        updateCurrentProgram();
        const updateInterval = setInterval(() => {
        updateCurrentProgram();
        }, 1000);
        
        return () => clearInterval(updateInterval); // Clean up the interval on component unmount
        }, [programs]);
        

       
     return(
        <>
 
       
            <div className="onair-div">
            <div className="containOn-air">
           
              <div className="col-md-12 text-center">
                <h3 className="animate-charcter"> On Air Now  <CiMicrophoneOn /></h3>
              </div>
           
          </div>
          
                
                {
                currentProgram && currentProgram ? (
                    <div>
                    <div className="topdisplay-program">
                 
                 <u><h3 className="morning-show">{currentProgram.programName }</h3></u>
                 <p className="onair-time">{ currentProgram.programStartTime
                } - {currentProgram.programEndTime}  <i><ImClock className="time-icon"/></i></p>
              
                 <p className="presenters-name">{currentProgram.programpresenters && currentProgram.programpresenters
                   .map((person)=>(
                    person.firstName   
                 ))
            }</p>
                 </div>
                 <div className="listen-div">
                        
                        
                           
                 </div>
                

             </div>

                ) : (
                    <p className="downbord"> Next program coming soon...</p>
                )
            }
                    
                   <Player/> 
              
                    

             
                <div className="play-div">
                   
           
                </div>
              

            </div>
        </>
    )
}
export default OnAir