import { BiLogOutCircle } from "react-icons/bi"
import { BsFillPersonFill, BsList } from "react-icons/bs"
import { Link, useNavigate, useNavigation } from "react-router-dom"
import { Modal, Nav } from 'react-bootstrap';
import logo from "../images/logo fm2.jpg"

import { CCollapse, CContainer, CNavLink, CNavbar, CNavbarBrand, CNavbarNav, CNavbarToggler } from '@coreui/react';
import { useState } from "react";


const ProfileNav = (() => {

  const [visible, setVisible] = useState(false);
  const [logOut, setLogOut] = useState(false)
  const navigation = useNavigate()

  const handleLogout = () => {
    navigation("/ecommerceupper", { state: {} });
    localStorage.removeItem("ecomUserData")

  };

  const profiles = JSON.parse(localStorage.getItem('ecomUserData'));

  const directBack = (() => {
    navigation("/ecommerceupper", { state: {} });
  })
  return (

    <>


      <CNavbar expand="lg" className="control-backgroundnav">
        <CContainer fluid>
          <span style={{ textDecoration: "none", zIndex: "20", display: 'block' }} onClick={directBack}><CNavbarBrand className='colapse-nav-profileNav'><img src={logo} style={{ width: "20px", height: "20px" }} />  Ebeonadi E-commerce</CNavbarBrand></span>
          <BsList
            aria-label="Toggle navigation"
            aria-expanded={visible}
            onClick={() => setVisible(!visible)}
            className='back-dropIcon'
            size={25}
          />

          <CCollapse className="navbar-collapse colapse-nav-profile" id="naVShift" visible={visible}>
            <CNavbarNav component="nav" className='navcontrol-push'>
              <CNavLink className='colapse-nav-profiles'> <li> Hi ! {profiles.firstName}</li></CNavLink>
              <Link to="/mainprofile" className="proHide-link"><CNavLink active className='colapse-nav-profile'>
                My Account
              </CNavLink></Link>

              <CNavLink onClick={() => setLogOut(true)} className='colapse-nav-profile'>Log out <BiLogOutCircle className="logout-take" /></CNavLink>

            </CNavbarNav>
          </CCollapse>
        </CContainer>
      </CNavbar>

      <Modal
        size="sm"
        show={logOut}
        onHide={() => setLogOut(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Logout
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are You Sure You Want to logout ?</p>
          <button className="btn-remove btn btn-danger" onClick={handleLogout} >Logout</button>
        </Modal.Body>


      </Modal>


    </>
  )
})
export default ProfileNav