import React, { useEffect, useState } from "react"
import "./header.css"
import logo1 from "./../images/logo fm2.jpg"
import {BsPersonWorkspace} from "react-icons/bs"
import ContactShow from "../directpages/contactshow";
import {MdOutlineCancel, MdOutlineLogout}from "react-icons/md"
import {ImMenu3}from "react-icons/im"
import { Await,NavLink, useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { useForm } from "react-hook-form";
import {mainaxios,fbaxios} from "../../utility/axios"
import { toast } from "react-toastify";

let renderCount = 0;






const ModifiedNav = (()=>{

  const [lgShow, setLgShow] = useState(false);
     
  const navigation = useNavigate()
  const [contactShow, setContactShow] = useState(false)
  const popContact = () => {
    setContactShow(!contactShow)
  }


  const handleLogout = () => {
    localStorage.removeItem("adminData")
    navigation("/loginpage", { state: {} });
  };

  const [modalShow, setModalShow] = useState(false);


  const profiles = JSON.parse(localStorage.getItem('adminData'));

  const { register, handleSubmit, formState: { errors } } = useForm();
  renderCount++;

  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [isPendings, setIsPendings] = useState(false);

  const notify = () => toast("Creating company news...");

  const handleSubmited = async (data) => {
    
    setIsPendings(true)

    await mainaxios.post("programs/createCompanyNews",data)

      .then((res) => {
        if (res.data.success) {
          setIsPendings(false)
          setSuccess("Company news created successfully");
          setErrorMessage("");
          } else {

          setSuccess(res.data.message);
          setIsPendings(false)
        }
          
      }).catch((err) => {
        console.log("err", err)
        setErrorMessage("Something went wrong. Please try again later.");
        setIsPendings(false)
      })
      setModalShow(false)
    notify()

  };


  const closeErrorClick = (() => {
    setErrorMessage(false)
  })

  useEffect(() => {

    let timeout;
    if (success) {
      timeout = setTimeout(() => {
        setSuccess(false);
      }, 5000)
    }
    return () => {
      clearTimeout(timeout);
    };

  }, [success])

    return(


      <>
        <div className="nav-bar-super">

          <NavLink to="/" >  <h1 className="radio-brand">EBEONADI FM</h1></NavLink>


          <ul className="nav-list" id="superadmin-ulnav">
            <li className="superadmin-li" onClick={() => setModalShow(true)}>Add Company News </li>
            <span className="line-super"></span>
            <li className="namenav-log" id="name-super-ebeonadi"  >{profiles.firstName}</li>

            
              {/* <li className="img-supername"> <i> <img className="profile-image-static" src={profiles.image} /></i>  Super Admin </li> */}


          </ul>
          <li className="proms-logout"onClick={() => setLgShow(true)}>  Log out  <MdOutlineLogout /></li>

        </div>
        <div className="display-companymessage">
        {
                success && <div >

                  <button className="btn btn-success toast-success-apply">{success}</button>

                </div>
              }
              {errorMessage && <div >
                <button className="btn btn-danger toast-success-btnapply">{errorMessage} <span onClick={closeErrorClick} className="remove-erroricon"><MdOutlineCancel size={25} /></span></button>


              </div>
              }

   {isPendings && 
            
            <div className="lds-ellipsis" id="id-ellipsis">Creating please wait<div></div><div></div><div></div><div></div></div>
            } 
              </div>

        <Modal show={modalShow} aria-labelledby="contained-modal-title-vcenter">
          <Modal.Header >
            <Modal.Title id="contained-modal-title-vcenter">
              Add Company News or Promo
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">

            <div>
            
              <form onSubmit={handleSubmit((data, event) => {

                event.target.reset()
                handleSubmited(data)

              })}>
                <label className="name-label" >Topic:<span className="star-required">*</span> </label> <br />

                <input className="name-labelbold"   {...register("topic", { required: "This field is required" })} />

                <p className="cum-error">{errors.topic?.message}</p>
                <label className="name-label" > Second Topic : </label><br />

                <input className="name-labelbold" {...register("secondTopic" )}  />

                <br /> <br />
                <label className="name-label"  >Start date time :<span className="star-required">*</span> </label><br />

                <input className="name-labelbold" type="datetime-local" {...register("startDateTime", { required: "This field is required" })}  />
                <p className="cum-error">{errors.startDateTime?.message}</p>

                <label className="name-label"  >End Date time : </label><br />

                <input className="name-labelbold" type="datetime-local" {...register("endDateTime")}  />

                <br /> <br />

                <label className="name-label"  >Link</label> <br />


                <input className="name-labelbold" {...register("link")}  />


                <br /> <br />
                <label className="name-label"  >Details : </label><br />

                <textarea className="name-labelbold-txt" {...register("details", { required: "This field is required" })}  />


                <p className="cum-error">{errors.details?.message}</p>


                <Button type="submit" className="btn btn-primary">Submit</Button>
                 
                          </form> 
                      </div>
          
        
      </Modal.Body>
      <Modal.Footer>
     
        <Button  onClick={() => setModalShow(false)} className="btn btn-secondary">Close</Button>
      </Modal.Footer>
       
    </Modal>
    <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Logout
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
       <p>Are You Sure You Want to logout?</p>
       <button  className="btn-remove btn btn-danger" onClick={handleLogout} >Logout</button> 
       </Modal.Body>
       
      
      </Modal>
   



    


        
        </>
    )
})
export default ModifiedNav