
import './App.css';
import Home from './components/Home';
import {Routes, Route,Switch, BrowserRouter as Router } from 'react-router-dom';
import NewsDisplay from './components/directpages/news';
import NoFound from './components/directpages/nofoundpage';
import News1 from './components/directpages/news1';
import News2 from './components/directpages/news2';
import ListenLive from './components/directpages/listenlive';
import Programs from './components/directpages/programs';
import NewsPage from './components/directpages/newspage';
import Schedule from './components/directpages/schedule';
import Player from './components/directpages/player';
import GetJobs from './components/directpages/getjobdetails';
import ShowJobDetails from './components/directpages/showjobdetails';
import News from './components/news/newss';
import SearchData from './components/directpages/searchdata';
import AboutUs from './components/directpages/aboutus';
import AdvertRAte from './components/directpages/Advertrate';
import Program2 from './components/directpages/program2';
import Program3 from './components/directpages/program3';
import Program4 from './components/directpages/program4';
import PromsGames from './components/directpages/promsgames';
import AdaOgidi from './components/directpages/adaogidi';
import RadioAchieve from './components/Ebonaditv/radioachieve';
import CultureTradition from './components/Ebonaditv/culturetradition';
import Edutainment from './components/Ebonaditv/edutainment';
import SubmitContent from './components/Ebonaditv/submitcontent';
import MovieReview from './components/Ebonaditv/moviereview';
import MovieDetails from './components/Ebonaditv/moviedetails';
import JobServicesRate from './components/directpages/jobservicesrate';
import AboutOurJob from './components/directpages/aboutourjob';
import SpeciaPromo from './components/marketplacenews/specialpromo';
import BuyMarketPlace from './components/marketplacenews/buymarketplace';
import SellMarketPlace from './components/marketplacenews/sellmarketplace';
import AboutEcommerce from './components/marketplacenews/aboutecommerce';
import AnambraNews from './components/marketplacenews/anambranews';
import GeneralNews from './components/marketplacenews/generalnews';
import AdminDashboard from './components/adminpage/admindashboard';
import BasicExample from './components/adminpage/dashboardpage';
import AdminLogin from './components/adminpage/adminlogin';
import TopNews from './components/directpages/topnews';
import LocalNews from './components/news/localnews';
import EntertainmentNews from './components/news/entertainmentnews';
import SportsNews from './components/news/sportsnews';
import CulturalNews from './components/news/culturalnews';
import PoliticalNews from './components/news/politicalnews';
import PostJob from './components/directpages/postjob';
import ModifiedNav from './components/layouts/modifiednav';
import SuperAdmin from './components/adminpage/superAdmin';
import MobileNav from './components/layouts/mobilenav';
import EcommerceUpper from './components/Ecommerce/ecommerce_upper';
import GoodsDetails from './components/Ecommerce/goodsdetails';
import HowToBuy from './components/Ecommerce/howtobuy';
import BookingPage from './components/Ecommerce/bookingpage';
import SignUpPage from './components/Ecommerce/signuppage';
import SignInPage from './components/Ecommerce/signinpage';
import EcoNAvSearch from './components/Ecommerce/econavsearch';
import AccountPage from './components/Ecommerce/accountpage';
import Products from './components/Ecommerce/product';
import MainProfile from './components/Ecommerce/mainprofile';
import CreateCompany from './components/adminpage/e-commerce-admin/create-company';
import EcomHistory from './components/Ecommerce/Ecom-history';
import GuestOrder from './components/Ecommerce/guestOrder';
import ForgetPasswordPage from './components/Ecommerce/forgetpassword-page';
import ResetPassword from './components/Ecommerce/resetPassword';
import ForgotPasswordTemplate from './components/Ecommerce/forgotpassTemplate';
import TokenField from './components/Ecommerce/tokenField';
import CreateProduct from './components/adminpage/e-commerce-admin/create-product';





function App() {

 


  return (
   <>

        
      <Routes basename="/">
        <Route path='/' element={<Home />} />
        <Route path='/news' element={<NewsDisplay />} />
        <Route path='*' element={<NoFound />} />
        <Route path='/news1' element={<News1 />} />
        <Route path='/news2' element={<News2 />} />
        <Route path='/listenlive' element={<ListenLive />} />
        <Route path='/programs' element={<Programs />} />
        <Route path='/newspage' element={<NewsPage />} />
        <Route path='/newspage-1' element={<NewsPage />} />
        <Route path='/schedule' element={<Schedule />} />
        <Route path='/player' element={<Player />} />
        <Route path='/schedule' element={<Schedule />} />
        <Route path='/getjobs' element={<GetJobs />} />
        <Route path='/showjobdetails' element={<ShowJobDetails />} />
        <Route path='/newspage' element={<NewsPage />} />
        <Route path='/news' element={<News />} />
        <Route path='/searchdata' element={<SearchData />} />
        <Route path='/aboutus' element={<AboutUs />} />
        <Route path='/advertrate' element={<AdvertRAte />} />
        <Route path='/program2' element={<Program2 />} />
        <Route path='/program3' element={<Program3 />} />
        <Route path='/program4' element={<Program4 />} />
        <Route path='/promsgames' element={<PromsGames />} />
        <Route path='/advertrate' element={<AdvertRAte />} />
        <Route path='/adaogidi' element={<AdaOgidi />} />
        <Route path='/radioachieve' element={<RadioAchieve />} />
        <Route path='/culturetradition' element={<CultureTradition />} />
        <Route path='/edutainment' element={<Edutainment />} />
        <Route path='/submitcontent' element={<SubmitContent />} />
        <Route path='/moviereview' element={<MovieReview />} />
        <Route path='/moviedetails' element={<MovieDetails />} />
        <Route path='/jobservicesrate' element={<JobServicesRate />} />
        <Route path='/aboutourjob' element={<AboutOurJob />} />
        <Route path='/specialpromo' element={<SpeciaPromo />} />
        <Route path='/buymarketplace' element={<BuyMarketPlace />} />
        <Route path='/sellmarketplace' element={<SellMarketPlace />} />
        <Route path='/aboutecommerce' element={<AboutEcommerce />} />
        <Route path='/anambranews' element={<AnambraNews />} />
        <Route path='/generalnews' element={<GeneralNews />} />
         <Route path='/loginpage' element={<AdminLogin />} /> 
        <Route path='/admindashboard' element={<AdminDashboard />} />
        <Route path='/dashboardpage' element={<BasicExample />} />
        <Route path='/topnews/:newsTopic' element={<TopNews />} />
        <Route path='/program2/:programsId' element={<Program2 />} />
        <Route path='/jobdetails/:jobId' element={<ShowJobDetails />} />
        <Route path='/localnews' element={<LocalNews />} />
        <Route path='/entertainmentnews' element={<EntertainmentNews />} />
        <Route path='/sportsnews' element={<SportsNews />} />
        <Route path='/culturalnews' element={<CulturalNews />} />
        <Route path='/politicalnews' element={<PoliticalNews />} />
         <Route path='/adminLogin' element={<AdminLogin />} /> 
        <Route path='/jobs' element={<GetJobs />} />
        <Route path='/contactUs' element={<AboutUs />} />
        <Route path='/postjob' element={<PostJob />} />
        <Route path='/modifiednav' element={<ModifiedNav />} />
        <Route path='/superadmin' element={<SuperAdmin />} />
        <Route path='/mobilenav' element={<MobileNav />} />
        <Route path='/ecommerceupper' element={<EcommerceUpper />} />
        <Route path='/howtobuy' element={<HowToBuy />} />
        <Route path='/bookingpage' element={<BookingPage />} />
        <Route path='/signuppage' element={<SignUpPage />} />
        <Route path='/signinpage' element={<SignInPage />} />
        <Route path='/econavsearch' element={<EcoNAvSearch />} />
        <Route path='/accountpage' element={<AccountPage />} />
        <Route path='/products' element={<Products />} />
        <Route path='/mainprofile' element={<MainProfile />} />
        <Route path='/createcompany' element={<CreateCompany />} />
        <Route path='/goodsdetails/:productId' element={<GoodsDetails />} />
        <Route path='/ecom-history' element={<EcomHistory />} />
        <Route path='/guestOrder' element={<GuestOrder />} />
        <Route path='/forgot_password' element={< ForgetPasswordPage />} />
        <Route path='/reset_password' element={< ResetPassword />} />
        <Route path='/password_template' element={< ForgotPasswordTemplate />} />
        <Route path='/insert_token' element={< TokenField />} />
        <Route path='/add_details/:addDetails' element={<CreateProduct  />} />
        
        
      
        
        </Routes>
      

   </>
  )
}

 export default App;
