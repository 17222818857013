import { MDBBtn, MDBContainer, MDBCard, MDBCardBody, MDBCardImage, MDBRow, MDBCol, MDBIcon, MDBInput, MDBCheckbox }
  from 'mdb-react-ui-kit';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import EcommerceNav from './ecommerce_nav';
import Footer from '../layouts/footer';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { mainaxios, fbaxios } from "../../utility/axios";
import { useDispatch, useSelector } from 'react-redux';
import OtpInput from 'react-otp-input';
import "./categories.css"
import "./ecommerce.css"
import EcoNAvSearch from './econavsearch';
import ModeHeader from '../layouts/modeheader';

const TokenField = (() => {

  const { register, handleSubmit, formState: { errors } } = useForm();


  const handleSubmitClick = (() => {
    handleSubmit()

  })

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(120);

  // const deadline = "December, 31, 2022";

  const [otp, setOtp] = useState('');
  const navigate = useNavigate()
  const [isPendings, setIsPendings] = useState(false);
  const [errorMessage, setErrorMessage] = useState("")
  const [disabled, setDisabled] = useState(true)
  const [successMessage, setSuccessMessage] = useState("");
  const { items } = useSelector((state) => state.cart);
  const [hideToken, setHideToken] = useState(false)
  const [hideSend, setHideSend] = useState(false)


  const sendPassAgain = JSON.parse(localStorage.getItem("passwordDetails"));



  const handleSubmited = async () => {
    setDisabled(false);
    setIsPendings(true);
    const otpData = {
      token: otp
    }

    await mainaxios.put("ecommerce/tokenVerification", otpData)

      .then((res) => {

        if (res.data.success === true) {
          navigate("/reset_password", { state: {} })
          setDisabled(true);
          const tokenData = res.data
          localStorage.setItem("tokenData", JSON.stringify(tokenData))

        } else {
          setIsPendings(false);
          setErrorMessage(res.data.message)
        }

      }).catch((err) => {
        console.log("err", err)
        setIsPendings(false);
        setErrorMessage("Invalid code")
        setDisabled(true);
      })


  }

  const handleSendAgain = async (event) => {
    event.preventDefault()
    setDisabled(false);
    setIsPendings(true);
    const email = {
      emailAddress: sendPassAgain.emailAddress
    }

    await mainaxios.post("ecommerce/sendToken", sendPassAgain.emailAddress)

      .then((res) => {

        if (res.data.success === true) {
          setSuccessMessage("code sent to your email");
          setIsPendings(false);
          setDisabled(true);
          // window.location.reload();
        } else {
          setIsPendings(false);
        }

      }).catch((err) => {

        setIsPendings(false);
        setDisabled(true);
      })


  }




  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1); // Decrement the seconds
        setHideSend(false)
      } else {
        clearInterval(interval); // Stop the countdown when it reaches 0
        setSeconds("Time elapsed"); // Update the message
        setDisabled(false)
        setHideSend(true)
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const checkColor = (description) => {
    switch (description) {
      case "number":
        return "green";
      case "Time elapsed":
        return "red";
      default:
    }
  };


  return (

    <>


      <main className='mainbookinpage-all' id='mainbookinpage-allId'>
        <div>
          <ModeHeader />
        </div>
        <div>
          <EcoNAvSearch />
        </div>


        {
          successMessage && <div >

            <button className="btn btn-success toast-success-btn">{successMessage}</button>

          </div>
        }
        <MDBContainer fluid className='signupcontainer' show={() => setHideToken(false)}>

          <MDBRow className='d-flex justify-content-center align-items-center h-100'>
            <MDBCol col='12'>
              <form onSubmit={handleSubmit(() => {

                setOtp(otp)


              })} >
                <MDBCard className='bg-white my-5 mx-auto' style={{ borderRadius: '1rem', maxWidth: '500px' }}>
                  <h2 className="fw-bold mb-2 text-center">Enter Code </h2>
                  <p className="test4reset">Enter otp sent to your email to reset your password!</p>
                  <div className='otpDiv'>
                    <OtpInput

                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      renderSeparator={<span>-</span>}
                      renderInput={(props) => <input {...props} className='ebeInput' />}

                    />
                  </div>
                  <button className='submitOtp' onClick={handleSubmited}>  {!isPendings ? (
                    <span> Send </span>
                  ) : (
                    <span className="loader-load"></span>
                  )
                  }



                  </button>
                  {errorMessage && <div className="errlogin">{errorMessage}</div>}
                  {/* <p className='countOtp' style={{color:`${checkColor(seconds)}`}}> {seconds } </p> */}
                  <p></p>
                  {
                    hideSend && <span className='didtnGet'>Did't get the token<form onSubmit={handleSendAgain}> <button className='btnDidtnGet' type='submit'>resend</button></form> </span>
                  }

                </MDBCard>
              </form>

            </MDBCol>
          </MDBRow>

        </MDBContainer>



        <div>
          <Footer />
        </div>
      </main>

    </>
  )
})
export default TokenField