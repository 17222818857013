import { Button, Col, Modal, Row, Table } from "react-bootstrap"
import ProfileNav from "./profilenav"
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from "react";
import Footer from "../layouts/footer";
import useFectch from "../adminpage/createbackend";
import moment from "moment";
import { useForm } from "react-hook-form";
import { mainaxios, fbaxios } from "../../utility/axios";
import { MdOutlineCancel } from "react-icons/md";
import ModeHeader from "../layouts/modeheader";
import Changer from "../layouts/changer";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";



const EcomHistory = (() => {

    const { register, handleSubmit, formState: { errors } } = useForm();


    const userData = JSON.parse(localStorage.getItem("ecomUserData"));
    const { data: product, isPending, error } = useFectch(`ecommerce/getUsersProfile_orderEcommerce/${userData.userId}`);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [details, setDetails] = useState([])
    const [firstName, setFirstName] = useState("");
    const [lastName, setLasttName] = useState("");
    const [productId, setProductId] = useState("")

    const handleDetails = ((product) => {
        setDetails(product.order_products)
        setShow(true)
    })

    const [showReview, setShowReview] = useState(false);

    const handleCloseReview = () => setShowReview(false);

    const handleShowReview = () => {
        setShowReview(true);
        setShow(false)
    }

    const [isPendings, setIsPendings] = useState(false);
    const [errorMessage, setErrorMessage] = useState(' ')
    const [disabled, setDisabled] = useState(true)
    const [success, setSuccess] = useState("")

    const handleSubmited = async (data) => {

        setDisabled(false);
        setIsPendings(true);

        await mainaxios.post("ecommerce/createProductFeedbacks", data)

            .then((res) => {
                console.log("res", res)
                setIsPendings(false);
                setDisabled(true);
                setSuccess(res.data.message)
            }).catch((err) => {
                console.log("err", err)
                setIsPendings(false);
                setDisabled(true);
                setErrorMessage("Something went wrong, try again later")
            })

        setShowReview(false)

    }
    const closeErrorClick = (() => {
        setErrorMessage(false)
    })

    const filterHistory = product && product.filter((product) => (
        product.statuss === "Completed"
    ))

    useEffect(() => {

        let timeout;
        if (success) {
            timeout = setTimeout(() => {
                setSuccess(false);
            }, 5000)
        }
        return () => {
            clearTimeout(timeout);
        };

    }, [success])

    const symbol = Intl.NumberFormat('en-US', {
        style: 'currency',
        currencyDisplay: 'narrowSymbol',
        currency: 'NGN'
    })
        .formatToParts(0)
        .filter(part => part.type === 'currency')
        .map(part => part.value)
        .join('')




    return (
        <>
            <div className="mainbookinpage-all">

                <div>
                    <ModeHeader />
                    <Changer />
                </div>
                <div>
                    <ProfileNav />
                </div>



                {
                    success &&

                    <div className="alert success-alert">
                        <h3> <IoMdCheckmarkCircleOutline /> Thank you for submitting your feedback</h3>
                        <a className="close" onClick={closeErrorClick}><MdOutlineCancel /></a>

                    </div>


                }
                {/* {errorMessage && <div >
                    <button className="btn btn-danger toast-success-btn">{errorMessage} <span onClick={closeErrorClick} className="remove-erroricon"><MdOutlineCancel size={25} /></span></button>

                </div>
                } */}



                <div className="history-Contain">
                    <div className="searchHistory-hiscon">
                        <div>
                            <h4>My Order History</h4>
                        </div>

                        {/* <div class="main-search-input-wrap">
                            <div class="main-search-input fl-wrap">
                                <div className="main-search-input-item">
                                    <input type="text" placeholder="Search by product name..." />
                                </div>

                                <button className="main-search-button">Search</button>
                            </div>


                        </div> */}


                    </div>
                    <Table striped bordered hover size="sm" className="controlTable-his" >

                        <thead>
                            <tr className="tbview-head-history">
                                <th>S/N</th>
                                <th>Pickup address</th>

                                <th>Date ordered</th>
                                <th>Compl. date</th>
                                <th>T.amount</th>
                                <th>Status</th>
                                {/* <th>P.status</th> */}
                                {/* <th>payment </th> */}
                                <th>Order code</th>
                                <th>More</th>



                            </tr>
                        </thead>




                        <tbody>
                            {
                                isPending && <span className="loader-loads"></span>
                            }
                            {

                                filterHistory.length === 0 ? (


                                    <div>

                                        <p className="noHistory-text">You have no Order history yet</p>

                                    </div>


                                ) : (

                                    product.filter((pro) => (
                                        pro.statuss.includes("Completed")
                                    )).map((product, i) => (

                                        <tr  >
                                            <td className='tdd' >{i + 1} </td>
                                            <td className='tdd'>{product.deliveryAddress}</td>

                                            <td className='tdd'>{moment(product.createdDateTime).format('lll')}</td>
                                            <td className='tdd' >{moment(product.updatedDateTime).format('lll')}</td>
                                            <td className='tdd' > {symbol}{product.totalAmount}</td>
                                            <td className='tdd' >{product.statuss}</td>
                                            {/* <td className='tdd' >{"Not paid"}</td> */}
                                            {/* <td className='tdd' >{product.paymentOption}</td> */}
                                            <td className='tdd' >{product.orderCode}</td>
                                            <td className='tdd' ><button className="button-60" role="button" onClick={() => handleDetails(product)}>Details/Review</button> </td>



                                        </tr>



                                    ))
                                )
                            }
                        </tbody>

                    </Table>
                </div>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Ordered history list</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            details.map((product) => (
                                <div>
                                    <Form >
                                        <Form.Group className="mb-3" >

                                            <h4 className="modal-historyHead">{product.productName} </h4>
                                        </Form.Group>
                                        <Row className="mb-3 ">
                                            <Form.Group as={Col} controlId="formGridState">

                                                <p> <span className="proBold"> Price:</span> {product.price} </p>
                                                <p> <span className="proBold"> Quantity:</span> {product.quantity} </p>
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridState" >
                                                <div>
                                                    <img className="pic-ModalProduct" src={product.picture} />
                                                    {
                                                        isPending && <span className="loader-load"></span>
                                                    }
                                                </div>
                                            </Form.Group>
                                        </Row>
                                        <Form.Group className="mb-3" >
                                            <Form.Label><span className="proBold"> Details </span></Form.Label>
                                            <div>
                                                {product.description}
                                            </div>
                                        </Form.Group>

                                    </Form>
                                    <hr />
                                    <button className="button-16" role="button" onClick={handleShowReview}>Review this product</button>
                                    <hr />
                                </div>

                            ))
                        }

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>



                <Modal show={showReview} onHide={handleCloseReview}>
                    <Modal.Header closeButton>
                        <Modal.Title>Make us serve you better, drop review</Modal.Title>
                    </Modal.Header>
                    <Form style={{ padding: "10px" }} onSubmit={handleSubmit((data, event) => {
                        event.target.reset()
                        console.log("dataReview", data);
                        handleSubmited(data)


                    })}>

                        {
                            details.map((data) => (

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" hidden>

                                    <Form.Control
                                        value={data.productId}
                                        {...register('productId')}
                                    />
                                </Form.Group>


                            ))
                        }

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" hidden>

                            <Form.Control
                                value={userData.firstName}
                                {...register('firstName')}
                            />
                        </Form.Group>


                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" hidden>

                            <Form.Control
                                value={userData.lastName}
                                {...register('lastName')}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">

                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Comment <span className="star-required">*</span> </label>
                                <textarea
                                    className="form-control"
                                    id="exampleFormControlTextarea1"
                                    rows="5"
                                    {...register("comment", { required: "Comment is required" })} />
                            </div>
                            <span className="cum-error">{errors.comment?.message}</span>
                        </Form.Group>

                        <Modal.Footer>


                            <button className="button-16" type="submit" onClick={disabled}> {!isPendings ? <span>Comment</span> : <span className="loader-load"></span>}</button>

                        </Modal.Footer>

                    </Form>
                </Modal>



                <div className="history-footerContainer">

                    <Footer />
                </div>
            </div>


        </>
    )
})
export default EcomHistory