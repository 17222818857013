import { Button, Col, Row } from "react-bootstrap"
import ProfileNav from "./profilenav"
import { BsClockHistory, BsPersonFill } from "react-icons/bs"
import { MdOutlineCancel, MdOutlinePreview, MdPerson3 } from "react-icons/md"
import { FaAddressBook } from "react-icons/fa"
import { BiLogOutCircle } from "react-icons/bi"
import { useRef, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Footer from "../layouts/footer"
import { Link, useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { GiReturnArrow } from "react-icons/gi"
import { RiLockPasswordFill } from "react-icons/ri";
import axios from "axios"
import { useForm } from "react-hook-form"
import { mainaxios, fbaxios } from "../../utility/axios"
import useFectch from "../adminpage/createbackend"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import ModeHeader from "../layouts/modeheader"
import Changer from "../layouts/changer"






const MainProfile = (() => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [logOut, setLogOut] = useState(false)

  const [showPage, setShowPage] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showed, setShowed] = useState(false);
  const [showedPass, setShowedPass] = useState(false);

  const handleShowedPass = () => {
    setShowedPass(true)
    setShowProfile(false)
  }
  const handleShowed = () => {
    setShowed(true)
    setShowProfile(false)
  }
  const handleClosed = () => setShowed(false);
  const handleClosedPass = () => setShowedPass(false);



  const handleCloseProfile = () => setShowProfile(false);
  const handleShowProfile = () => setShowProfile(true);

  const [states, setStates] = useState([]);
  const [localGvt, setLocalGvt] = useState([]);
  const [newLga, setNewLga] = useState([]);
  const [state, setState] = useState();
  const [stateName, setStateName] = useState('')
  const [city, setCity] = useState()



  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [additionalNumber, setAdditionalNumber] = useState("");

  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  const [errorMess, setErrorMess] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isPendings, setIsPendings] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [userIdPass, setUserIdPass] = useState('')


  const [defaultFirstName, setDefaultFirstName] = useState('');
  const [defaultLastName, setDefaultLastName] = useState("");
  const [defaultEmailAddress, setDefaultEmailAddress] = useState("");
  const [defaultPhoneNumber, setDefaultPhoneNumber] = useState("");
  const [defaultAddress, setDefaultAddress] = useState("");
  const [defaultAdditionalNumber, setDefaultAdditionalNumber] = useState("");
  const [defaultState, setDefaultState] = useState('');
  const [defaultCity, setDefaultCity] = useState("");



  const getMainLGA = (e) => {
    const stateId = state

    const filterLga = localGvt.filter((lga) => (
      lga.state_id === stateId
    ))

    setNewLga(filterLga);
  };

  const onChangeHandler = (e) => {
    setState()
    const newStateId = e.target.value
    setState(newStateId)
    const newState = states.find((stat) => stat.stateId === newStateId);

    setStateName(newState.name)


  }



  // const { data: user, isPending, error } = useFectch('registrations/getUsers_ebeonadiEcommerce')



  const [data, setData] = useState([]);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);



  useEffect(() => {
    setIsPending(true)
    mainaxios.get("registrations/getUsers_ebeonadiEcommerce")
      .then(response => {

        if (!response.statusText === "OK") {
          throw Error("Could not fetch data")
        }
        setIsPending(false)
        setData(response.data)

        setError(null)

      })
      .catch(error => {
        setError(error.message)
        setIsPending(false)
        console.log(error)
      })


    const filterAll = data && data.map((data) => (
      setDefaultFirstName(data.firstName),
      setDefaultLastName(data.lastName),
      setDefaultEmailAddress(data.emailAddress),
      setDefaultPhoneNumber(data.phoneNumber),
      setDefaultAddress(data.address),
      setDefaultAdditionalNumber(data.additionalNumber),
      setDefaultState(data.state),
      setDefaultCity(data.city)


    ))

  }, []);





  const userData = JSON.parse(localStorage.getItem("ecomUserData"));
  
  const Navigate = useNavigate();
  useEffect(() => {
    const checkLogin = () => {
      if (userData.statuss === "active") {

        setShowPage(true);

      } else {
        setShowPage(false);
        Navigate("/ecommerceupper", { state: {} });
      }
    };
    checkLogin();
  }, []);


  const navigation = useNavigate()

  const handleLogout = () => {

    navigation("/ecommerceupper", { state: {} });
    localStorage.removeItem("ecomUserData")

  };



  useEffect(() => {
    const getState = async () => {
      const resState = await axios.get('https://ipms.intellims.com:5300/api/v1/public/states')

      const relState = await resState.data
      setStates(await relState)
    }

    getState()
  }, [])


  useEffect(() => {
    const getLocalG = async () => {
      const localLga = await axios.get(
        "https://ipms.intellims.com:5300/api/v1/public/local_govt"
      );

      const localGvt = await localLga.data;

      setLocalGvt(localGvt)

    };


    getLocalG()
  }, [])

  const users = {
    firstName, lastName, address, phoneNumber, emailAddress, city, state: stateName
  }



  const handleUpdate = async () => {
    setIsPendings(true);
    const userId = userData.userId



    await axios.put(`registrations/updateUser_ebeonadiEcommerce/${userId}`, users)

      .then((res) => {


        if (res.data.success) {
          setIsPendings(false);
          setSuccessMessage("Job Updated Successfully");
          window.location.reload();
          setErrorMess("");
        } else {
          setIsPendings(false);
          setErrorMess(res.data.message);
        }
      }).catch((err) => {
        setIsPendings(false);
        console.log("err", err)
        setErrorMessage("Something went wrong. Please try again later.");
      })
    handleClosed()

  }
  useEffect(() => {

    let timeout;
    if (success) {
      timeout = setTimeout(() => {
        setSuccess(false);
      }, 5000)
    }
    return () => {
      clearTimeout(timeout);
    };

  }, [success])

  const mainUserId = userData.userId;

  const { data: product, isPending: isPendingProduct, error: errorProduct } = useFectch(`ecommerce/getUsersProfile_orderEcommerce/${userData.userId}`);

  const { data: number, isPending: ispendingNumber, error: errorNumber } = useFectch('programs/getCallNumbers')


  const filterUser = data && data.filter((data) => (
    data.userId === userData.userId
  ))

  const callNumber = number && number.filter((call) => (
    call.type == "E-commerce"
  ))


  // const filterUserProduct = product.filter((data)=>(
  //   userData.userId === data.userId

  // ))

  const changePasswordDetails = {
    oldPassword, newPassword, userId: userData.userId
  }

  const password = useRef({});
  password.current = watch("password", "");

  const handleSubmited = async () => {

    setIsPendings(true);


    await axios.put("registrations/changePassword_ebeonadiEcommerce", changePasswordDetails)

      .then((res) => {


        if (res.data.success) {
          setIsPendings(false);
          setSuccess(res.data.message);

        } else {
          setIsPendings(false);
          setErrorMessage(res.data.message);
        }

      }).catch((err) => {
        setIsPendings(false);
        console.log("err", err)
        setErrorMessage("Something went wrong, please try again");

      })
    handleClosedPass()

  }

  const closeErrorClick = (() => {
    setErrorMessage(false)
  })
  const [details, setDetails] = useState('');
  const [productID, setProductID] = useState('');


  const showDetails = ((pro) => {
    setProductID(pro.productId)
    setDetails(pro)


    setShow(true)

  })

  const symbol = Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: 'NGN'
  })
    .formatToParts(0)
    .filter(part => part.type === 'currency')
    .map(part => part.value)
    .join('')

  const checkProcessing = product && product.filter((pro) => (
    pro.statuss === "processing"
  ))

  const checkPending = checkProcessing.length === 0 || product.statuss === "Completed"



  return (

    <>

      {
        success && <div >

          <button className="btn btn-success toast-success-btn">{success}</button>

        </div>
      }
      {errorMessage && <div >
        <button className="btn btn-danger toast-success-btn">{errorMessage} <span onClick={closeErrorClick} className="remove-erroricon"><MdOutlineCancel size={25} /></span></button>

      </div>
      }



      {
        successMessage && <div >

          <button className="btn btn-success toast-success-btn">{successMessage}</button>

        </div>
      }
      {errorMess && <div >
        <button className="btn btn-danger toast-success-btn">{errorMess} <span onClick={closeErrorClick} className="remove-erroricon"><MdOutlineCancel size={25} /></span></button>

      </div>
      }

      <main className="mainbookinpage-all">
        <div>
          <ModeHeader />
          <Changer />
        </div>
        <div>
          <ProfileNav />
        </div>


        <div className="container-profilemain ">


          <div className="main-body ">




            <div className="row gutters-sm ">
              <div className="col-md-4 mb-3">
                <div className="card cardimage-profile">
                  <div className="card-body">
                    <div className="d-flex flex-column align-items-center text-center">
                      <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="Admin" className="rounded-circle" style={{ width: 150 }} />
                      {
                        filterUser.map((name) => (
                          <div className="mt-3">

                            <p className="text-secondary mb-1">{name.firstName} {userData.lastName} </p>
                            <p className="text-muted font-size-sm">{name.emailAddress} </p>

                          </div>
                        ))
                      }

                    </div>
                  </div>
                </div>
                <div className="card mt-3">
                  <ul className="list-group list-group-flush">
                    <li className="dashProfile"> <BsPersonFill /> Dashboard</li>
                    <Link to="/ecom-history" className="linkHistory-remove"><li> <BsClockHistory /> History</li></Link>
                    <li className="linkHistory-remove" onClick={handleShowProfile}> <MdPerson3 />  My profile</li>
                    <hr />
                    <li onClick={() => setLogOut(true)}> <BiLogOutCircle size={30} />  Logout</li>

                  </ul>
                </div>
              </div>
              <div className="col-md-8">
                {
                  isPending && <span className="loader-load"></span>
                }


                <div className="row gutters-sm  " id="control-productIt">
                  <div className="col-sm-12 mb-3 checkingcard  ">
                    <div className="card h-100  cardimage-profile2">
                      <div className="card-body">
                        <h6 className="d-flex align-items-center mb-3"><i className="material-icons text-info mr-2"> </i>My Orders</h6>

                        <hr />
                        {
                          isPending && <span className="loader-loads"></span>
                        }
                        {
                          checkPending ? (
                            <div className="ordersdisplay" >

                              <div className="centerprofile-pend">
                                <h2>You don't have any pending order</h2>
                                <Link to="/ecommerceupper" className="remove-linkline">  <a className="bn13 " >Go back to shopping <GiReturnArrow /></a>   </Link>

                              </div>
                            </div>

                          ) : (

                            product && product.filter((pro) => (
                              pro.statuss && pro.statuss.includes("processing") && pro.cancelStatus && pro.cancelStatus.includes("...")
                            )).map((order) => (
                              <>

                                <div className="ordersdisplay" >


                                  <div className="containe ">
                                    <div className="row w-100 ">
                                      <div className="col-md-12 col-xl-12">
                                        <div className="card shadow-0 border rounded-3 ">
                                          <div className="card-body ">

                                            {
                                              order.order_products.map((pro) => (
                                                <div className="row ">
                                                  <div className="col-md-12 col-lg-3 col-xl-3 mb-4 mb-lg-0">
                                                    <div className="bg-image hover-zoom ripple rounded ripple-surface">
                                                      <img src={pro.picture}
                                                        className="w-100" />
                                                      <a href="#!">
                                                        <div className="hover-overlay">
                                                          <div className="mask" style={{ background: "rgba(253, 253, 253, 0.15)" }} ></div>
                                                        </div>
                                                      </a>
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6 col-lg-6 col-xl-6">
                                                    <h5>{pro.productName} </h5>
                                                    <div className="d-flex flex-row">
                                                      <div className="text-danger mb-1 me-2">
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                      </div>

                                                    </div>

                                                    <p className=" mb-4 mb-md-0">
                                                      {pro.description.slice(0, 80)}...
                                                    </p>
                                                    <button classn="btn  btn-sm" type="button"><span className="proBold">Price:</span> {symbol}{pro.price} </button>
                                                    <p>
                                                      <button classn="btn  btn-sm" type="button"><span className="proBold">quantity:</span> {pro.quantity} </button>
                                                    </p>
                                                  </div>

                                                  <div className="col-md-6 col-lg-3 col-xl-3 border-sm-start-none border-start">


                                                    <div className="d-flex flex-column mt-4">

                                                      <button className="btn btn-outline-primary btn-sm mt-2 hoverBTN-profile" type="button" onClick={() => showDetails(pro)} >
                                                        Details
                                                      </button>

                                                    </div>
                                                  </div>

                                                  <hr className="hr-pro" />


                                                </div>

                                              ))
                                            }
                                            <div className="col-md-6 col-lg-3 col-xl-3 border-sm-start-none border-start">
                                              <div className="d-flex flex-column mt-4">
                                                <button classn="btn  btn-sm" type="button"><span className="proBold">Total Price:</span> {symbol}{order.totalAmount} </button>
                                                <button classn="btn btn-primary btn-sm" type="button"> <span className="proBold" >Status: </span> <span className="greenPros">{order.statuss}...</span> </button>
                                                <button classn="btn btn-primary btn-sm" type="button"> <span className="proBold" >Order code: </span> <span className="greenPros">{order.orderCode}</span> </button>

                                              </div>

                                            </div>
                                          </div>
                                          <button classn="btn  btn-sm" type="button"><span className="">Date ordered:</span> {moment(order.createdDateTime).format('lll')} </button>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                                <p></p>
                              </>
                            ))
                          )
                        }

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>


        <Footer />
      </main>

      {/* product modal */}


      {/* update modal */}

      <Modal show={showed} onHide={handleClosed}>
        <Modal.Header closeButton>
          <Modal.Title>Update profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            filterUser && filterUser.map((ecom) => (

              <Form onSubmit={handleSubmit((data) => {
                console.log("data-update",);
                handleUpdate(data)

              })} >
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    onChange={(e) => setFirstName(e.target.value)}
                    defaultValue={ecom.firstName}
                    //  value={firstName}
                    autoFocus
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Last name</Form.Label>
                  <Form.Control
                    onChange={(e) => setLastName(e.target.value)}
                    defaultValue={ecom.lastName}
                    // value={lastName}
                    autoFocus
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Phone number</Form.Label>
                  <Form.Control
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    defaultValue={ecom.phoneNumber}
                    // value={phoneNumber}
                    autoFocus
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    defaultValue={ecom.emailAddress}
                    // value={emailAddress}
                    onChange={(e) => setEmailAddress(e.target.value)}
                    autoFocus
                  />
                </Form.Group>
                <Form.Group >
                  <Form.Label className='lb'>State</Form.Label>
                  <Form.Select defaultValue={ecom.state}  {...register("state", { required: "This field is required", })} onChange={onChangeHandler} onClick={(e) => getMainLGA(e)}>
                    {/* <option>{ecom.state} </option> */}
                    {
                      states.map((state) => (
                        <option key={state.stateId} value={state.stateId}> {state.name} </option>
                      ))
                    }
                  </Form.Select>
                </Form.Group>
                <Form.Group >
                  <Form.Label >City</Form.Label>
                  <Form.Select defaultValue={ecom.city} {...register("city")} onChange={(e) => setCity(e.target.value)} >
                    {/* <option> {ecom.city} </option> */}
                    {
                      newLga && newLga.map((lga, i) => (
                        <option key={i.id} value={lga.name}> {lga.name} </option>
                      ))
                    }
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    onChange={(e) => setAddress(e.target.value)}
                    defaultValue={ecom.address}

                    autoFocus
                  />
                </Form.Group>



                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClosed}>
                    Close
                  </Button>
                  <Button variant="primary" type="submit">
                    Save Changes
                  </Button>

                </Modal.Footer>
              </Form>

            ))
          }

        </Modal.Body>
      </Modal>


      {/* Password change modal */}
      <Modal show={showedPass} onHide={handleClosedPass}>
        <Modal.Header closeButton>
          <Modal.Title>Change password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit((data, event) => {
            event.target.reset()

            handleSubmited(data)
          })}  >
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Old password <span className="star-required">*</span></Form.Label>
              <Form.Control

                placeholder="Enter a old password"
                autoFocus
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>New password  <span className="star-required">*</span></Form.Label>
              <Form.Control

                placeholder="Enter a new password"
                autoFocus
                onChange={(e) => setNewPassword(e.target.value)}
                type="password"
              // {...register("password", {
              //   required: "You must specify a password", minLength: {
              //     value: 6,
              //     message: "Password must have atlest 6 characters"
              //   }
              // })} 
              />
              {/* <span className="cum-error">{errors.password?.message}</span> */}
            </Form.Group>
            {/* <Form.Group >
              <Form.Label className='lb'>Confirm password <span className="star-required">*</span></Form.Label>
              <Form.Control placeholder=" Confirm password..." type="password" name="password_repeat" {...register("password_repeat", {
                validate: value =>
                  value === password.current || "The password does not match"
              })} />
              <span className="cum-error">{errors.password_repeat?.message}</span>
            </Form.Group> */}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">

              <Form.Control


                autoFocus

                // value={userId}
                onChange={(e) => setUserIdPass(e.target.value)}
                hidden

              />
            </Form.Group>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClosedPass}>
                Close
              </Button>
              <Button variant="primary" type="submit"
                onClick={handleSubmited}
              >
                Change password
              </Button>
            </Modal.Footer>

          </Form>
        </Modal.Body>
      </Modal>


      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>


          <div>
            <Form>
              <Form.Group className="mb-3" >

                <h4 className="modal-historyHead">{details.productName} </h4>
              </Form.Group>
              <Row className="mb-3 ">
                <Form.Group as={Col} controlId="formGridState">
                  <span className="proBold">Payment Details</span>
                  <hr />
                  <p>Amount: {details.price}</p>
                  <p>quantity: {details.quantity}</p>



                </Form.Group>
                <Form.Group as={Col} controlId="formGridState" >
                  <div>
                    <img className="pic-ModalProduct" src={details.picture} />
                  </div>
                </Form.Group>
              </Row>
              <Form.Group className="mb-3" >
                <span className="proBold">Description</span>
                <p>{details.description}  </p>
                <hr />
                <p>Delivery Method: ----</p>
                <p>Expected Delivery Date: ---------</p>
              </Form.Group>

            </Form>
            <hr />
            <div style={{ fontStyle: "italic" }}>Contact Ebeonadi helpdesk @<span style={{ color: "darkorange" }}>{callNumber.map((call) => (
              call.callNumbers
            ))} </span> for more details</div>
          </div>




        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

        </Modal.Footer>
      </Modal>


      <Modal show={showProfile} onHide={handleCloseProfile}>
        <Modal.Header closeButton>
          <Modal.Title>My profile</Modal.Title>
        </Modal.Header>


        <div className="card mb-3 ">
          {
            filterUser && filterUser.map((detail) => (
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Full Name</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">
                    {detail.firstName} {detail.lastName}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Email</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">
                    {detail.emailAddress}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Phone</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">
                    {detail.phoneNumber}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">State</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">
                    {detail.state}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">City</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">
                    {detail.city}
                  </div>
                </div>
                <hr />


                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Address</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">
                    {detail.address}
                  </div>
                </div>
                <hr />

              </div>

            ))
          }

        </div>

        <Modal.Footer>
          <div className="row">
            <div className="col-sm-6">
              <a className="btn btn-info-i " target="__blank" onClick={handleShowed} >Edit</a>
            </div>
            <div className="col-sm-6  " >
              <span className="changePassw-background" onClick={handleShowedPass}><RiLockPasswordFill /> Change password </span>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        size="sm"
        show={logOut}
        onHide={() => setLogOut(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Logout
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are You Sure You Want to logout ?</p>
          <button className="btn-remove btn btn-danger" onClick={handleLogout} >Logout</button>
        </Modal.Body>


      </Modal>



    </>
  )
})
export default MainProfile