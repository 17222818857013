
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { mainaxios, fbaxios } from "../../utility/axios"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdOutlineCancel } from "react-icons/md"

let renderCount = 0;


const CreateJobs = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    renderCount++;



    const scrolltop = (() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"

        });
    })

    const [disabled, setDisabled] = useState(false)
    const [isPendings, setIsPendings] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");
    const [success, setSuccess] = useState("");

    const handleSubmited = async (data) => {
        setDisabled(true)
        setIsPendings(true);

        await mainaxios.post("jobs/createJobs", data)

            .then((res) => {


                if (res.data.success) {
                    setIsPendings(false);
                    setSuccess("Job Created Successfully");

                    setDisabled(false)
                } else {
                    setIsPendings(false);
                    setSuccess(res.data.message);
                    setDisabled(false)
                }

            }).catch((err) => {
                setIsPendings(false);

                setErrorMessage("Something went wrong. Please try again later.");
                setDisabled(false)
            })
        // console.log("networkcall", networkcall)
        scrolltop()
    }

    useEffect(() => {

        let timeout;
        if (success) {
            timeout = setTimeout(() => {
                setSuccess(false);
            }, 5000)
        }
        return () => {
            clearTimeout(timeout);
        };

    }, [success])

    const closeErrorClick = (() => {
        setErrorMessage(false)
    })

    const notify = () => toast("Creating job...");

    return (
        <>
            <div>
                <ul className="action-bar">
                    <li>Home /</li>
                    <li>Jobs/</li>
                    <li className="createem">Create Jobs</li>
                </ul>
            </div>

            <div className="toaterter-run"> <ToastContainer /></div>
            {
                success && <div >

                    <button className="btn btn-success toast-success-btn">{success}</button>

                </div>
            }
            {errorMessage && <div >
                <button className="btn btn-danger toast-success-btn">{errorMessage} <span onClick={closeErrorClick} className="remove-erroricon"><MdOutlineCancel size={25} /></span></button>

            </div>
            }

            {isPendings &&

                <div className="lds-ellipsis">Creating please wait<div></div><div></div><div></div><div></div></div>
            }

            <form className="createnews-form" onSubmit={handleSubmit((data, event) => {
                event.target.reset()
                setDisabled(true)
                handleSubmited(data)
            })}>


                <h2 className="createnews-head">Create Jobs</h2>

                {/* {
           success && <div >{success}</div>
        }
        {errorMessage && <div className="failednotice">{errorMessage}</div>} */}
                <label>Job Type</label><br />
                <select className="newstopic-input" {...register("jobType")}>
                    <option value="Paid">Paid</option>
                    <option value="Unpaid">Unpaid</option>
                    <option value="Others">Others</option>
                </select>
                <br />  <br />
                <label className="ccpay">Paid Period</label><br />
                <label>Start Date</label><br />
                <input className="createp-input" placeholder="Start Date" type="datetime-local"  {...register("startDateTime")} /><br />
                <label className="endlabeled">End Date</label><br />
                <input className="createp-input" type="datetime-local"   {...register("endDateTime")} /> <br /> <br />
                <label>Priority</label><br />
                <select className="newstopic-input" {...register("priority")}>
                    <option value="High">High</option>
                    <option value="Normal">Normal</option>
                    <option value="Others">Others</option>
                </select>
                <br />  <br />
                <label>Job Category</label><br />
                <select className="newstopic-input" {...register("jobCategory")}>
                    <option value="House Help">House Help</option>
                    <option value="Sales Person" >Sales Person</option>
                    <option value="Blue Collar">Blue Collar</option>
                    <option value="White Collar">White Collar</option>
                    <option value="Others">Others</option>
                </select>
                <br />  <br />

                <label>Company's Name<span >*</span></label><br />
                <input className="newstopic-input" placeholder="Company Name..." {...register("companyName", { required: "This field is required" })} />
                <p className="cum-error">{errors.companyName?.message}</p>
                <label>Company's Address<span className="star-required">*</span></label><br />
                <input className="newstopic-input" placeholder="Company address..." {...register("companyAddress", { required: "This field  is required" })} /><br /><br />
                <label>Job Position<span className="star-required">*</span></label><br />
                <input className="newstopic-input" placeholder="Position..." {...register("jobPosition", { required: "This field  is required" })} />
                <p className="cum-error">{errors.companyName?.message}</p>
                <label>Email Address<span className="star-required">*</span></label><br />
                <input className="newstopic-input" placeholder="Enter valid email to recieve Cv from applicants" {...register("emailAddress", { required: "Enter valid email to recieve Cv from applicants" })} />
                <p className="cum-error">{errors.emailAddress?.message}</p>
                <label>Employer's phone number<span className="star-required">*</span></label><br />
                <input className="newstopic-input" placeholder="Enter phone number" {...register("phoneNumber", { required: "This field  is required" })} />
                <p className="cum-error">{errors.phoneNumber?.message}</p>


                <label>Application Type</label><br />
                <select className="newstopic-input" {...register("applicationType")}>
                    <option value="Radio Station">Radio Station</option>
                    <option value="In Person">In Person</option>
                    <option value="Email" >Email</option>
                    <option value="Others"  >Others</option>
                </select>
                <br />  <br />
                <label>Minimum Education Requirement</label><br />
                <select className="newstopic-input" {...register("minimumEducation")}>
                    <option value="Primary/ Fslc" >Primary/ Fslc</option>
                    <option value='Weac' >Weac</option>
                    <option value="ND"  >ND</option>
                    <option value="HND/ B.sc"  >HND/ B.sc</option>
                    <option value="Masters">Masters</option>
                    <option value="Phd" >Phd</option>
                </select>
                <br />  <br />
                <label>Qualifications</label><br />
                <input className="newstopic-input" placeholder="Qualification..."{...register("qualifications")} /><br /><br />
                <label>Start Publication Date</label><br />
                <input type="datetime-local" className="newstopic-input" placeholder="" {...register("startPublicationDateTime")} /><br /><br />
                <label>End Publication Date</label><br />
                <input type="datetime-local" className="newstopic-input" placeholder="" {...register("endPublicationDateTime")} /><br /><br />

                <label>Job Details</label><br />
                <textarea className="news-details" placeholder="Add Details" {...register("jobDetails", {
                    required: "This filed is required", minLength: {
                        value: 50,
                        message: "Must be more than 200 letters"
                    }
                })} />
                <p className="cum-error">{errors.jobDetails?.message}</p>
                {/* <label>Upload Image</label>
                <input type="file" className="uploadcreate-news" {...register("image")} /><br /><br /> */}
                <button type="submit" className="createnews-btn btn btn-primary" disabled={disabled} >Create</button>
            </form>

        </>
    )
}
export default CreateJobs