import { useState } from "react";
import { useForm } from "react-hook-form";
import { RiArrowDropDownLine } from "react-icons/ri"
import { mainaxios, fbaxios } from "../../utility/axios"
import { Alert } from "react-bootstrap";
import { useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdOutlineCancel } from "react-icons/md"

let renderCount = 0;

const AddNumber = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    renderCount++;


    const [action, setAction] = useState('')
    const [isPendings, setIsPendings] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [success, setSuccess] = useState("");

    const [disabled, setDisabled] = useState(false)

    const actionButton = () => {
        setAction(!action)


    }

    const handleSubmited = async (data) => {

        setIsPendings(true);
        setDisabled(true)


        await mainaxios.post("programs/createCallNumbers", data)

            .then((res) => {


                if (res.data.success) {
                    setIsPendings(false);
                    setSuccess(" Number Added Successfully");
                    setErrorMessage("");
                    setDisabled(false)
                } else {
                    setIsPendings(false);
                    setSuccess(res.data.message);
                    setDisabled(false)

                }

            }).catch((err) => {
                setIsPendings(false);

                setErrorMessage("Something went wrong. Please try again later.");
                setDisabled(false)
            })
        // console.log("networkcall", networkcall)

    }

    useEffect(() => {

        let timeout;
        if (success) {
            timeout = setTimeout(() => {
                setSuccess(false);
            }, 5000)
        }
        return () => {
            clearTimeout(timeout);
        };

    }, [success])

    const notify = () => toast("Creating employee...");
    const closeErrorClick = (() => {
        setErrorMessage(false)
    })


    return (
        <>
            <div>
                <ul className="action-bar">
                    <li>Home /</li>
                    <li>Edit Call-In Numbers/</li>
                    <li className="createem">Create Call-In Numbers</li>
                </ul>
            </div>
            <div className="toaterter-run"> <ToastContainer /></div>
            {
                success && <div >

                    <button className="btn btn-success toast-success-btn">{success}</button>

                </div>
            }
            {errorMessage && <div >
                <button className="btn btn-danger toast-success-btn">{errorMessage} <span onClick={closeErrorClick} className="remove-erroricon"><MdOutlineCancel size={25} /></span></button>

            </div>
            }
            {isPendings &&
                <div className="lds-ellipsis">Creating please wait<div></div><div></div><div></div><div></div></div>
            }

            <form className="createmployee-container" onSubmit={handleSubmit((data, event) => {
                event.target.reset()
                setDisabled(true)
                handleSubmited(data)

            })}  >
                <h2 className="createmployee-head">Add Call-In Number</h2>
                <label > Type <span className="star-required">*</span></label> <br />
                <select type="text" placeholder="e.g General Number..." className="contentname" {...register("type", { required: "This field is required" })} >
                 <option value="generalNumber">General number</option>
                 <option value="callInNumber">Call in number</option>
                 <option value="promgramNumber">Program number</option>
                 <option value="customerCareNumber">Customer care number</option>
                 <option value="serviceNumber">Service number</option>
                 <option value="officeNumber">Office number</option>
                 <option></option>
                </select>
               <p className="cum-error">{errors.typeNumber?.message}</p>
                <label>Call-in Number  <span className="star-required">*</span></label> <br />
                <input type="number" placeholder="09000000000000..." className="contentname" {...register("callNumbers", { required: "This filed is required" })} />
                <p className="cum-error">{errors.callNumber?.message}</p>
                <label>Email<span className="star-required">*</span></label> <br />
                <input placeholder="e.g ebeonadi@gmail.com..." className="contentname" type="email" {...register("socialMediaNumber", { required: "This filed is required" })} />
                <p className="cum-error">{errors.textNumber?.message}</p>
                <button type="submit" className="createmployee-btn btn btn-primary" disabled={disabled} >Add Number</button>

            </form>

        </>
    )
}
export default AddNumber