import { MDBBtn, MDBContainer, MDBCard, MDBCardBody, MDBCardImage, MDBRow, MDBCol, MDBIcon, MDBInput, MDBCheckbox }
  from 'mdb-react-ui-kit';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import EcommerceNav from './ecommerce_nav';
import Footer from '../layouts/footer';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { mainaxios, fbaxios } from "../../utility/axios";
import { useDispatch, useSelector } from 'react-redux';
import OtpInput from 'react-otp-input';
import ModeHeader from '../layouts/modeheader';
import EcoNAvSearch from './econavsearch';
import Changer from '../layouts/changer';



let renderCount = 0;

const ForgetPasswordPage = (() => {


  const { register, handleSubmit, formState: { errors } } = useForm();
  renderCount++;
  console.log(errors)

  const handleSubmitClick = (() => {
    handleSubmit()

  })

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  // const deadline = "December, 31, 2022";

  const [otp, setOtp] = useState('');
  const navigate = useNavigate()
  const [isPendings, setIsPendings] = useState(false);
  const [errorMessage, setErrorMessage] = useState(' ')
  const [disabled, setDisabled] = useState(true)

  const { items } = useSelector((state) => state.cart);
  const [hideToken, setHideToken] = useState(false)



  const handleSubmited = async (data) => {
    setDisabled(false);
    setIsPendings(true);

    await mainaxios.post("ecommerce/sendToken", data)

      .then((res) => {
        console.log("res passwordDetails", res)
        if (res.data.success === true) {
          const passwordDetails = res.config.data
          localStorage.setItem("passwordDetails", JSON.stringify(passwordDetails))
          setIsPendings(false);
          setDisabled(true);
          navigate("/insert_token", { state: {} })

        } else if (res.data.success === false) {
          setIsPendings(false);
          setErrorMessage(res.data.message)
        } else {
          setIsPendings(false);

        }

      }).catch((err) => {
        console.log("err", err)
        setIsPendings(false);
        setDisabled(true);
      })


  }
  const scrolltop = (() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"

    });
  })


  return (

    <>
      <main className='mainbookinpage-all' id='mainbookinpage-allId'>

        <div>
          <ModeHeader />
          <Changer />
        </div>
        <div>
          <EcoNAvSearch />
        </div>


        <MDBContainer fluid className='signupcontainer'>

          <MDBRow className='d-flex justify-content-center align-items-center h-100'>
            <MDBCol col='12'>

              <MDBCard className='bg-white my-5 mx-auto' style={{ borderRadius: '1rem', maxWidth: '500px' }}>
                <form onSubmit={handleSubmit((data, event) => {
                  event.target.reset()
                  handleSubmited(data)


                })}>
                  <MDBCardBody className='p-5 w-100 d-flex flex-column'>

                    <h2 className="fw-bold mb-2 text-center">Forgot password</h2>
                    <p className="test4reset">Enter your email to reset your password!</p>
                    <label>Email</label>
                    <MDBInput wrapperClass='mb-4 w-100' id='formControlLg' type='email' size="lg"  {...register("emailAddress", { required: "This field is required" })} />
                    <span className="cum-error">{errors.emailAddress?.message}</span>

                    <button size='lg' className='login-btn-login w-100 btnlogin ' type='submit'
                      onClick={disabled}
                    >
                      {!isPendings ? (
                        <span> Forgot password </span>
                      ) : (
                        <span className="loader-load"></span>
                      )
                      }
                    </button>

                    {errorMessage && <div className="errlogin">{errorMessage}</div>}


                  </MDBCardBody>

                </form>
              </MDBCard>

            </MDBCol>
          </MDBRow>

        </MDBContainer>







        <div>
          <Footer />
        </div>
      </main>

    </>
  )
})
export default ForgetPasswordPage