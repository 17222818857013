import {AiFillFacebook} from "react-icons/ai";
import {AiFillInstagram} from "react-icons/ai";
import {AiOutlineTwitter}from "react-icons/ai";
import {FaTiktok} from "react-icons/fa";
import {AiFillYoutube} from "react-icons/ai";
import {CiLocationOn} from "react-icons/ci"
import {BsTelephoneMinusFill} from 'react-icons/bs'
import {AiOutlineMail} from "react-icons/ai"
import { Link, NavLink } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import {BsBoxArrowUp} from "react-icons/bs"
import useFectch from "../adminpage/createbackend";




const Footer = ()=>{

    const {data:call, isPending, error} = useFectch('/programs/getCallNumbers')

    const scrolltop = (()=>{
        window.scrollTo({
            top:0,
            behavior: "smooth"

        });
    })

const customerCareLine = call && call.find((data)=>
     data.type === "customerCareNumber"

    )

// console.log("customercare", customerCareLine)



    return(

          <>
          
          <Card className="footergeneral-container">
      <Card.Header></Card.Header>
      <Card.Body className="footer-boot">
      <div className="firstfootermain-div">
            <div className="footer-location-div">
                <div className="residency-footert"><CiLocationOn size={25} className="footersicon"/> Residency Hotel, Ogidi, Anambra State, Nigeria</div>
                <p className="residency-footert"> <BsTelephoneMinusFill size={20} className="footersicon" /> {customerCareLine?.callNumbers
                } </p>
                <p className="residency-footert" ><AiOutlineMail size={20} className="footersicon"/> {customerCareLine?.socialMediaNumbers}</p>

            </div>
            <div className="footer-text-part">
                <h2 className="ebenamefooter">Ebeonadi Radio Tv</h2>
                <div className="footertext-pdiv">
                    <div>
                        <span className="footerlist-head">Our Services</span> <br/>
                        <span className="footertext-fade">Advert Placement</span> <br/>
                        <span  className="footertext-fade">News Broadcasting</span> <br/>
                        <span  className="footertext-fade">Public Awareness</span> <br/>
                        <span className="footertext-fade"> Job Services</span> <br/>
                    </div>
                    <div>
                        <span className="footerlist-head">Entertainment</span> <br/>
                        <span  className="footertext-fade">Adaogidi Pageant</span> <br/>
                        <span  className="footertext-fade">Anambra Culture</span> <br/>
                        <span  className="footertext-fade">Local foods</span> <br/>
                        <span  className="footertext-fade">Music Fiesta</span> <br/>
                    </div>
                    <div>
                        <span className="footerlist-head">News</span> <br/>
                        <span  className="footertext-fade">Local News</span> <br/>
                        <span  className="footertext-fade">entertainment News</span> <br/>
                        <span  className="footertext-fade">Politics</span> <br/>
                        <span  className="footertext-fade">Cultural News</span> <br/>
                        <hr className="linemobile-footer"/>
                    <div className="footericon-main-mobile">

                    <div className="circlediv"><i className="footer-instagram"> <AiFillInstagram size={20}  /> </i></div>
                    <div  className="circlediv"> <i className="footer-twitter"> <AiOutlineTwitter size={20} /></i></div>
                    <div className="circlediv" > <i className="footer-tiktok"><FaTiktok size={20} /> </i></div>
                    <div className="circlediv"> <a href="https://www.youtube.com/results?search_query=ebeonadi" > <i className="footer-youtube"> <AiFillYoutube size={20} /></i> </a></div>
                    <div className="circlediv" > <a href="https://www.facebook.com/ebeonadi103.5FM?mibextid=ZbWKwL"> <i className="footer-facebook"> <AiFillFacebook size={20} /> </i> </a>  </div>
                 </div>

                    </div>
                </div>

                {/* <p className="footertext-text">The Only Resident Radio Station On 24hr transmission in Southeast Nigeria. Started in 
                2014, Ebeonadi combines culture with entertainment. Proudly Nigerian and Proudly Igbo, we don’t use foreign accents on our 
                station. We are true to our heritage and culture.</p> */}
               
            </div>
            <div>
                <button className="footer-listenlive " onClick={scrolltop} ><BsBoxArrowUp  size={30} className="top-scroll" /></button>
            </div>
          
            </div>
            <hr className="linedown"/>
            <div className="secondfootermain-div">
                <div className="footericon-main">

                    <div className="circlediv">  <i className="footer-instagram"> <AiFillInstagram size={20}  /> </i></div>
                    <div  className="circlediv"> <i className="footer-twitter"> <AiOutlineTwitter size={20} /></i></div>
                    <div className="circlediv" > <i className="footer-tiktok"><FaTiktok size={20} /> </i></div>
                    <div className="circlediv"> <a href="https://www.youtube.com/results?search_query=ebeonadi" > <i className="footer-youtube"> <AiFillYoutube size={20} /></i> </a></div>
                    <div className="circlediv" > <a href="https://www.facebook.com/ebeonadi103.5FM?mibextid=ZbWKwL"> <i className="footer-facebook"> <AiFillFacebook size={20} /></i></a>  </div>
                 </div>                             
                <ul  className="footer-schedules">
                    <NavLink to="/schedule" className="footernav-sch"><li className="shortcut-footer">Programs</li></NavLink>
                   <NavLink  to="/jobs" className="footernav-sch" > <li className="shortcut-footer"  >  Jobs</li></NavLink>
                   <NavLink  to="/contactUs"  className="footernav-sch" ><li className="shortcut-footer"   >Contact Us</li></NavLink>
                   <li className="shortcut-footer" onClick={scrolltop} >On Air</li>
                </ul>

            </div>
        <p className="last-text">©2023 Ebeonadi Radio Tv. Powered by Ontrac Tech</p> 
      </Card.Body>
    </Card>
        
          
        {/* <div className=" footer-cont">
           
        </div> */}

        </>
    )
}
export default Footer