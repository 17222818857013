
import { useState, Fragment, useEffect } from "react";
// import data from "../adminpage/advertdata.json"

import { Alert, Table } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import AdvertRAte from "../directpages/Advertrate";
import useFectch from "./createbackend";
import { mainaxios, fbaxios } from "../../utility/axios";
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdOutlineCancel } from "react-icons/md"




const ViewAdvertRate = () => {

  const [getAllAdvert, setGetAllAdvert] = useState("");

  const { data, error, isPending } = useFectch('adverts/getAdvertRates')

  const update = () => {

    mainaxios.get("adverts/getAdvertRates")
      .then((res) => {
        setGetAllAdvert(res.data);
      });
  };
  useEffect(update, [getAllAdvert]);

// console.log("getAllAdvert",getAllAdvert);

  const notify = () => toast("Creating advert Rate...");

  const [lgShow, setLgShow] = useState(false);
  const [advertRate, setAdvertRate] = useState('');
  const [inAdvert, setInAdvert] = useState('')

  const [timeZone, setTimeZone] = useState('');
  const [first, setFirst] = useState('');
  const [second, setSecond] = useState('');
  const [third, setThird] = useState('');
  const [fourth, setFourth] = useState('');
  const [fifth, setFifth] = useState('');
  const [sixth, setSixth] = useState('');
  const [seventh, setSeventh] = useState('');
  const [eighth, setEight] = useState('');

  const application = {
    timeZone, first, second, third, fourth, fifth, sixth, seventh, eighth
  }

  const handleAddFormSubmit = async (event) => {
    event.preventDefault();
    setIsPendings(true);

    await mainaxios.post("adverts/createAdvertRates", application)

      .then((res) => {

        if (res) {
          setIsPendings(false);
          setSuccessMessage("AdertRate Added Successfully");
          //  window.location.reload();
        } else {

          setSuccessMessage("AdertRate Added Successfully");
          setIsPendings(false);
        }

      }).catch((err) => {

        setIsPendings(false);
      })

    handleAddFormSubmit()

  };


  const popover = (
    <Popover id="popover-basic" className="wide-advert">
      <form onSubmit={handleAddFormSubmit}>

        <input
          type="text"
          name="timeZone"
          placeholder="Time Zone.."
          className="listinput"
          onChange={(e) => setTimeZone(e.target.value)}
          required
        />

        <input

          type="text"
          name="first"

          placeholder="5am-6am"
          className="listinput"
          onChange={(e) => setFirst(e.target.value)}
          required
        />
        <input
          type="text"
          name="second"
          placeholder="6am-10am.."
          className="listinput"
          onChange={(e) => setSecond(e.target.value)}
          required
        />
        <input
          type="text"
          name="third"
          placeholder="10am-1pm..."
          className="listinput"
          onChange={(e) => setThird(e.target.value)}
          required
        />
        <input
          type="text"
          name="fourth"
          placeholder="1pm-4pm"
          className="listinput"
          onChange={(e) => setFourth(e.target.value)}
          required
        />
        <input
          type="text"
          name="fifth"
          placeholder="4pm-7pm"
          className="listinput"
          onChange={(e) => setFifth(e.target.value)}
          required
        />
        <input
          type="text"
          name="sixth"
          placeholder="7pm-11pm..."
          className="listinput"
          onChange={(e) => setSixth(e.target.value)}
          required
        />
        <input
          type="text"
          name="seventh"
          placeholder="11pm-1am"
          className="listinput"
          onChange={(e) => setSeventh(e.target.value)}
          required
        />
        <input
          type="text"
          name="eighth"
          placeholder="1am-5am"
          className="listinput"
          onChange={(e) => setEight(e.target.value)}
          required
        />

        <button className="btn btn-primary" type="submit" onClick={notify} >Add</button>
      </form>
    </Popover>
  );


  //////////////////////////////delete////////////
  const [errorMess, setErrorMess] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [employeeData, setEmployeeData] = useState()
  const [advertRatesId, setAdvertRatesId] = useState("")
  const [isPendings, setIsPendings] = useState(false);


  const handleSubmitDelete = async (event) => {
    event.preventDefault();

    mainaxios.put(`adverts/removeAdvertRates/${advertRatesId}`)
      .then(response => {


        if (response) {
          setSuccessMessage("AdertRate Removed Successfully");
          
        } else {

          setErrorMess(data.message);
        }
      })
      .catch(error => {

      });
    setLgShow(false)

  }

  const toggleDelete = (advert) => {

    setAdvertRatesId(advert.advertRatesId)
    setLgShow(true)
  }

  useEffect(() => {

    let timeout;
    if (successMessage) {
      timeout = setTimeout(() => {
        setSuccessMessage(false);
      }, 5000)
    }
    return () => {
      clearTimeout(timeout);
    };

  }, [successMessage])


  const closeErrorClick = (() => {
    setErrorMess(false)
  })



  return (
    <>



      <div>


        <ul className="action-bar">
          <li>Home /</li>
          <li>Advert Rate/</li>
          <li className="createem">View Advert Rate</li>
        </ul>
      </div>

      <div className="viewad-head">

        <OverlayTrigger trigger="click" placement="right" overlay={popover}>
          <Button variant="success" className="add-advertbutton">Add Advert Rate</Button>
        </OverlayTrigger>



      </div>
      <div className="toaterter-run"> <ToastContainer /></div>
      {
        successMessage && <div >

          <button className="btn btn-success toast-success-btn">{successMessage}</button>

        </div>
      }
      {errorMess && <div >
        <button className="btn btn-danger toast-success-btn">{errorMess} <span onClick={closeErrorClick} className="remove-erroricon"><MdOutlineCancel size={25} /></span></button>
      </div>
      }
      {isPendings &&
        <div className="lds-ellipsis">Creating please wait<div></div><div></div><div></div><div></div></div>
      }



      <form >


        <Table striped bordered hover size="sm" className='adaogidi-table'>
          <thead>
            <tr className="tbview-head">
              <th>Time Zone</th>
              <th >5am-6am</th>
              <th>6am-10am</th>
              <th>10am-1pm</th>
              <th>1pm-4pm</th>
              <th>4pm-7pm</th>
              <th>7pm-11am</th>
              <th>11pm-1am</th>
              <th>1am-5am</th>
              <th>Action</th>

            </tr>
          </thead>


          <tbody>
            {isPending && <div className="loaderless"> <div className="lds-ripple"> Loading... <div></div><div></div></div>

            </div>}
            {
              getAllAdvert && getAllAdvert.map((advert) => (


                <tr key={advert.advertRateId}>

                  <td>{advert.timeZones}</td>
                  <td>{advert.first}</td>
                  <td>{advert.second}</td>
                  <td>{advert.third}</td>
                  <td> {advert.fourth} </td>
                  <td> {advert.fifth} </td>
                  <td> {advert.sixth} </td>
                  <td> {advert.seventh} </td>
                  <td> {advert.eighth} </td>






                  {/* onClick={()=>sendDetails(employee)} */}
                  <Button variant="primary" disabled className=" ">Update</Button>{' '}
                  <Button variant="primary" className=" deleteshift" onClick={() => toggleDelete(advert)} >Delete</Button>{' '}

                </tr>

              ))
            }





            {/* <Fragment>
  {editAdvertId === advert.id ? (<EditableRoll 
  editFormData={editFormData} handleEditFormChange={handleEditFormChange}
  handleCancelClick={handleCancelClick} 
  />) :( <RollCall advert={advert} handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
  
  /> )  }
  
  
  

</Fragment> */}





          </tbody>
        </Table>


      </form>

      <form onSubmit={handleSubmitDelete}  >
        <Modal
          size="lg"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg"  >
              Remove
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Comfirm Delete</p>
            <button className="btn-remove btn btn-danger" type="submit" onClick={handleSubmitDelete} >Remove</button>
          </Modal.Body>



        </Modal>
      </form>


    </>
  )
}
export default ViewAdvertRate