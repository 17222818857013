import { useEffect, useState } from "react";
import { Button, Col, Dropdown, Form, Modal, Pagination, Row, Table } from "react-bootstrap"
import { RiDeleteBin6Line } from "react-icons/ri"
// import useFectch from "../createbackend";
import moment from "moment";
import { BsFillTrash3Fill } from "react-icons/bs";
import { MdOutlineCancel, MdOutlineDeleteForever } from "react-icons/md";
import { mainaxios, fbaxios } from "../../../utility/axios"
import 'bootstrap/dist/css/bootstrap.css';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { HiOutlineSearch } from "react-icons/hi";
import { useRef } from 'react';
import { useId } from 'react';



const ViewOrder = (() => {



  const [productCall, setProductCall] = useState('')
  const [completeOrder, setCompleteOrder] = useState("");
  const [cancelOrder, setCancelOrder] = useState(false);
  const [details, setDetails] = useState([])
  const [show, setShow] = useState(false);
  const [orderId, setOrderId] = useState({})
  const handleClose = () => setShow(false);
  const [orderMessage, setOrderMessage] = useState("")
  const [color, setColor] = useState("")
  const [payConfirm, setPayConfirm] = useState("")
  //  const { data: product, isPending, error: errorProduct } = useFectch('ecommerce/getAllOrders_ebeonadiEcommerce');
  const [selectedOption, setSelectedOption] = useState(false);
  const [productLoad, setProductLoad] = useState([])

  const [data, setData] = useState([]);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);



  useEffect(() => {
    setIsPending(true)
    mainaxios.get("ecommerce/getAllOrders_ebeonadiEcommerce")
      .then(response => {

        if (!response.statusText === "OK") {
          throw Error("Could not fetch data")
        }
        setIsPending(false)
        // setData(response.data)
        setProductLoad(response.data);
        setError(null)

      })
      .catch(error => {
        setError(error.message)
        setIsPending(false)
        // console.log(error)
      })
  }, []);

  const symbol = Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: 'NGN'
  })
    .formatToParts(0)
    .filter(part => part.type === 'currency')
    .map(part => part.value)
    .join('')




  const update = () => {

    mainaxios.get("ecommerce/getAllOrders_ebeonadiEcommerce")
      .then((res) => {
        setProductLoad(res.data);
      });
  };
  useEffect(update, [productLoad]);



  

  const closeErrorClick = (() => {
    setErrorMessage(false)
  })




  const handleOptionChange = () => {
    setSelectedOption(selectedOption);
  };
  // const [oderCoplete, setOrderComplete] = useState('')


  // let render = useRef("")

  const handleCancelOrder = ((product) => {
    setOrderId(product.orderId)
    setCancelOrder(true)
    update()
    setCancelOrder("Canceled")

  })
  const handleConPayment = ((product) => {
    setOrderId(product.orderId)
    setLgShow(true)
    update()
    setPayConfirm("Paid")
    // render.current = product
  })
  const handleCompleteOrder = ((product) => {
    setOrderId(product.orderId)
    setCompleteOrder(true)
    update()
    setOrderMessage("Completed")
    // render.current = product
  })


  const handleDetails = ((product) => {
    setDetails(product.order_products)
    setShow(true)
  })
  const [lgShow, setLgShow] = useState(false);

  // console.log("all order>>>", product);
  // console.log("cancel order>>>", cancelOrder);
  const [isPendings, setIsPendings] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMess, setErrorMess] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [search, setSearch] = useState("")


  // const inputRef = useRef('');

  const scrolltop = (() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"

    });
  })



  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPage = 10;
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = productLoad.slice(indexOfFirstOrder, indexOfLastOrder);


  // complete order
  const handleUpdate = async () => {
    // setIsPendings(true);
    const complete = {
      status: orderMessage
    }
    await mainaxios.put(`ecommerce/confirmOrder_orderEcommerce/${orderId}`, complete)
      .then((res) => {
        // console.log("res", res)

        if (res.data.success) {
          setIsPendings(false);
          setSuccessMessage("Order completed succesfully");
          setErrorMess("");

        } else {
          setIsPendings(false);
          setErrorMess(res.data.message);

        }
      }).catch((err) => {
        setIsPendings(false);
        // console.log("err", err)
        setErrorMessage("Something went wrong. Please try again later.");
      })

    // scrolltop()
    setCompleteOrder(false)
  }

  // submit payment
  const handleConfirmPayment = async () => {
    // setIsPendings(true);
    const confirm = {
      paymentStatus: payConfirm
    }
    await mainaxios.put(`ecommerce/confirmPaymentStatus/${orderId}`, confirm)
      .then((res) => {
        // console.log("res", res)

        if (res.data.success) {
          setIsPendings(false);
          setSuccessMessage("Payment confirmed");
          setErrorMess("");

        } else {
          setIsPendings(false);
          setErrorMess(res.data.message);

        }
      }).catch((err) => {
        setIsPendings(false);
        // console.log("err", err)
        setErrorMessage("Something went wrong. Please try again later.");
      })

    // scrolltop()
    setLgShow(false)
  }

  // Cancel order
  const handleCancelOrderUpdate = async () => {
    // setIsPendings(true);
    const canceled = {
      cancelOrder: cancelOrder
    }
    await mainaxios.put(`ecommerce/cancelOrder/${orderId}`, canceled)
      .then((res) => {
        // console.log("res", res)

        if (res.data.success) {
          setIsPendings(false);
          setSuccessMessage("Order canceled successfully");
          setErrorMess("");

        } else {
          setIsPendings(false);
          setErrorMess(res.data.message);

        }
      }).catch((err) => {
        setIsPendings(false);
        console.log("err", err)
        setErrorMessage("Something went wrong. Please try again later.");
      })

    // scrolltop()
    setCancelOrder(false)
  }

  useEffect(() => {

    let timeout;
    if (successMessage) {
      timeout = setTimeout(() => {
        setSuccessMessage(false);
      }, 5000)
    }
    return () => {
      clearTimeout(timeout);
      setProductCall(productCall)
    };

  }, [successMessage])




  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [colorTest, setColorTest] = useState("")
  const [dropdown, setDropdown] = useState(false);
  const toggleOpen = () => setDropdown(!dropdown);

  const handleSearchDisplay = ((event) => {
    setSearch(event.target.value)
  })

  const currentOrderDisplay = productLoad && productLoad.filter((order) => (
    order.phoneNumber && order.phoneNumber.toLowerCase().includes(search) || order.firstName && order.firstName.toLowerCase().includes(search)

  ))


  // const use = useId()
  // console.log("use>>>",use);
  const searchBtn = (() => {
    if (currentOrderDisplay) {
      return currentOrderDisplay
    }
  })

  const checkColor = (description) => {
    switch (description) {
      case "Completed":
        return "green";
      case "processing":
        return "blue";
      default:
    }
  };
  const checkColorPayment = (description) => {
    switch (description) {
      case "Paid":
        return "green";
      case "not paid":
        return "red";
      default:
    }
  };

  //     const formatNum = currentOrderDisplay && currentOrderDisplay.map((num)=>(
  //       num.totalAmount

  // ))

  // const num = formatNum;  
  // const options = {  maximumFractionDigits: 2   }   
  // const formattedNumber = Intl.NumberFormat("en-US",options).format(num); 

// console.log(" currentOrderDisplay", currentOrderDisplay);


  return (

    <>

      {errorMessage && <div >
        <button className="btn btn-danger toast-success-btn">{errorMessage} <span onClick={closeErrorClick} className="remove-erroricon"><MdOutlineCancel size={25} /></span></button>

      </div>
      }
      {isPendings &&
        <div className="lds-ellipsis" id="lds-ellipsis">Loading...<div></div><div></div><div></div><div></div></div>
      }



      {errorMess && <div >
        <button className="btn btn-danger toast-success-btn">{errorMess} <span onClick={closeErrorClick} className="remove-erroricon"><MdOutlineCancel size={25} /></span></button>

      </div>
      }

      <div>
        <ul className="action-bar">
          <li>Home /</li>
          <li>E-commerce /</li>
          <li className="createem">View orders</li>
        </ul>
      </div>
      <h2 className="viewemployee-head">List of Orders Recieved</h2>
      <div>
        {/* <Table striped bordered hover size="sm" > */}



        <button className="deleteMarked"> <BsFillTrash3Fill /> Delete marked</button>
        {
          successMessage && <div >

            <div className="col-sm-12 displayOrder_alert">
              <div className="alert fade alert-simple alert-success alert-dismissible text-left  font__size-16  show">
                <button type="button" className="close font__size-18" data-dismiss="alert" onClick={closeErrorClick}>
                  <span aria-hidden="true"><a>
                    <i className="fa fa-times greencross"></i>
                  </a></span>
                  <span className="sr-only">Close</span>
                </button>
                <i className="start-icon far fa-check-circle faa-tada animated"></i>
                <strong className="font__weight-semibold"> {successMessage} </strong>
              </div>
            </div>

          </div>
        }

        <div className="search_box">
          <div className="search">
            <div className="select_area">
              <i className="fas fa-map-marker-alt map_icon"></i>
              <div className="text">Search</div>
            </div>
            <div className="line"></div>
            <div className="text_and-icon">
              <input type="text" className="search_text" id="search_text" placeholder="Search by customer name or phone number ..." onChange={handleSearchDisplay} />
              <i className=" search_icon" onClick={searchBtn}><HiOutlineSearch />  </i>
            </div>
          </div>
        </div>

        <Table striped bordered hover size="sm" className='adaogidi-table' id="order-Table">

          <thead>
            <tr>

              <th>S/N</th>

              <th>Cus. First Name</th>
              <th> Cus. Phone</th>
              <th>Cus. Email</th>
              <th>Pick Up Address</th>
              <th> Status </th>
              <th>City</th>
              <th>Total Price</th>

              <th>Payment status</th>
              {/* <th>Cancel status</th> */}
              <th>Order date/time</th>
              <th>Date completed</th>


              <th>Action</th>

            </tr>
          </thead>
          <tbody>
            {
            isPending && <div className="loaderless"> <div className="lds-ripple"> Loading... <div></div><div></div></div>

            </div>
            }
            {
              currentOrderDisplay && currentOrderDisplay.filter((cancel) => (
                cancel.cancelStatus && cancel.cancelStatus.includes("...")

              )).map((product, i) => (
                <tr key={product.productId}>
                  {/* <th scope="row">
      <div className="form-check">
        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
      </div>
    </th> */}
                  <td>{i + 1} </td>
                  <td> {product.firstName} </td>
                  <td>  {product.phoneNumber}</td>

                  <td> {product.emailAddress} </td>
                  <td>{product.deliveryAddress} </td>
                  <td className="textColor" style={{ color: `${checkColor(product.statuss)}` }}  > {product.statuss}</td>
                  <td>{product.localGovernment} </td>


                  <td> {symbol}{product.totalAmount} </td>
                  {/* <td>{product.paymentOption} </td> */}
                  <td style={{ color: `${checkColorPayment(product.paymentStatus)}` }} >{product.paymentStatus} </td>
                  {/* <td>{product.cancelStatus}</td> */}

                  <td>{moment(product.createdDateTime).format('lll')}</td>
                  <td> {moment(product.updatedDateTime).format('lll')} </td>
                  {/*        
        <Dropdown className="d-inline mx-2 firstline-drop " autoClose="outside"  >
        <Dropdown className="  firstline-drop " autoClose="outside"/>
        <Dropdown.Menu className="dropdownshow-d" style={{position:"absolute"}}>
          <Dropdown.Item className="sideside" onClick={()=>handleConPayment(product)} >Confirm payment</Dropdown.Item>
          <Dropdown.Item  className="sideside" onClick={() => handleCompleteOrder(product)}>Complete order</Dropdown.Item>
          <Dropdown.Item  className="sideside" onClick={() => handleCancelOrder(product)}  >Cancel order</Dropdown.Item>
          <Dropdown.Item  className="sideside" onClick={() =>handleDetails(product) } >Order details</Dropdown.Item>
        
        </Dropdown.Menu>
       

      </Dropdown> 
       */}

                  <Dropdown className="  firstline-drop " autoClose="outside" >
                    {/* <Dropdown.Toggle variant="primary" id="dropdown-autoclose-outside" style={{ zIndex: "1" }} >
                    Dropdown
                   
                  </Dropdown.Toggle> */}

                    <Dropdown.Toggle split id="dropdown-split-basic" aria-label="Action" variant="secondary" />

                    <Dropdown.Menu style={{ zIndex: "100", position: "absolute" }} >
                      <Dropdown.Item href="#/action-1" className="shiftPick" onClick={() => handleConPayment(product)}>Confirm payment</Dropdown.Item>
                      <Dropdown.Item href="#/action-2" className="shiftPick" onClick={() => handleCompleteOrder(product)}>Complete order</Dropdown.Item>
                      <Dropdown.Item href="#/action-1" className="shiftPick" onClick={() => handleCancelOrder(product)} >Cancel order</Dropdown.Item>
                      <Dropdown.Item href="#/action-2" className="shiftPick" onClick={() => handleDetails(product)}>Order details</Dropdown.Item>

                    </Dropdown.Menu>
                  </Dropdown>



                </tr>


              ))

            }


          </tbody>
          <div className="pagination-Content">
            <Pagination >
              {Array.from({ length: Math.ceil(productLoad.length / ordersPerPage) }).map((_, index) => (
                <Pagination.Item
                  key={index + 1}
                  active={index + 1 === currentPage}
                  onClick={() => paginate(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          </div>

        </Table>




        <form   >
          <Modal
            size="sm"
            show={lgShow}
            onHide={() => setLgShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg"  >
                Confirm payment
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="Center_bodyRed">
              <p>Are You Sure You Want to Confirm the payment?</p>
              <button className="btn-remove btn btn-danger" type="submit" onClick={handleConfirmPayment}   >Comfirm payment</button>
              {/* <button style={{marginLeft:"20%"}} className="btn btn-secondary"  type="submit"  onClick={()=>setLgShow(false)} > No</button>   */}
            </Modal.Body>



          </Modal>
        </form>

        <form onSubmit={handleUpdate}  >
          <Modal
            size="sm"
            show={completeOrder}
            onHide={() => setCompleteOrder(false)}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg"  >
                Confirm Completed Order
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Is the order completed?</p>
              <button className="btn-remove btn btn-danger" type="submit" onClick={handleUpdate} > Complete order</button>

            </Modal.Body>



          </Modal>
        </form>


        <form   >
          <Modal
            size="sm"
            show={cancelOrder}
            onHide={() => setCancelOrder(false)}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg"  >
                Cancel order
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are You Sure You Want to Cancel the order?</p>
              <button className="btn-remove btn btn-danger" type="submit" onClick={handleCancelOrderUpdate}   >Cancel order</button>
              {/* <button style={{marginLeft:"20%"}} className="btn btn-secondary"  type="submit"  onClick={()=>setCancelOrder(false)} > No</button>   */}
            </Modal.Body>



          </Modal>
        </form>


        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Ordered history list</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              details.map((product) => (
                <div>
                  <Form>
                    <Form.Group className="mb-3" >

                      <h4 className="modal-historyHead">{product.productName} </h4>
                    </Form.Group>
                    <Row className="mb-3 ">
                      <Form.Group as={Col} controlId="formGridState">

                        <p> <span className="proBold"> Price:</span> {product.price} </p>
                        <p> <span className="proBold"> Quantity:</span> {product.quantity} </p>
                      </Form.Group>
                      <Form.Group as={Col} controlId="formGridState" >
                        <div>
                          <img className="pic-ModalProduct" src={product.picture} />
                        </div>
                      </Form.Group>
                    </Row>
                    <Form.Group className="mb-3" >
                      <Form.Label><span className="proBold"> Details </span></Form.Label>
                      <div>
                        {product.description}
                      </div>
                    </Form.Group>

                  </Form>
                  <hr />
                </div>

              ))
            }

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>

          </Modal.Footer>
        </Modal>

      </div>
      

    </>
  )
})
export default ViewOrder