import { RiCalendarEventLine, RiDirectionFill } from "react-icons/ri";
import Table from "react-bootstrap/Table";
import Footer from "../layouts/footer";
import Header from "../layouts/header";

import { useState } from "react";
import DarkExample from "./updateadmin/darktable";
import DarkExamples from "./updateadmin/daytable";
import useFectch from "./createbackend";
import ViewJobber from "./viewjobs";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AdminFooter from "./adminfooter";
import { SlPeople } from "react-icons/si"
import { BsPeopleFill } from "react-icons/bs";
import { MdGames } from "react-icons/md";
import { GiOfficeChair } from "react-icons/gi";
import { set } from "react-hook-form";
import ModifiedNav from "../layouts/modifiednav";
import AdminDashboard from "./admindashboard";

function BasicExample() {
  const [firstCard, setFirstCard] = useState("");
  const [showPage, setShowPage] = useState(false);
  // const [errorMessage, setErrorMessage] = useState(false)
  const [success, setSuccess] = useState(false)

  const Navigate = useNavigate();

  const userData = JSON.parse(localStorage.getItem("adminData"));

  const firstCards = (alls) => {
    setFirstCard(!firstCard);


  };

  const loggedInCheck = (() => {
    if (userData === 1) {
      return <AdminDashboard />
    }
  })

  useEffect(() => {
    const checkLogin = () => {
      if (userData.statuss === "active") {
        setSuccess("Welcome! You successfully logged in")
        setShowPage(true);

        loggedInCheck()
      } if (userData.emailAddress === "admin@gmail.com") {
        return <ModifiedNav />
      } else {
        setShowPage(false);

      }
    };
    checkLogin();
  }, []);

  useEffect(() => {

    let timeout;
    if (success) {
      timeout = setTimeout(() => {
        setSuccess(false);
      }, 5000)
    }
    return () => {
      clearTimeout(timeout);
    };

  }, [success])

  const { data, isPending, error } = useFectch("programs/getPrograms");

  const { data: employees, isPending: isLoadingEmployees } = useFectch("registrations/getUsers");

     const totalEmployee = employees.length;

  const { data: advert, isPending: isLoadingAdvert } = useFectch("adverts/getOnlineAds");
  const totalAdverts = advert.length;

  const { data: jobs, isPending: isLoadingJobs } = useFectch("jobs/getJobs");
       const totalJobs = jobs.length;


  const { data: programs, isPending: isLaodingPrograms } = useFectch("programs/getPrograms");
        const toltalPrograms = programs.length;


  const [showJob, setShowJob] = useState("");
  const [showEmployee, setShowEmployee] = useState("");
  const [showAdvert, setShowAdvert] = useState("");
  const [showPrograms, setShowPrograms] = useState("");

  const handleShowJob = () => {
    setShowJob(!showJob);
    displayShow();
  };

  const displayShow = () => {
    if (showJob === "job") {
      return
      // <ViewJobber/>
    }
  };
  {
    displayShow();
  }

  return (
    <>
      {/* {
        success && <div >

          <button className="btn btn-success toast-success-btn"> Welcome! {userData.firstName} </button>

        </div>
      } */}
     
      <div id="adminpage-allcontainer">
     
        <div className="admin-cards-div" >

          <div className="cardstyle" id="wrap">
            <div className="card-head"> Adverts  <MdGames /></div>
            <p className="card-number"> {isLoadingAdvert ? <span className="loader-load" id="adminControlTotal"></span>: totalAdverts } </p>
            <p>Total Number of Adverts</p>
           </div>

          <div className="cardstyle2" id="wrap">
            <div className="card-head2">Employees <BsPeopleFill /> </div>

            <p className="card-number">  <p className="card-number"> {isLoadingEmployees ? <span className="loader-load" id="adminControlTotal"></span>: totalEmployee } </p>   </p>
            <p>Total Number of Employees</p>
          </div>

          <div className="cardstyle1" id="wrap">
            <div className="card-head2"> Programs    <RiCalendarEventLine /></div>
            <p className="card-number">{isLaodingPrograms ? <span className="loader-load" id="adminControlTotal"></span>: toltalPrograms  }</p>
            <p>Total Number of Programs</p>
          </div>

          <div className="cardstyle3  " id="wrap">
            <div className="card-head2"> Jobs  <GiOfficeChair /></div>
            <p className="card-number">{isLoadingJobs ? <span className="loader-load" id="adminControlTotal"></span>: totalJobs  }</p>
            <p>Total Number of Jobs</p>
          </div>

        </div>
        {
          isPending &&
          <div className="allprograms-card-skeleton">
            <div>
              <h3 className="card-head"></h3>

              <div>
                <p>

                </p>
              </div>

            </div>
          </div>
        }




        <div>
          <h3 className="card-head">All Programs</h3>
          {isPending && <div className="loaderless"> <div className="lds-ripple"> Loading... <div></div><div></div></div>

          </div>}


          <div >
            

            <table class="table-fill">
              <thead>
                <tr className="tr-table">
                  <th className="th-table">S/N</th>
                  <th className="th-table">Program Name</th>
                  <th className="th-table">Program Days</th>
                </tr>
              </thead>
              <tbody class="table-hover">
                {data && data.map((data, i) => (
                  <tr className="tr-table">
                    <td className="td-table">{i + 1}</td>
                    <td className="td-table" >{data.programName}</td>
                    <td className="td-table" >{data.program_days.map((program) => (
                      program.day
                    )).join(" - ")}</td>
                  </tr>
                ))}
              </tbody>
            </table>





          </div>

        </div>



        <div className="admintable-div">
          <table className="prog-table">
            <h2 className="card-head">CURRENT PROGRAM</h2>
            <DarkExample />
          </table>

          <table className="prog-table2">
            <h2 className="card-head">TODAY PROGRAMS</h2>
            <DarkExamples />
          </table>
        </div>
      </div>

      <AdminFooter />

    </>
  );
}

export default BasicExample;
