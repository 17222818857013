import { createContext, useEffect, useState } from "react";
import { Dropdown, Table } from "react-bootstrap"
import { fbaxios, mainaxios } from "../../../utility/axios";
import { VscDiffAdded } from "react-icons/vsc";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
// import Dropdown from 'react-bootstrap/Dropdown'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import CreateProduct from "./create-product";
import { Link, useNavigate } from "react-router-dom";
import { HiOutlineSearch } from "react-icons/hi";
import { TbListDetails } from "react-icons/tb";
import { FcViewDetails } from "react-icons/fc";





const ViewAddedProduct = (() => {
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [isPending, setIsPending] = useState(true);
  const [details, setDetails] = useState({})
  const [showEdit, setShowEdit] = useState("");
  const [cashId, setCashId] = useState("");
  const [hideTable, setHideTable] = useState(true)
  const [dropdown, setDropdown] = useState(false);
  const [search, setSearch] = useState("");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = ((product) => {
    setShow(true)
    setDetails(product)
  })
  const searchBtn = (() => {
    if (currentOrderDisplay) {
      return currentOrderDisplay
    }
  })


  useEffect(() => {
    setIsPending(true)
    mainaxios.get("ecommerce/getProductDetails")
      .then(response => {

        if (!response.statusText === "OK") {
          throw Error("Could not fetch data")
        }
        setIsPending(false)
        setData(response.data)

        setError(null)

      })
      .catch(error => {
        setError(error.message)
        setIsPending(false)
        console.log(error)
      })
  }, []);

  

  const handleSearchDisplay = ((event) => {
    setSearch(event.target.value)
  })

  const currentOrderDisplay = data && data.filter((order) => (
    order.productName && order.productName.toLowerCase().includes(search) || order.subCategoryName
    && order.subCategoryName
      .toLowerCase().includes(search)

  ))


  return (
    <>
      <div>
        <ul className="action-bar">
          <li>Home /</li>
          <li>E-commerce /</li>
          <li className="createem">View Products</li>
        </ul>
      </div>


      <div >
        <h2 className="viewemployee-head">View Fb Products</h2>


        <div className="search_box" id="searchDrive">
          <div className="search">
            <div className="select_area">
              <i className="fas fa-map-marker-alt map_icon"></i>
              <div className="text">Search</div>
            </div>
            <div className="line"></div>
            <div className="text_and-icon">
              <input type="text" className="search_text" id="search_text" placeholder="Search by product name or sub category name ..." onChange={handleSearchDisplay} />
              <i className=" search_icon" onClick={searchBtn} ><HiOutlineSearch />  </i>
            </div>
          </div>
        </div>


        <Table striped bordered hover size="sm" className='adaogidi-table'>
          <thead>


            <tr>
              <th>S/N</th>
              <th>Product Name</th>

              <th>Category name</th>
              <th>Product Price</th>
              <th>Promoter</th>
              <th>promoter Num.</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>

            {isPending && <div className="loaderless"> <div className="lds-ripple"> Loading... <div></div><div></div></div>

            </div>}

            {
              currentOrderDisplay && currentOrderDisplay.map((product, i) => (


                <tr key={product.comboId} >
                  <td  >{i + 1} </td>
                  <td> {product.productName}  </td>
                  <td> {product.subCategoryName} </td>
                  <td>{product.price} </td>
                  <td>{product.promoter} </td>
                  <td>{product.promoterNumber} </td>

                  <div className="side_iconBarDisplay" >

                    <div className="side_iconBarDisplayDiv" onClick={() => handleShow(product)}>
                      <FcViewDetails size={25} />
                    </div>
                  </div>


                </tr>

              ))

            }

          </tbody>
        </Table>
      </div>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-100w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Added Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p className="detailsAd-show">Product name: <span style={{ fontWeight: "lighter" }}> {details.productName} </span> </p>
            <p className="detailsAd-show">Subcategory name: <span style={{ fontWeight: "lighter" }}> {details.subCategoryName} </span>  </p>
            <p className="detailsAd-show">Product price: <span style={{ fontWeight: "lighter" }}> {details.price} </span>  </p>
            <p className="detailsAd-show"> promoter: <span style={{ fontWeight: "lighter" }}>{details.promoter} </span>  </p>
            <p className="detailsAd-show">  promoterNumber: <span style={{ fontWeight: "lighter" }}>{details.promoterNumber} </span>  </p>

            <p className="detailsAd-show"> Promo reason: <span style={{ fontWeight: "lighter" }}>{details.promoReason} </span>  </p>



          </div>
          <p className="detailsAd-show">Added Details:<p style={{ fontWeight: "lighter" }}>
            {details.productDetails}
          </p>
          </p>
          <p className="detailsAd-show">additional Info : <span style={{ fontWeight: "lighter" }}>{details.additionalInfo} </span>  </p>

          <div className="imageFlexAdded"> Images: {
            details.product_images && details.product_images.map((image) => (
              <div className="imageFlexAdded">
                <div > <img src={image.image} style={{ width: "80px", height: "80px" }} />   </div>
              </div>
            ))


          } </div>
          <p>Video:</p>
          <div>
            <video controls style={{ width: "200px", height: "150px" }}>
              <source src={details.videos} type="video/mp4" />
            </video>
          </div>


        </Modal.Body>
      </Modal>





    </>
  )
})
export default ViewAddedProduct