import { useForm } from "react-hook-form";

let renderCount = 0;


const CreateAdvertRate = () => {

    const { register, handleSubmit, formState: { errors } } = useForm();
    renderCount++;



    return (
        <>
            <div>
                <ul className="action-bar">
                    <li>Home /</li>
                    <li>Advert Rate/</li>
                    <li className="createem">Create Advert Rate</li>
                </ul>
            </div>

            <form className="createmployee-container" onSubmit={handleSubmit((data) => {
                console.log("createadvert-data", data)

            })} >
                <h2 className="createmployee-head">Create Advert Rate</h2>
                <label className="name-label"> Time Zone *</label> <br />
                <input placeholder="e.g 5a.m-6am" className="contentselect" {...register("timeZone", { required: "This field is required" })} />
                <p className="cum-error">{errors.timeZone?.message}</p>
                <label>Select Program Zone *</label> <br />
                <select className="contentselect" {...register("programZone")} >
                    <option>Ututu oma</option>
                    <option>radio in the air</option>
                    <option>radio as control </option>
                    <option>radio as control </option>
                    <option>radio as control </option>

                </select>

                <br /><br />
                <label>Add price per time</label> <br />
                <input className="contentselect" placeholder="e.g #2500 " {...register("advertPrice", { required: "Enter price per time" })} />
                <p className="cum-error">{errors.price?.message}</p>
                {/* <label>Short description of the Content</label><br />
                <textarea className="contentdescribe" /><br /> */}
                <label>Advert time</label> <br />
                <input className="contentselect" type='number' placeholder="e.g 30sec" {...register("advertTime", { required: "Enter advert time rate" })} />
                <p className="cum-error">{errors.advertTime?.message}</p>



                <button className="createmployee-btn" type="submit" >Create Rate</button>

            </form>

        </>
    )
}
export default CreateAdvertRate