


import { RiArrowDropDownLine } from "react-icons/ri"
import { useState } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useFectch from "./createbackend";
import Dropdown from 'react-bootstrap/Dropdown';
import { mainaxios, fbaxios } from "../../utility/axios"
import { Alert } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from "react";
import { MdOutlineCancel } from "react-icons/md"




function UpdateProms() {
  const [isPendings, setIsPendings] = useState(false);
  const [action, setAction] = useState('')
  const [show, setShow] = useState(false)
  const [lgShow, setLgShow] = useState(false);

  const [showMore, setShowMore] = useState(false)

  const actionButton = () => {
    setAction(!action)


  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);





  const [errorMess, setErrorMess] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [onlineAdsId, setonlineAdsId] = useState("");
  const [getAdvert, setGetAdvert] = useState([]);


  const { data, isPending, error } = useFectch('adverts/getOnlineAds');


  const update = () => {

    mainaxios.get("adverts/getOnlineAds")
      .then((res) => {
        setGetAdvert(res.data);
      });
  };
  useEffect(update, [getAdvert]);


  //////delete section/////////////////////////////////////////


  const handleSubmitDelete = () => {
    // event.preventDefault();
    setIsPendings(true);
    mainaxios.put(`adverts/removeOnlineAds/${onlineAdsId}`)
      .then(response => {

        if (response) {
          setIsPendings(false);
          setSuccessMessage("Advert Removed Successfully");
         

        } else {
          setIsPendings(false);
          setErrorMess("Could not delete the Advert, try again later");
        }
      })
      .catch(error => {
        console.log(error);
        setIsPendings(false);
      });

    setLgShow(false)


  }
  //  console.log("mennnnnnnnnnn", data);

  const [advertId, setAdvertId] = useState("")

  const toggleDelete = (online) => {

    setonlineAdsId(online.onlineAdsId)

    setLgShow(true)

  }

  const toggleAdvert = (online) => {

    setAdvertId(online.onlineAdsId)
    setEmployeeData(online)
    setShow(true)
  }

  ///////Update /////////////////////////////////////////////////////


  const handleImageChange = (e) => {

    setImage(e.target.files[0]);
  };


  const [employeeData, setEmployeeData] = useState('')
  const [isPendingUpdate, setIsPendingUpdate] = useState("")


  const [advertType, setAdvertType] = useState('');
  const [paidStartDate, setPaidStartDate] = useState('');
  const [paidEndDate, setPaidEndDate] = useState('');
  const [airStartDate, setAirStartDate] = useState('');
  const [amountPaid, setAmountPaid] = useState('');
  const [image, setImage] = useState('');

  const [airEndDate, setAirEndDate] = useState('');
  const [numberOfTimes, setNumberOfTimes] = useState('');
  const [advertLink, setAdvertLink] = useState('');
  const [priority, setPriority] = useState('');
  const [sizeType, setSizeType] = useState('');
  const [advertDetails, setAdvertDetails] = useState('');



  const initialValues = {
    advertType: employeeData && employeeData.advertType,
    paidStartDate: employeeData && employeeData.paidStartDate,
    paidEndDate: employeeData && employeeData.paidEndDate,
    airStartDate: employeeData && employeeData.airStartDate,
    amountPaid: employeeData && employeeData.amountPaid,
    image: employeeData && employeeData.image,
    airEndDate: employeeData && employeeData.airEndDate,
    numberOfTimes: employeeData && employeeData.numberOfTimes,
    link: employeeData && employeeData.link,
    priority: employeeData && employeeData.priority,
    advertDetails: employeeData && employeeData.advertDetails,
    sizeType: employeeData && employeeData.sizeType

  }
  const [formValues, setFormValues] = useState(initialValues)

  const handleSubmits = async (data) => {
    setFormValues({ ...formValues })

    setIsPendingUpdate(true)
    const formData = new FormData();
    formData.append("advertType", advertType);
    formData.append("paidStartDate", paidStartDate);
    formData.append("paidEndDate", paidEndDate);
    formData.append("airStartDate", airStartDate);
    formData.append("amountPaid", amountPaid);
    formData.append("airEndDate", airEndDate);
    formData.append("numberOfTimes", numberOfTimes);
    formData.append("advertLink", advertLink);
    formData.append("priority", priority);
    formData.append("sizeType", sizeType);
    formData.append("advertDetails", advertDetails);
    //  formData.append("link", data.link);
    formData.append("image", image);



    await mainaxios.put(`adverts/updateOnlineAds/${advertId}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {

        if (res.data.success) {
          setSuccessMessage("Advert Updated Successfully");
        
          setIsPendingUpdate(false)
        } else {
          setIsPendingUpdate(false)
          setSuccessMessage("Advert Updated Successfully");
        }

      }).catch((err) => {
        console.log("err", err)
        setErrorMess("Something went wrong. Please try again later.");
        setIsPendingUpdate(false)
      })



  }

  const handleCloseUpdate = () => {
    handleSubmits()
    setShow(false)
    // notify()

  }

  useEffect(() => {

    let timeout;
    if (successMessage) {
      timeout = setTimeout(() => {
        setSuccessMessage(false);
      }, 5000)
    }
    return () => {
      clearTimeout(timeout);
    };

  }, [successMessage])

  // const notify = () => toast("Processing your request...");

  const closeErrorClick = (() => {
    setErrorMess(false)
  })


  return (

    <>

      <div>
        <ul className="action-bar">
          <li>Home /</li>
          <li>Proms/Games/</li>
          <li className="createem">View Advert</li>
        </ul>
      </div>

      <div className="toaterter-run"> <ToastContainer /></div>
      {
        successMessage && <div >

          <button className="btn btn-success toast-success-btn">{successMessage}</button>

        </div>
      }
      {errorMess && <div >
        <button className="btn btn-danger toast-success-btn">{errorMess} <span onClick={closeErrorClick} className="remove-erroricon"><MdOutlineCancel size={25} /></span></button>
      </div>
      }
      {error && <button className="btn btn-danger toast-success-btn">{error}</button>
      }

      {isPendingUpdate &&
        <div style={{ display: isPendings ? 'flex' : 'none' }} className='modaled'>
          <div className='modal-contents'>
            <div className='loadered'></div>
            <div className='modal-texted'>Loading...</div>
          </div>
        </div>

      }

      <h2 className="viewemployee-head">View Online Advert</h2>

      <Table striped bordered hover size="sm" className='adaogidi-table'>
        <thead>
          <tr >
            <th>S/N</th>
            <th>Advert Type</th>
            <th>Start paid Time</th>
            <th>end paid Time</th>
            <th>Start Announcement Date</th>
            <th>End Announcement Date</th>
            <th>Amount Paid</th>
            <th>Duration</th>
            <th>Details</th>
            <th>Size Type</th>
            <th>Priority</th>
          </tr>
        </thead>
        <tbody>
          {isPending && <div className="loaderless"> <div className="lds-ripple"> Loading... <div></div><div></div></div>

          </div>}

          {
            getAdvert && getAdvert.map((online, i) => (
              <tr key={online.onlineAdsId}>
                <td>{i + 1}</td>
                <td>{online.advertType
                }</td>
                <td>{online.paidStartDateTime}</td>
                <td>{online.paidEndDateTime
                }</td>
                <td>{online.airStartDateTime
                }</td>
                <td>{online.airEndDateTime}</td>
                <td>{online.amountPaid
                }</td>
                <td>{online.numberOfTimes}</td>
                <td> {showMore ? online.advertDetails : online.advertDetails.substring(0, 100)}...
                </td>
                <td>{online.sizeType
                }</td>
                <td>{online.priority
                }</td>

                <Dropdown className="d-inline mx-2 firstline-drop " autoClose="outside"  >
                  <Dropdown.Toggle id="dropdown-autoclose-outside">
                    Action
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdownshow-d">
                    <Dropdown.Item className="sideside" onClick={() => toggleAdvert(online)}   >Update</Dropdown.Item>
                    <Dropdown.Item className="sideside" onClick={() => toggleDelete(online)} >Delete Advert</Dropdown.Item>
                    {/* <Dropdown.Item className="sideside" >Menu Item</Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
              </tr>


            ))
          }



        </tbody>

        {/* )) } */}

      </Table>
      <form   >
        <Modal show={show} onHide={handleClose} animation={false} className="employupdate-modal">
          <Modal.Header closeButton>
            <Modal.Title>View Online Advert</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            {/* <form className="createnews-form" >
          <h2 className="createnews-head">Create Advert </h2> */}
            <label>Advert Type</label><br />
            <select className="contentname"
              defaultValue={employeeData && employeeData.advertType}
              onChange={(e) => setAdvertType(e.target.value)} >
              <option value="Paid">Paid</option>
              <option value="Unpaid">Unpaid</option>
            </select><br /><br />
            <label>Start paid Date Time</label><br />
            <input className="contentname" placeholder="" type="datetime-local"
              defaultValue={employeeData && employeeData.paidStartDate}
              onChange={(e) => setPaidStartDate(e.target.value)}
            /><br /><br />
            <label>End paid Date Time</label><br />
            <input className="contentname" placeholder="" type="datetime-local"
              defaultValue={employeeData && employeeData.paidEndDate}
              onChange={(e) => setPaidEndDate(e.target.value)}
            /><br /><br />
            <label className="ccpay">On Air Announcement</label><br />
            <label>Start Paid Announcement Date Time</label><br />
            <input className="contentname" placeholder="" type="datetime-local"
              defaultValue={employeeData && employeeData.airStartDate}
              onChange={(e) => setAirStartDate(e.target.value)}
            /><br /><br />
            <label>End Paid Announcement Date Time</label><br />
            <input className="contentname" placeholder="" type="datetime-local"
              defaultValue={employeeData && employeeData.airEndDate}
              onChange={(e) => setAirEndDate(e.target.value)}
            /><br /><br />
            <label>Amount Paid</label><br />
            <input className="contentname" placeholder="" type="number"
              defaultValue={employeeData && employeeData.amountPaid}
              onChange={(e) => setAmountPaid(e.target.value)}

            /><br /><br />
            <label>Advert Link</label><br />
            <input className="contentname" placeholder="advert link..."
              defaultValue={employeeData && employeeData.advertLink}
              onChange={(e) => setAdvertLink(e.target.value)}
            /><br /><br />
            <label>Number of Times</label><br />
            <input className="contentname" placeholder=""
              defaultValue={employeeData && employeeData.numberOfTimes}
              onChange={(e) => setNumberOfTimes(e.target.value)}
            /><br /><br />
            {/* <input className="contentname" placeholder="Link..." 
           defaultValue={employeeData && employeeData.link}
           onChange={(e) => setFirstName(e.target.value)}
          /><br/><br/> */}
            <label>Priority</label><br />
            <select className="contentname" defaultValue={employeeData && employeeData.priority}
              onChange={(e) => setPriority(e.target.value)} >
              <option value="High" >High</option>
              <option value="Normal">Normal</option>
            </select><br /><br />
            <label>Display Area</label><br />
            <select className="contentname" defaultValue={employeeData && employeeData.sizeType}
              onChange={(e) => setSizeType(e.target.value)} >
              <option value="Top Page Rectangular">Top Page Rectangular</option>
              <option value="Side Page Banner 1">Side Page Banner 1</option>
              <option value=" Side Page Banner 2">Side Page Banner 2</option>
              <option value="Others">Others</option>
            </select><br /><br />

            <label>Details</label><br />
            <textarea className="news-detailsed" placeholder="Add Details"
              defaultValue={employeeData && employeeData.advertDetails}
              onChange={(e) => setAdvertDetails(e.target.value)}
            /><br /><br />
            <label >Change Image</label>
            <input type="file" className="contentname" onChange={handleImageChange} />
            <img className="edit-image-cont" src={employeeData && employeeData.image} />







          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleCloseUpdate}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </form>



      {/* remove promos */}
      <form onSubmit={handleSubmitDelete}>
        <Modal
          size="sm"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Remove News
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are You Sure You Want to Remove the Selected Promo?</p>
            <button className="btn-remove btn btn-danger" onClick={handleSubmitDelete}>Remove</button>
          </Modal.Body>


        </Modal>
      </form>

    </>
  );
}

export default UpdateProms;









