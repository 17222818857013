
import { RiArrowDropDownLine } from "react-icons/ri"
import { useState, useEffect, useRef } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import { useAlert } from 'react-alert'
import { mainaxios, fbaxios } from "../../utility/axios";

import useFectch from "./createbackend";
import Dropdown from 'react-bootstrap/Dropdown';
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdOutlineCancel } from "react-icons/md"





function SmallExamples() {

  const navigate = useNavigate()

  const [action, setAction] = useState('')
  const [show, setShow] = useState(false)
  const [lgShow, setLgShow] = useState(false);
  const [lgShows, setLgShows] = useState(false);

  const actionButton = () => {

    setAction(!action)
  }
  const handleClose = () => setShow(false);


  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [erro, setErro] = useState(null);
  const [successMessag, setSuccessMessag] = useState(null);

  const [employeeData, setEmployeeData] = useState()

  const [userID, setUserID] = useState()



  const handleImageChange = (e) => {

    setImage(e.target.files[0]);
  };


  const notify = () => toast("Processing your request...");

  //update section////////////////////////

  const { register, handleSubmit, formState: { errors } } = useForm();
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [employees, setEmployees] = useState([])

  const { data, isPending, error } = useFectch('registrations/getUsers');



  const update = () => {

    mainaxios.get("registrations/getUsers")
      .then((res) => {
        setEmployees(res.data);
      });
  };
  useEffect(update, [employees]);



  const [users, setUsers] = useState(data)

  // //////delete section///////////////////////////////

  const [errorMess, setErrorMess] = useState("");
  const [successMessage, setSuccessMessage] = useState("");


  const handleSubmitDelete = (event) => {
    event.preventDefault();
    mainaxios.put(`registrations/removeUser/${userID}`)
      .then(response => {


        if (response) {
          setSuccessMessage("Employee Removed Successfully");

        } else {

          setErrorMess(data.message);
        }
      })
      .catch(error => {
        console.log(error);
      });

    setLgShow(false)

    handleSubmitDelete();
  }







  const toggleDelete = (employee) => {

    setUserID(employee.userId)
    notify()
    setLgShow(true)
  }


  // console.log("userID", userID)
  const updateShow = (employee) => {

    setUserID(employee.userId)

  }
  const [close, setClose] = useState(false)
  const handleAlertClose = () => {
    setClose(!close)

  }



  /////Update section////////////////////////////////////////////////////////

  const [isPendingUpdate, setIsPendingUpdate] = useState("")
  const [isPendings, setIsPendings] = useState(false);
  const toggle = (employee) => {

    setUserID(employee.userId)
    setEmployeeData(employee)

    setShow(true)
  }
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [department, setDepartment] = useState('');
  const [image, setImage] = useState(null);

  const [menuCollapse, setMenuCollapse] = useState(false)

  const initialValues = {
    firstName: employeeData && employeeData.firstName, lastName: employeeData && employeeData.lastName,
    emailAddress: employeeData && employeeData.emailAddress,
    phoneNumber: employeeData && employeeData.phoneNumber, department: employeeData && employeeData.department,
  }
  const [formValues, setFormValues] = useState(initialValues)

  const handleSubmits = async (data) => {
    setIsPendings(true)
    setFormValues({ ...formValues })

    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("emailAddress", emailAddress);
    formData.append("phoneNumber", phoneNumber);
    formData.append("department", department);
    formData.append("image", image);



    await mainaxios.put(`registrations/updateUser/${userID}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {

        if (res.data.success) {
          setSuccessMessage("Employee Updated Successfully");
         

          setIsPendingUpdate(false)
        } else {
          setIsPendingUpdate(false)
          setSuccessMessage("Employee Updated Successfully");
        }

      }).catch((err) => {

        setErrorMess("Something went wrong. Please try again later.");
        setIsPendingUpdate(false)
      })
  }

  const handleCloseUpdate = () => {
    handleSubmits()
    setShow(false)
    notify()

  }

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [Password, setPassword] = useState('');
  const [PasswordError, setPasswordError] = useState('');
  const [userId, setUserId] = useState('');

  ///////Change Password////////////////////////////////////////////////


  const ref = useRef(null);


  const toggleChangePassword = (employee) => {

    setUserID(employee.userId)


    setLgShows(true)
  }

  const [productId, setProductId] = useState()



  const handleCurrentPasswordChange = (event) => {
    setOldPassword(event.target.value);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };
  const handleUserId = (event) => {
    setUserId(event.target.value);
  };

  const allData = { oldPassword, newPassword, userId: userID }

  const handleSubmited = async (data) => {

    setIsPendings(true);

    if (oldPassword < 3 && newPassword < 3) {
      setLgShows(true)
    }
    await mainaxios.put("registrations/password", allData)

      .then((res) => {


        if (res.data.success) {
          setIsPendings(false);
          setSuccess(res.data.message);

        } else {
          setIsPendings(false);
          setErrorMessage(res.data.message);
        }

      }).catch((err) => {
        setIsPendings(false);

        setErrorMessage("Something went wrong, please try again");

      })

    setLgShows(false)
  }

  const menuIconClick = () => {

    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };


  useEffect(() => {

    let timeout;
    if (success) {
      timeout = setTimeout(() => {
        setSuccess(false);

      }, 5000)
    }
    return () => {
      clearTimeout(timeout);
    };

  }, [success])

  const closeErrorClick = (() => {
    setErrorMessage(false)
  })


  const [dropdown, setDropdown] = useState(false);
  const toggleOpen = () => setDropdown(!dropdown);


  return (

    <>

      <div>


        <ul className="action-bar">
          <div className="closemenu" onClick={menuIconClick}>


          </div>
          <li>Employee/</li>
          <li className="createem">View Employee</li>
        </ul>
      </div>
      <div className="toaterter-run"> <ToastContainer /></div>

      {
        success && <div >

          <button className="btn btn-success toast-success-btn">{success}</button>

        </div>
      }
      {errorMessage && <div >
        <button className="btn btn-danger toast-success-btn">{errorMessage} <span onClick={closeErrorClick} className="remove-erroricon"><MdOutlineCancel size={25} /></span></button>

      </div>
      }
      {isPending &&
        <div className="lds-ellipsis">Creating please wait<div></div><div></div><div></div><div></div></div>
      }



      {error && <button className="btn btn-danger toast-success-btn">{error}</button>
      }
      {/* {
         Password && <div >
           
          <button  className="btn btn-success toast-success-btn">{Password}</button>
          
        </div>
        }
         {
         PasswordError && <div >
           
          <button  className="btn btn-danger toast-success-btn">{PasswordError}</button>
          
        </div>
        } */}
      {isPendings &&

        <div className="lds-ellipsis">Changing password please wait...<div></div><div></div><div></div><div></div></div>
      }



      {/* {isPendingUpdate && <Alert onClose={() => {}} className="alert-display-positive">Processing...</Alert>} */}

      <h2 className="viewemployee-head">Employee List

      </h2>


      <Table striped bordered hover size="sm" className='adaogidi-table'>


        <thead>

          <tr>
            <th>S/N</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Department</th>
            <th>Action</th>
          </tr>
        </thead>


        <tbody>
          {isPending && <div className="loaderless"> <div className="lds-ripple"> Loading... <div></div><div></div></div>

          </div>}
          {

employees &&employees.map((employee, i) => (

              <tr key={employee.userId}>
                <td>{i + 1}</td>
                <td>{employee.firstName}</td>
                <td>{employee.lastName}</td>
                <td>{employee.phoneNumber}</td>
                <td>{employee.emailAddress}</td>
                <td> {employee.department} </td>
                {/* onClick={()=>sendDetails(employee)} */}
                <Dropdown className="d-inline mx-2 firstline-drop " autoClose="outside"  >
                  <Dropdown.Toggle id="dropdown-autoclose-outside" >
                    Action
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdownshow-d">
                    <Dropdown.Item className="sideside" onClick={() => toggle(employee)}  >Update</Dropdown.Item>
                    <Dropdown.Item className="sideside" onClick={() => toggleChangePassword(employee)} >Change Password</Dropdown.Item>
                    <Dropdown.Item className="sideside" onClick={() => toggleDelete(employee)} >Delete</Dropdown.Item>
                    {/* <Dropdown.Item className="sideside" >Menu Item</Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>


              </tr>


            ))
          }
        </tbody>




      </Table>



      <form onSubmit={handleSubmits}>

        {isPendings &&
          <div style={{ display: isPendings ? 'flex' : 'none' }} className='modaled'>
            <div className='modal-contents'>
              <div className='loadered'></div>
              <div className='modal-texted'>Loading...</div>
            </div>
          </div>

        }


        <Modal show={show} onHide={handleClose} animation={false} className="employupdate-modal">

          <Modal.Header closeButton>
            <Modal.Title>Update Employee</Modal.Title>
          </Modal.Header>

          <Modal.Body key={employeeData && employeeData.userId}  >

            {/* <input type="hidden" name="id" value={employ.userId} /> */}
            <label name="firstName">First Name *</label> <br />
            <input type="text"
              className="contentname"
              required
              name="firstName"
              defaultValue={employeeData && employeeData.firstName} onChange={(e) => setFirstName(e.target.value)}
            />

            <br /> <br />



            <label name="fullName"> Last Name *</label> <br />
            <input placeholder=""
              className="contentname"
              required
              name="lastName"
              defaultValue={employeeData && employeeData.lastName} onChange={(e) => setLastName(e.target.value)}
            />

            <br /> <br />

            <label>Email *</label> <br />

            <input placeholder=""
              className="contentname"
              name="emailAddress"
              required
              defaultValue={employeeData && employeeData.emailAddress} onChange={(e) => setEmailAddress(e.target.value)}
            /><br /><br />


            <label>Phone *</label> <br />
            <input placeholder=""
              className="contentname"
              name="phoneNumber"
              required
              defaultValue={employeeData && employeeData.phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}
            />

            <br /> <br />

            <label>Department</label><br />
            <select
              className="contentname"
              name="department"
              required
              defaultValue={employeeData && employeeData.department} onChange={(e) => setDepartment(e.target.value)}
            >
              <option value="ICT">ICT</option>
              <option value="Broadcasting">Broadcasting</option>
              <option value="Admin">Admin</option>
              <option value="Security">Security</option>
              <option value="Others">Others</option>
            </select> <br /> <br />


            <label>Change Image</label> <br />
            <input type="file" onChange={handleImageChange} name="file" required />
            <img className="edit-image-cont" src={employeeData && employeeData.image} />
            <p>

            </p>
          </Modal.Body>
          <Modal.Footer>
            {/* <button type="submit">submit</button> */}
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit"
              onClick={handleCloseUpdate}
            >
              Save Changes
            </Button>
          </Modal.Footer>



        </Modal>
      </form>




      {/* change password modal */}

      <form onSubmit={handleSubmit((data, event) => {
        event.target.reset()
        console.log('testingdata', data);
        handleSubmited(data)
      })} >

        <Modal
          size="lg"
          show={lgShows}
          onHide={() => setLgShows(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Change Password
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <label htmlFor="current-password">Old password</label>
            <input
              type="password"
              name="oldPassword"
              placeholder="Enter old password..."
              id="current-password"
              className="inputchange-password"
              onChange={handleCurrentPasswordChange}
              required
            /><br /><br />
            <label>New password</label><br />
            <input className="inputchange-password"
              name="newPassword"
              type="password"
              placeholder="Enter new password..."
              id="new-password"
              onChange={handleNewPasswordChange}
              required
            /><br /><br />
            <input className="inputchange-password"
              name="userId"
              type="password"
              id="confirm-new-password"
              defaultValue={userID}
              onChange={handleUserId}
              required
              hidden
            /><br /><br />

            <button className="submit-change-pass btn btn-primary" type="submit" onClick={handleSubmited}>Change Password</button><br />


          </Modal.Body>
        </Modal>
      </form>




      <form
        onSubmit={handleSubmitDelete}
      >

        <Modal
          size="sm"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"

        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Remove User
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are You Sure You Want to Remove the Selected User?</p>
            <button className="btn-remove btn btn-danger" type="submit" onClick={handleSubmitDelete} >Remove</button>
          </Modal.Body>


        </Modal>
      </form>
    </>
  );
}


export default SmallExamples;









