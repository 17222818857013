import { RiArrowDropDownLine } from "react-icons/ri"
import { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useFectch from "./createbackend";
import { mainaxios, fbaxios } from "../../utility/axios"
import { Alert } from "react-bootstrap";
import { MdOutlineCancel } from "react-icons/md"
import { FaTrashAlt } from "react-icons/fa";


function EditCallNumber() {

  const [action, setAction] = useState('');
  const [show, setShow] = useState(false)
  const [lgShow, setLgShow] = useState(false);
  const [getNumbers, setGetNumbers] = useState([]);

  const actionButton = () => {
    setAction(!action)


  }
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const { data: callin, isPending, error } = useFectch('programs/getCallNumbers');

 
  const update = () => {

    mainaxios.get("programs/getCallNumbers")
      .then((res) => {
        setGetNumbers(res.data);
      });
  };
  useEffect(update, [getNumbers]);


  ///////remove number/////
  const [errorMess, setErrorMess] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [callId, setCallId] = useState("")

  const handleSubmitDelete = (event) => {
    event.preventDefault();
    mainaxios.put(`programs/removeCallNumbers/${callId}`)
      .then(response => {

        if (response) {
          setSuccessMessage("News Removed Successfully");
         
        } else {

          setErrorMess(
            // data.message
          );
        }
      })
      .catch(error => {
        console.log(error);
      });

    setLgShow(false)
  }



  const toggleDelete = (call) => {
    console.log("newsID", call)
    setCallId(call.callNumbersId
    )

    setLgShow(true)
  }

  const closeErrorClick = (() => {
    setErrorMess(false)
  })



  return (

    <>

      {
        successMessage && <div >

          <button className="btn btn-success toast-success-btn">{successMessage}</button>

        </div>
      }
      {errorMess && <div >
        <button className="btn btn-danger toast-success-btn">{errorMess} <span onClick={closeErrorClick} className="remove-erroricon"><MdOutlineCancel size={25} /></span></button>

      </div>

      }
      {isPending && <div className="loaderless">Loading...
        <figure>
          <div className="dot white"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </figure>

      </div>}
      {error && <div className="errorssample">{error}</div>}

      <div>
        <ul className="action-bar">
          <li>Home /</li>
          <li>Edit Call-in Number/</li>
          <li className="createem">Call-in Numbers</li>
        </ul>
      </div>

      <h2 className="viewemployee-head">View Call-in Numbers</h2>

      <Table striped bordered hover size="sm" className='adaogidi-table'>
        <thead>
          <tr>
            <th>S/N</th>
            <th>Type</th>
            <th>Call-in Number</th>
            <th>Whatsapp/Text/Email</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {
            getNumbers && getNumbers.map((call, i) => (
              <tr>
                <td >{i + 1}</td>
                <td>{call.type}  </td>
                <td> {call.callNumbers} </td>
                <td>{call.socialMediaNumbers} </td>
                <i onClick={() => toggleDelete(call)} > <FaTrashAlt  size={25}/> </i><br />




              </tr>

            ))
          }



        </tbody>
      </Table>


      <Modal show={show} onHide={handleClose} animation={false} className="employupdate-modal">
        <Modal.Header closeButton>
          <Modal.Title>Update Number</Modal.Title>
        </Modal.Header>
        <Modal.Body>



          <label name="fullName"> Type</label> <br />
          <input placeholder="First Name" className="contentname" />
          <p className="cum-error"></p>


          <label>Call-in Number</label> <br />

          <input className="contentname" /> <br /> <br />

          <label>Whatsapp/Text</label> <br />

          <input className="contentname" /> <br /> <br />


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>


      {/* remove Number */}
      <form onSubmit={handleSubmitDelete}>
        <Modal
          size="sm"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Remove News
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are You Sure You Want to Remove the Selected Number?</p>
            <Button variant="primary" type="submit" className="btn-remove btn btn-danger" onClick={handleSubmitDelete} >Remove</Button>
          </Modal.Body>


        </Modal>
      </form >
    </>
  );
}

export default EditCallNumber;