
import { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import {mainaxios,fbaxios} from "../../../utility/axios"

function DarkExample() {

 const [programs, setPrograms] = useState([]);
    const [currentProgram, setCurrentProgram] = useState(null);
    // const [error, setError] = useState(null);
    
        

    useEffect(()=>{
        mainaxios.get("programs/getPrograms")
        .then(response=>{
         
          if (!response.statusText=== "OK"){
            throw Error("Could not fetch data")
          }
          setPrograms(response.data)
         
        })
        .catch(error =>{
        console.log(error)
        })
       },[]);

       useEffect(()=>{
        const  updateCurrentProgram =(()=>{
            // setPrograms(programs)
            const now = new Date()
            const currentTime = now.getHours() + ':' + now.getMinutes()
            //  console.log("curnttttt",currentProgram);
             const currentProgram = programs.find(
               (program)=> 
               currentTime >= program.programStartTime &&
               currentTime <= program.programEndTime
                 
                   )
           
            setCurrentProgram(currentProgram);

        })
        updateCurrentProgram()
        const updateInterval = setInterval(()=>{
        updateCurrentProgram(); 
        // const now = new Date()
        // const time = now.getHours() + ':' + now.getMinutes()
        // const currentProgram = programs.find(
        //     (program) => time >= new Date(program.programStartTime
                
        //         ) && time <= new Date(program.programEndTime
        //             ))
        // setCurrentProgram(currentProgram)
        // console.log("current",currentProgram);
       },1000);
    //    console.log("prom>>>>>>>>",programs);
       return () => clearInterval(updateInterval)

       },[programs]);



  return (

    <>
      {
        currentProgram ? (
          <Table striped bordered hover variant="dark">
      
          <thead>
            <tr>
              <th>S/N</th>
              <th>Program Title</th>
              <th>Host Name</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>{currentProgram.programName }</td>
              <td>{currentProgram.programpresenters
                   .map((person)=>(
                    person.firstName   
                 ))}</td>
              <td>{currentProgram.programStartTime
                }-{currentProgram.programEndTime}</td>
            </tr>
           
          </tbody>
        </Table>

        ):(

          <Table striped bordered hover variant="dark">
      
          <thead>
            <tr>
              <th>S/N</th>
              <th>Program Title</th>
              <th>Host Name</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>------</td>
              <td>------</td>
              <td>------</td>
            </tr>
           
          </tbody>
        </Table>

        )
      }

   


    </>
  );
}

export default DarkExample;