
import { Link, useLocation, useNavigate } from "react-router-dom"
// import {datas} from "./itemdata"
import { TbArrowBadgeRight } from "react-icons/tb"
import { useState, useEffect } from "react"
import { MdClearAll } from "react-icons/md"
import { mainaxios, fbaxios } from '../../utility/axios';
import { AiOutlineShoppingCart } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { addToCart } from "../../redux/counter";
import 'bootstrap/dist/css/bootstrap.min.css'
import { CAlert } from "@coreui/react";
import { ImCheckmark2 } from "react-icons/im";
import { BsFilterRight } from "react-icons/bs";



const EcommerceGoods = (() => {
  const [outOfStock, setOutOfStock] = useState("")
  const [datas, setDatas] = useState([]);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);
  const [majorData, setMajorData] = useState([])
  const [subCatName, setSubCatName] = useState('')
  const [productId, setProductId] = useState('')
  const [displayCategoryText, setDisplayCategoryText] = useState(false)
  const [allProductTenary, setAllProductTenary] = useState(true)
  const [subName, setSubName] = useState("")
  const [imageDisplay, setImageDisplay] = useState(true)
  const [outOfStocks, setOutOfStocks] = useState(false)
  const [onLoading, setOnLoading] = useState(true)

  const [isFocused, setIsFocused] = useState(false)
  const dispatch = useDispatch();


  useEffect(() => {
    setIsPending(true)
    fbaxios.get("products/menus/djW7G5kgB43wqdh?locationId=Njw9pXwT3BYIUMc")
      .then(response => {

        if (!response.statusText === "OK") {
          throw Error("Could not fetch data")
        }
        setIsPending(false)
        setDatas(response.data)

        setError(null)

      })
      .catch(error => {
        setError(error.message)
        setIsPending(true)
        console.log(error)
      })
  }, []);




  useEffect(() => {
    setIsPending(true)
    setImageDisplay(true)
    fbaxios.get("products/getMenuProdByMajorId/djW7G5kgB43wqdh/aVNRL2gX2sdIUTm")
      .then(response => {

        if (!response.statusText === "OK") {
          throw Error("Could not fetch data")
        }
        setIsPending(false)
        setMajorData(response.data)
        setImageDisplay(false)

        setError(null)
        // console.log("data>>>>>>>>>",data)
      })
      .catch(error => {
        setError(error.message)
        setIsPending(true)
        setImageDisplay(false)
        console.log(error)
      })
  }, []);



  const navigate = useNavigate();

  const toggleFocus = () => {
    setIsFocused(!isFocused)
  }


  useEffect(() => {

    const handleCurrentQuantity = (() => {

      const currentQuantity = datas.products && datas.products.fields.map((fields) => (
        fields.fieldItems.map((item) => (
          item.currentQuantity

        ))

      ))
      if (currentQuantity < 1) {
        setOutOfStock("Sold out")

      } else {
        return
      }

    }
    )
    handleCurrentQuantity()

  }, [])
  const [alert, setAlert] = useState(false)


  const handleAddToCart = ((prod) => {
    const cartData = {
      comboId: prod.comboId, comboName: prod.comboName, description: prod.description,
      price: prod.price, picture: prod.picture, comboTypeCategory: prod.comboTypeCategory, menuId: prod.menuId, hotelId: prod.hotelId,
      comboType: prod.comboType, fields: prod.fields && prod.fields.map((item) => (
        {
          defaultCheckBox: item.defaultCheckBox, fieldId: item.fieldId,
          fieldName: item.fieldName, fieldType: item.fieldType, fulfillmentType: item.fulfillmentType, itemRequired: item.itemRequired,
          orderNumber: item.orderNumber, printOnInvoice: item.printOnInvoice, fieldItems: item.fieldItems.map((fieldItem) => (
            fieldItem
          ))
        }
      ))
    }

    dispatch(addToCart(cartData))
    setAlert(true)
  })



  useEffect(() => {

    let timeout;
    if (alert) {
      timeout = setTimeout(() => {
        setAlert(false);
      }, 2000)
    }
    return () => {
      clearTimeout(timeout);
    };

  }, [alert])




  const symbol = Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: 'NGN'
  })
    .formatToParts(0)
    .filter(part => part.type === 'currency')
    .map(part => part.value)
    .join('')

  const handleSelectedProduct = (product) => {
    navigate(`/goodsdetails/${product.comboId}`, { state: { product, name: '' } })
    setProductId(product.comboId)
  }



  // useEffect(()=>{
  //   setImageDisplay(imageDisplay)
  // },[])

  const handleSubCatName = (major) => {
    setSubCatName(major.subCategoryName)
    setAllProductTenary(false)
    setDisplayCategoryText(true)
    setSubName()
    console.log("(major", major);

  }


  const subCatogoryFilter = datas.products && datas.products.filter((products) => (
    subCatName === products.subCategoryName

  ))
  const handleAllProductTenary = () => {
    setAllProductTenary(true)
    toggleFocus()
    setDisplayCategoryText(false)
  }
  const scrolltop = (() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"

    });
  })


  useEffect(() => {
    const filter = datas.products && datas.products.map((data) => (
      setImageDisplay(data.picture)
    ))
  }, [datas])



  const outStock = datas.products

  const disableBtn = outStock && outStock.map((product) => (
    product.fields.map((field) => (
      field.fieldItems.map((item) => (
        { item: item.currentQuantity }
      ))
    ))
  ))
  if (disableBtn < 1) {
    setOutOfStocks(true)
  }


  const loadImage = (() => {
    const image = new Image();
    image.onload = () => {
      setOnLoading(false)
    }

  });
  loadImage();

  return (

    <>



      {
        alert && <CAlert style={{ position: "fixed", marginTop: "-5%", zIndex: "100" }}>
          <ImCheckmark2 size={30} style={{ padding: "5px" }} /> Item added to your cart!
        </CAlert>
      }
      <div className="main-eco-goods">
        {/* <form > */}
        <ul className="filter_catControl_mobile"><span className="display_BigS"> Filter Categories </span> <BsFilterRight size={20} />
          <li className="hiDe_Cat_list">
            <li onClick={() => handleAllProductTenary()} className="catogory_ListCheck"><TbArrowBadgeRight /> All</li>
            {

              majorData.subCategories && majorData.subCategories.map((major) => (
                <li onClick={() => handleSubCatName(major)} key={major.subCategoryId} className="catogory_ListCheck"> <TbArrowBadgeRight />  {major.subCategoryName}</li>

              ))

            }
          </li>


        </ul>
       
        <ul id="cardsId">




          {

            isPending &&
            <div className="cardSkeletonDis">
              <li className="cards_item-eco " >
                <div className="linkselect" >
                  <div className="card-eco" id="cardSkelotoIdT">
                    <div className="card_image">
                      <img />
                      <span ><span> </span> </span>
                    </div>
                    <div className="card_content">
                      <h2 className="card_title"> </h2>

                    </div>
                  </div>
                </div>
              </li>

              <li className="cards_item-eco" id="hideMobilepro-ske">
                <div className="linkselect" >
                  <div className="card-eco" id="cardSkelotoIdT">
                    <div className="card_image">
                      <img />
                      <span ><span> </span> </span>
                    </div>
                    <div className="card_content">
                      <h2 className="card_title"> </h2>

                    </div>
                  </div>
                </div>
              </li>

              <li className="cards_item-eco" id="hideMobilepro-ske">
                <div className="linkselect" >
                  <div className="card-eco" id="cardSkelotoIdT">
                    <div className="card_image">
                      <img />
                      <span ><span> </span> </span>
                    </div>
                    <div className="card_content">
                      <h2 className="card_title"> </h2>

                    </div>
                  </div>
                </div>
              </li>


              <li className="cards_item-eco" id="hideMobilepro-ske">
                <div className="linkselect" >
                  <div className="card-eco" id="cardSkelotoIdT">
                    <div className="card_image">
                      <img />
                      <span ><span> </span> </span>
                    </div>
                    <div className="card_content">
                      <h2 className="card_title"> </h2>

                    </div>
                  </div>
                </div>
              </li>


              <li className="cards_item-eco" id="hideMobilepro-ske">
                <div className="linkselect" >
                  <div className="card-eco" id="cardSkelotoIdT">
                    <div className="card_image">
                      <img />
                      <span ><span> </span> </span>
                    </div>
                    <div className="card_content">
                      <h2 className="card_title"> </h2>

                    </div>
                  </div>
                </div>
              </li>

              <li className="cards_item-eco" id="hideMobilepro-ske">
                <div className="linkselect" >
                  <div className="card-eco" id="cardSkelotoIdT">
                    <div className="card_image">
                      <img />
                      <span ><span> </span> </span>
                    </div>
                    <div className="card_content">
                      <h2 className="card_title"> </h2>

                    </div>
                  </div>
                </div>
              </li>


            </div>

          }
          {
            displayCategoryText && <div className="categoryText-Check">{subCatName}</div>
          }
          {
            allProductTenary ? (
              datas.products && datas.products.map((prod) => (
                <li className="cards_item-item" key={prod.comboId}>
                  <div className="linkselect" >
                    <div className="card-eco">
                      <div className="card_image" onClick={() => handleSelectedProduct(prod)}>

                        <div className="bg-image hover-zoom ">

                          <picture>
                            <img className="card_image_main"
                              decoding="async"
                              role="presentation"
                              loading="lazy"
                              fetchPriority="high"
                              src={prod.picture} />

                          </picture>
                        </div>
                        {/* {
                          outOfStocks ? <span className="display-Outofstock">Out of stock</span> : null
                         }  */}
                        {
                          prod.fields[0]?.fieldItems[0]?.currentQuantity === 0 ? (
                            <span key={prod.comboId} className="display-Outofstock">
                              Out of stock
                            </span>
                          ) : null
                        }

                        <span className="card_price"><span>{symbol} </span>{prod.price} </span>
                        <span className="card_priceCat"><span> </span>{prod.subCategoryName} </span>
                      </div>
                      <div className="card_content">
                        <h2 className="card_title">{prod.comboName.substr(0, 23)} </h2>

                        <div> {prod.description.substr(0, 200)}... <span className="moreDetail_main" onClick={() => handleSelectedProduct(prod)}>More details</span> </div>
                        {
                          prod.fields[0]?.fieldItems[0]?.currentQuantity === 0 ? (
                            <button className="button-57" disabled role="button" onClick={() => handleAddToCart(prod)}> Add to cart   <span className="text-ADDcart">    </span><span>   <AiOutlineShoppingCart size={30} />   </span></button>
                          ) : <button className="button-57" role="button" onClick={() => handleAddToCart(prod)}> Add to cart  <span className="text-ADDcart">    </span><span>   <AiOutlineShoppingCart size={30} />   </span></button>
                        }


                        {/* <button className="button-57" role="button" onClick={() => handleAddToCart(prod)}> Add <span className="addRemoveText">to cart</span>   <span className="text-ADDcart">    </span><span>   <AiOutlineShoppingCart size={30} />   </span></button> */}
                      </div>
                    </div>
                  </div>
                </li>
              ))

            ) : (

              subCatogoryFilter && subCatogoryFilter.map((prod) => (
                <li className="cards_item-eco" key={prod.comboId}>
                  <div className="linkselect" >
                    <div className="card-eco">
                      <div className="card_image" onClick={() => handleSelectedProduct(prod)}>

                        <div className="bg-image hover-zoom ">
                          <picture>
                            <img src={prod.picture}
                              decoding="async"
                              role="presentation"
                              loading="lazy"
                              fetchPriority="high"
                              alt="mixed vegetable salad in a mason jar." />
                          </picture>

                        </div>
                        {
                          prod.fields[0]?.fieldItems[0]?.currentQuantity === 0 ? (
                            <span key={prod.comboId} className="display-Outofstock">
                              Out of stock
                            </span>
                          ) : null
                        }
                        <span className="card_price"><span>{symbol} </span>{prod.price} </span>
                        <span className="card_priceCat"><span> </span>{prod.subCategoryName} </span>
                      </div>
                      <div className="card_content">
                        <h2 className="card_title">{prod.comboName.substr(0, 23)} </h2>
                        <div> {prod.description.substr(0, 200)}... <span className="moreDetail_main" onClick={() => handleSelectedProduct(prod)}>More details</span> </div>
                        {
                          prod.fields[0]?.fieldItems[0]?.currentQuantity === 0 ? (
                            <button className="button-57" disabled role="button" onClick={() => handleAddToCart(prod)}> Add to cart  <span className="text-ADDcart">    </span><span>   <AiOutlineShoppingCart size={30} />   </span></button>
                          ) : <button className="button-57" role="button" onClick={() => handleAddToCart(prod)}> Add to cart   <span className="text-ADDcart">    </span><span>   <AiOutlineShoppingCart size={30} />   </span></button>
                        }

                        {/* <button className="button-57" role="button" onClick={() => handleAddToCart(prod)}> Add <span className="addRemoveText">to cart</span>   <span className="text-ADDcart">    </span><span>   <AiOutlineShoppingCart size={30} />   </span></button> */}

                      </div>
                    </div>
                  </div>
                </li>

              ))

            )
          }

        </ul>




      </div>
      <div>

      </div>





    </>
  )
})
export default EcommerceGoods