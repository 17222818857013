import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useFieldArray, useForm } from 'react-hook-form';
import { mainaxios, fbaxios } from "../../../utility/axios"
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MdOutlineCancel } from 'react-icons/md';
import { TextArea } from 'semantic-ui-react';

let renderCount = 0;

const CreateProduct = (() => {
  const { register, handleSubmit, control, formState: { errors } } = useForm();
  renderCount++;
  console.log(errors)
  const [error, setError] = useState(null);
  const [datas, setDatas] = useState([]);
  const [isPending, setIsPending] = useState(true);
  const [image, setImage] = useState(null);
  const [videoUpload, setVideoUpload] = useState(null);
  const [selectedPicture, setSelectedPicture] = useState([]);
  const [videos, setVideos] = useState(null);
  const [disabled, setDisabled] = useState(false)
  const [success, setSuccess] = useState("");
  const [errorMessage, setErrorMessage] = useState("")



  useEffect(() => {
    setIsPending(true)
    fbaxios.get("ecommerce/productDetails")
      .then(response => {

        if (!response.statusText === "OK") {
          throw Error("Could not fetch data")
        }
        setIsPending(false)
        setDatas(response.data)

        setError(null)

      })
      .catch(error => {
        setError(error.message)
        setIsPending(false)
        console.log(error)
      })
  }, []);

  const data = datas.products

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files).slice(0, 4)
    setSelectedPicture(files);
  };

  // const handleUploadVideo = (e) => {

  //   setVideos(Array.from(e.target.files));
  // };

  const handleUploadVideo = (e) => {
    setVideos(e.target.files[0]);
  };



  const renderImagePreviews = () => {
    return selectedPicture.map((picture, index) => (
      <img key={index} src={URL.createObjectURL(picture)} alt={`Preview ${index}`} style={{ maxWidth: '100px', maxHeight: '100px', marginLeft: "1%" }} />
    ));
  };




  const renderVideoPreviews = () => {
    return (
      videos && (
        <video
          controls
          width="100"
          height="100"
          style={{ marginLeft: '1%' }}
        >
          <source
            src={URL.createObjectURL(videos)}
            type={videos.type}
          />
          Your browser does not support the video tag.
        </video>
      )
    );
  };

  console.log("selectedPicture", selectedPicture);

  const handleSubmited = async (data, event) => {

    setDisabled(true)
    setIsPending(true)

    const formData = new FormData();
    formData.append("productName", data.productName);
    formData.append("subCategoryName", data.subCategoryName);
    formData.append("productDetails", data.productDetails);
    formData.append("price", data.price);
    formData.append("additionalInfo", data.additionalInfo);
    formData.append("promoter", data.promoter);
    formData.append("comboId", data.comboId);
    formData.append("promoterNumber", data.promoterNumber);
    formData.append("promoReason", data.promoReason);
    selectedPicture.forEach((picture) => {
      formData.append(`picture`, picture);

    });
    formData.append("videos", videos);

    await mainaxios.post("ecommerce/productDetails", formData, {
      headers: { "Content-Type": "multipart/form-data" },
      body: formData,
    })

      .then((res) => {
        console.log(res);
        if (res.data.success) {
          setIsPending(false)
          setSuccess(res.data.message);
          setDisabled(false)

        } else {

          setSuccess(res.data.message);
          setIsPending(false)
          setDisabled(false)

        }

      }).catch((err) => {
        console.log("err", err)
        setErrorMessage("Something went wrong, please try again later!");
        setIsPending(false)
        setDisabled(false)
      })

    scrolltop()
    event.target.reset()

  };
  const fbData = JSON.parse(localStorage.getItem("fbData"));



  const scrolltop = (() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"

    });
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: " "

  });

  const closeErrorClick = (() => {
    setErrorMessage(false)
  })

  useEffect(() => {

    let timeout;
    if (success) {
      timeout = setTimeout(() => {
        setSuccess(false);
      }, 5000)
    }
    return () => {
      clearTimeout(timeout);

    };

  }, [success])


  return (


    <>
      <div>

        <ul className="action-bar">
          <li>Home /</li>
          <li>E-commerce / </li>
          <li className="createem"> Add Details </li>
        </ul>
      </div>

      {isPending && <div className="loaderless"> <div className="lds-ripple"> Loading... <div></div><div></div></div>

      </div>}


      {
        success && <div >

          <div className="col-sm-12 displayOrder_alert">
            <div className="alert fade alert-simple alert-success alert-dismissible text-left  font__size-16  show">
              <button type="button" className="close font__size-18" data-dismiss="alert" onClick={closeErrorClick}>
                <span aria-hidden="true"><a>
                  <i className="fa fa-times greencross"></i>
                </a></span>
                <span className="sr-only">Close</span>
              </button>
              <i className="start-icon far fa-check-circle faa-tada animated"></i>
              <strong className="font__weight-semibold"> {success} </strong>
            </div>
          </div>

        </div>
      }

      {errorMessage && <div >
        <button className="btn btn-danger toast-success-btn">{errorMessage} <span onClick={closeErrorClick} className="remove-erroricon"><MdOutlineCancel size={25} /></span></button>

      </div>
      }


      <div className='createcompany-contain'>
        <Form className='booking-form-container' id='booking-form-container-id' onSubmit={handleSubmit((data, event) => {
          event.target.reset()
          handleSubmited(data)


        })}>
          <p className='filld-form-create'> Add more details to <span style={{ color: "darkorange" }}>{fbData.comboName}</span></p>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label className='lb'>Product name </Form.Label>
              <Form.Control readOnly value={fbData.comboName}  {...register("productName")} />



            </Form.Group>
            <Form.Group as={Col} >
              <Form.Label className='lb'>Price </Form.Label>
              <Form.Control readOnly value={fbData.comboPrice} type='number' {...register("price", { required: "This field is required" })} />

            </Form.Group>



            <Form.Group as={Col} >
              <Form.Label className='lb'>Sub category</Form.Label>
              <Form.Control readOnly value={fbData.subCategoryName} {...register("subCategoryName", { required: "This field is required" })} />

            </Form.Group>



          </Row>

          <Form.Group className="mb-3" controlId="formGridAddress1">
            <Form.Label className='lb'>Add more Details </Form.Label>
            <textarea placeholder="Enter more details to the product..." className='TextsAreainput' {...register("productDetails")} />

          </Form.Group>

          <Form.Group className="mb-3" controlId="formGridAddress2">
            <Form.Label className='lb'>Additional Information </Form.Label>
            <Form.Control placeholder="Enter additional info..." className='product-detailsinputad' {...register("additionalInfo")} />

          </Form.Group>



          <Row className="mb-3 " >

            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label className='lb'>Promoter </Form.Label>
              <Form.Control placeholder=" Enter additional..."{...register("promoter")} />

            </Form.Group>

            <Form.Group as={Col} controlId="formGridState">
              <Form.Label className='lb' >Promoter Contact number

              </Form.Label>
              <Form.Control type="number" placeholder="Enter phone number for enquiries..." {...register("promoterNumber")} />

            </Form.Group>





          </Row>
          <Form.Group as={Col} controlId="formGridZip">
            <Form.Label className='lb'> Reason for Promoting </Form.Label>
            {/* <Form.Control  placeholder=" Enter expected delivery day ... e.g 2days" {...register("promoReason")} /> */}
            <textarea placeholder="Enter Reason for promoting..." className='TextsAreainput'  {...register("promoReason")} />

          </Form.Group>
          <Form.Group hidden >
            <Form.Control className='product-detailsinput' value={fbData.comboId}  {...register("comboId")} />
          </Form.Group>

          {/* <Form.Group className="mb-3" controlId="formGridAddress1">
                            <Form.Label className='lb'>Reason for Promoting </Form.Label>
                            <Form.Control placeholder="Enter your delivery address..." className='product-detailsinput'  {...register("productDetails", { required: "This field is required" })} />
                            <span className="cum-error">{errors.prouctDetails?.message}</span>
                        </Form.Group> */}



          <Form.Group as={Col} controlId="formGridState">
            <Form.Label className='lb' >FB Image </Form.Label>
            <img className='' src={fbData.picture} style={{ with: "100px", height: "100px", padding: "5px" }} />

          </Form.Group>

          <Row>
            <Form.Group as={Col} key={24}>
              <Form.Label className='lb'>Upload more images of the product(maximum of four images) </Form.Label><br />

              <div>
                <Form.Control type='file' name='picture' className='input-imagesfile' multiple accept='image/*' onChange={handleImageChange} />
                {renderImagePreviews()}
              </div>
            </Form.Group>

            <Form.Group as={Col} key={19}>
              <Form.Label className='lb'>Upload Video clip </Form.Label><br />
              <div>
                <Form.Control type='file' accept='image/*, video/*' onChange={handleUploadVideo} name='video' className='input-imagesfile' />
                {renderVideoPreviews()}
              </div>
            </Form.Group>

          </Row>
          <Button id='conorder' type="submit" className='submitbtn-create ' disabled={disabled}>
            {
              !isPending ? <span > Add Changes</span> : <span> <span></span>   <span className="loader-load"></span></span>
            }

          </Button>

        </Form>
      </div>

    </>
  )
})
export default CreateProduct