import Changer from "../layouts/changer"
import Footer from "../layouts/footer"
import ModeHeader from "../layouts/modeheader"
import EcommerceNav from "./ecommerce_nav"
import EcoNAvSearch from "./econavsearch"
import ProductCards from "./product"
import PaymentMethods from "./product"
import Products from "./product"




const AccountPage = (() => {



  return (
    <>
      <main className="mainbookinpage-all">
        <div>
          <ModeHeader />
          <Changer />
        </div>
        <div>
          <EcoNAvSearch />
        </div>

        <div className="cartpush-div">
          <ProductCards />
        </div>
        <div className="footerchange-shopping">

          <Footer />
        </div>
      </main>
    </>
  )
})
export default AccountPage