import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import "./create-company.css"
import { useForm } from 'react-hook-form';

let renderCount = 0;




const CreateCompany = (() => {

    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    renderCount++;


    return (
        <>
            <div>
                <ul className="action-bar">
                    <li>Home /</li>
                    <li>E-commerce / </li>
                    <li className="createem"> Create Company </li>
                </ul>
            </div>
            <div className='createcompany-contain'>
                <Form className='booking-form-container' id='booking-form-container-id' onSubmit={handleSubmit((data, event) => {
                    event.target.reset()
                    // setDisabled(true)
                    console.log("data-create company", data);

                })}>
                    <p className='filld-form-create'>CREATE COMPANY</p>

                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label className='lb'>Company name</Form.Label>
                            <Form.Control placeholder="Enter your name" {...register("companyName", { required: "This field is required", })} />
                            <span className="cum-error">{errors.companyName?.message}</span>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3 " >
                        <Form.Group as={Col} controlId="formGridState">
                            <Form.Label className='lb' >Phone number</Form.Label>
                            <Form.Control type="number" placeholder="Phone number..." {...register("phoneNumber", { required: "This field is required", })} />

                            <span className="cum-error">{errors.companyName?.message}</span>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridCity">
                            <Form.Label className='lb'>Additional phone number</Form.Label>
                            <Form.Control type="number" placeholder=" Enter additional..." {...register("additionalNumber")} />
                        </Form.Group>



                        <Form.Group as={Col} controlId="formGridZip">
                            <Form.Label className='lb'>Email Address</Form.Label>
                            <Form.Control type="email" placeholder=" Enter your email..." {...register("email", { required: "This field is required", })} />
                            <span className="cum-error">{errors.email?.message}</span>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3 " >
                        <Form.Group as={Col} controlId="formGridState">
                            <Form.Label className='lb' >State</Form.Label>
                            <Form.Select defaultValue="Choose..." {...register("state", { required: "Select a State to procceed", })}>
                                <option>Choose...</option>
                                <option>Service center</option>
                                <option>Pickup/deliver</option>
                            </Form.Select>
                            <span className="cum-error">{errors.state?.message}</span>

                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridCity">
                            <Form.Label className='lb'>City</Form.Label>
                            <Form.Select defaultValue="Choose..." {...register("city")}>
                                <option>Choose...</option>
                                <option>Service center</option>
                                <option>Pickup/deliver</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>

                    <Form.Group className="mb-3" controlId="formGridAddress1">
                        <Form.Label className='lb'>Company Address</Form.Label>
                        <Form.Control placeholder="Enter your delivery address..." {...register("companyAddress", { required: "This field is required", })} />
                        <span className="cum-error">{errors.companyAddress?.message}</span>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formGridAddress2">
                        <Form.Label className='lb'>Additional Information </Form.Label>
                        <Form.Control placeholder="Enter additional info..."  {...register("additionalInfo")} />
                    </Form.Group>

                    <Button id='conorder' type="submit" className='submitbtn-create ' >
                        Create Company
                    </Button>

                </Form>
            </div>


        </>

    )

})
export default CreateCompany