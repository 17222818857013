import { AiOutlineMenuUnfold, AiOutlineShoppingCart } from "react-icons/ai"
import { BsFillPersonFill, BsJustifyRight, BsPersonCircle } from "react-icons/bs"
import { useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import {datas} from "./itemdata"
import React, { useEffect, useRef, useState } from "react"
import { BiSearchAlt } from "react-icons/bi"
import useFectch from "../adminpage/createbackend"
import { HiOutlineSearch } from "react-icons/hi"
import {mainaxios,fbaxios} from '../../utility/axios';
import { GoPersonFill } from "react-icons/go";


const EcoNAvSearch = (()=>{

  // const { data: datas, isPending, error } = useFectch("http://10.0.5.51:3018/api/v1/products/menus/DfUB4v5rKBZq4xH?locationId=ZVLyzRdwOI53vQp")
   
  const [datas, setDatas] = useState([]);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);

   const [mobile, setMobile] = useState("")

  useEffect(() => {
    setIsPending(true)
    fbaxios.get("products/menus/djW7G5kgB43wqdh?locationId=Njw9pXwT3BYIUMc")
      .then(response => {

        if (!response.statusText === "OK") {
          throw Error("Could not fetch data")
        }
        setIsPending(false)
        setDatas(response.data)

        setError(null)
       
      })
      .catch(error => {
        setError(error.message)
        setIsPending(false)
        console.log(error)
      })
  }, []);
  
  
  const data = datas.products;

  const totalCount = useSelector((state) => {

    return state.cart.items.length

  })
  const [display, setDisplay] = useState(false)

  const handleDisplay = (() => {
    setDisplay(true)
  })

  const [search, setSearch] = useState("")

  const handleDirect = (() => {

    setDisplay(false)
  })
 

  const handleSearchDisplay = ((event) => {
    setSearch(event.target.value)
    if (event.target.value == 0) {
      setDisplay(false)
    } else {
      setDisplay(true)
    }

  })

  const userData = JSON.parse(localStorage.getItem("ecomUserData"));

  let locationList1 = useRef();

  const navigate = useNavigate();

  useEffect(() => {
    const handler = (event) => {
      if (locationList1.current && !locationList1.current.contains(event.target)) {
        locationList1.current.style.display = 'none';
      }
    };

    document.addEventListener("click", handler);

    return () => {
      document.addEventListener("click", handler);
    };
  }, [locationList1]);

  const handleSelectedProduct = (product) => {
    navigate(
      `/goodsdetails/${product.comboId}`,
      {
        state: {
          product, name: 'Emeka cannot connect'
        }
      }

    )
    handleDirect()
  }
  
 const handleMobile = (()=>{

     setMobile(!mobile)
 })

 const handleLogin = (()=>{
      navigate("/signinpage",{})
 })
 const handleRegister = (()=>{
  navigate("/signuppage",{})
 })
 const handleAccount = (()=>{
  navigate("/mainprofile",{})
 })



return (

    <>
 <nav className="second-ecomercenav">
        {/* <div className=" w-100 " style={{ color: "white", textAlign: "center", padding: "1px", backgroundColor: "#030334" }} >  Ebeonadi e-commerce</div> */}
        <div className="control-serchBox">
            {
              userData ? (
                <div>
                <div className="nameDisplay_account" onClick={handleAccount}> <span className="hideMyAco">My</span> Account </div>
                <div  className="iconPersonHide" onClick={handleAccount} >
                <BsPersonCircle size={25} style={{marginLeft:"20px", marginTop:"18px"}} />
                <div className="meControl">me</div>
                </div>
                </div>
              ):(
                <ul className="myaccount-list">
                <li className="show_hideBig">
                <li className="link_LogBig" id="hideregiste"  onClick={handleLogin}><li>Login</li></li>
                <li to="/signuppage" className="linksty" onClick={handleRegister}><li id="hideregiste">Register</li></li>
                </li>
                <li className="liIcon_mobile" onClick={handleMobile}> <AiOutlineMenuUnfold size={30} /> </li>
                  
                   {
                    mobile && <li className="liIcon_mobile_div">
                    <li className="linksty" id="id-back-log" onClick={handleLogin}><li>Login</li></li>
                    <li to="/signuppage" className="linksty" onClick={handleRegister}><li id="hideregiste">Register</li></li>
                    </li>
                   }   
                  
                </ul>
              )
            }
         

          <Link className="linkstyle"  id="linkstyleProtude"    to="/accountpage"><i className="cart-icon"> <i className="badge " value={totalCount}> <span className="cartHideonm">Cart</span>  <AiOutlineShoppingCart size={25} className="carticon-up" /></i> </i> </Link>




          <form className="formWhite-contain" role="search">
           
            <input className="form-testers-white" placeholder="Search products..." onKeyDown={(() => handleDisplay())} onChange={handleSearchDisplay} /><BiSearchAlt size={20} className="searchIn-sec" type="submit" />
           
                {display && <div ref={locationList1} className="allsearch-drop">
                               {
                                isPending && 
                                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                               }

              {
                data.slice(0, 10).filter((product) => (
                  product.comboName.toLowerCase().indexOf(search) !== -1

                )).map ((name) => (

                  <ul className="ulcontrol-search">

                    <div  onClick={(() => handleSelectedProduct(name))} className="linktodisplay-search">
                      <li><i><img className="picSearch-control" src={name.picture} /> </i>
                        <span> {name.comboName} </span>
                      </li>
                    </div>
                  </ul>

                ))
              }



            </div>}

          </form>


        </div>
      </nav>

    
        
        </>
    )
})
export default EcoNAvSearch