import EcommerceNav from "./ecommerce_nav"
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Link, useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import Footer from "../layouts/footer";
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import { MdOutlineCancel } from "react-icons/md";
import { mainaxios, fbaxios } from "../../utility/axios"
import { TiArrowBackOutline } from "react-icons/ti";
import EcoNAvSearch from "./econavsearch";
import ModeHeader from "../layouts/modeheader";
import Changer from "../layouts/changer";

let renderCount = 0;

const SignUpPage = (() => {

  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  renderCount++;


  const password = useRef({});
  password.current = watch("password", "");

  const [states, setStates] = useState([]);
  const [localGvt, setLocalGvt] = useState([]);
  const [newLga, setNewLga] = useState([]);
  const [state, setState] = useState();
  const [stateName, setStateName] = useState('')
  const [city, setCity] = useState()
  const navigate = useNavigate()


  useEffect(() => {
    const getState = async () => {
      const resState = await axios.get('https://ipms.intellims.com:5300/api/v1/public/states')

      const relState = await resState.data
      setStates(await relState)
    }

    getState()
  }, [])


  useEffect(() => {
    const getLocalG = async () => {
      const localLga = await axios.get(
        "https://ipms.intellims.com:5300/api/v1/public/local_govt"
      );

      const localGvt = await localLga.data;

      setLocalGvt(localGvt)

    };


    getLocalG()
  }, [])


  const scrolltop = (() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"

    });
  })

  const getMainLGA = (e) => {
    const stateId = state
    const filterLga = localGvt.filter((lga) => (
      lga.state_id === stateId
    ))

    setNewLga(filterLga);
  };



  const [disabled, setDisabled] = useState(false)
  const [isPendings, setIsPendings] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {

    let timeout;
    if (success) {
      timeout = setTimeout(() => {
        setSuccess(false);
      }, 4000)
    }
    return () => {
      clearTimeout(timeout);
    };

  }, [success])

  const Navigate = useNavigate();

  const handleSubmited = async (data) => {
    setDisabled(true)
    setIsPendings(true);

    const datas = data.state = stateName

    await mainaxios.post("registrations/createUser_ebeonadiEcommerce", data)

      .then((res) => {


        if (res.data.success) {
          setIsPendings(false);
          setSuccess("User created successfully");

          setDisabled(false)
          Navigate("/signinpage", { state: {} });
        } else {
          setIsPendings(false);
          setErrorMessage("user already exist!");
          setDisabled(false)
        }

      }).catch((err) => {
        setIsPendings(false);

        setErrorMessage("Something went wrong. Please try again later.");
        setDisabled(false)
      })
    // console.log("networkcall", networkcall)
    scrolltop()
  }


  const closeErrorClick = (() => {
    setErrorMessage(false)
  })

  const notify = () => toast("Creating user...");

  const onChangeHandler = (e) => {
    setState()
    const newStateId = e.target.value
    setState(newStateId)
    const newState = states.find((stat) => stat.stateId === newStateId);

    setStateName(newState.name)

   
  }


  const goBack = (() => {
    navigate(-1, { state: {} })

  })


  return (

    <>
      {
        success && <div >

          <button className="btn btn-success toast-success-btn">{success}</button>

        </div>
      }
      {errorMessage && <div >
        <button className="btn btn-danger toast-success-btn">{errorMessage} <span onClick={closeErrorClick} className="remove-erroricon"><MdOutlineCancel size={25} /></span></button>

      </div>
      }

      {isPendings &&

        <div className="lds-ellipsis">Creating please wait<div></div><div></div><div></div><div></div></div>
      }
      <main className='mainbookinpage-all' id="mainbookinpage-allId">
        <div>
          <ModeHeader />
          <Changer />
        </div>
        <div>
          <EcoNAvSearch />
        </div>

        <div className='iconBackBtn-div' onClick={goBack}> <TiArrowBackOutline size={35} className='iconBackBtn' /> </div>

        <div className="signup-gen">

          <Form className='booking-form-container w-50 ' id="booking-form-container" onSubmit={handleSubmit((data, event) => {
            event.target.reset()
            setDisabled(true)
            handleSubmited(data)
          })} >
            <h3 className='filld-fo'>Sign up </h3>

            <Row className="mb-3 " >
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label className='lb'>First name <span className="star-required">*</span></Form.Label>
                <Form.Control placeholder="Enter your name"  {...register("firstName", { required: "This field is required", })} />
                <span className="cum-error">{errors.firstName?.message}</span>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label className='lb'>Last name <span className="star-required">*</span> </Form.Label>
                <Form.Control type="text" placeholder="Last name..." {...register("lastName", { required: "This field is required" })} />
                <span className="cum-error">{errors.lastName?.message}</span>
              </Form.Group>

            </Row>

            <Row className="mb-3 " >
              <Form.Group as={Col} controlId="formGridState">
                <Form.Label className='lb' >Phone number <span className="star-required">*</span></Form.Label>
                <Form.Control type="number" placeholder="Phone number..."  {...register("phoneNumber", { required: "This field is required" })}
                />
                <span className="cum-error">{errors.phoneNumber?.message}</span>
              </Form.Group>





              <Form.Group as={Col} controlId="formGridZip">
                <Form.Label className='lb'>Email Address <span className="star-required">*</span></Form.Label>
                <Form.Control type="email" placeholder=" Enter your email..." {...register("emailAddress", { required: "This field is required", })} />
                <span className="cum-error">{errors.emailAddress?.message}</span>
              </Form.Group>

            </Row>

            <Row className="mb-3 " >
              <Form.Group as={Col} controlId="formGridState">
                <Form.Label className='lb' >State <span className="star-required">*</span></Form.Label>
                <Form.Select defaultValue="Choose..."  {...register("state", { required: "This field is required", })} onChange={onChangeHandler} onClick={(e) => getMainLGA(e)}>
                  <option>Choose...</option>
                  {
                    states.map((state) => (
                      <option key={state.stateId} value={state.stateId}> {state.name} </option>
                    ))
                  }
                </Form.Select>
                <span className="cum-error">{errors.state?.message}</span>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridCity">
                <Form.Label className='lb'>City</Form.Label>
                <Form.Select defaultValue="Choose..." {...register("city")} onChange={(e) => setCity(e.target.value)} >
                  <option>Choose...</option>
                  {
                    newLga && newLga.map((lga, i) => (
                      <option key={i.id} value={lga.name}> {lga.name} </option>
                    ))
                  }
                </Form.Select>
              </Form.Group>



            </Row>
            <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label className='lb'>Address  <span className="star-required">*</span></Form.Label>
              <Form.Control placeholder="Enter your delivery address..." type="text" {...register("address", { required: "This field is required", })} />
              <span className="cum-error">{errors.address?.message}</span>
            </Form.Group>

            {/* <Row className="mb-2 "> */}
              <Form.Group className="mb-3" as={Col} controlId="formGridAddress1">
                <Form.Label className='lb'>Create password <span className="star-required">*</span></Form.Label>
                <Form.Control placeholder="Create login password..." name="password" type="password"  {...register("password", {
                  required: "You must specify a password", minLength: {
                    value: 6,
                    message: "Password must have atlest 6 characters"
                  }
                })} />
                <span className="cum-error">{errors.password?.message}</span>
              </Form.Group>

              <Form.Group className="mb-3" as={Col} controlId="formGridZip">
                <Form.Label className='lb'>Confirm password <span className="star-required">*</span></Form.Label>
                <Form.Control placeholder="Create login password..." type="password" name="password_repeat" {...register("password_repeat", {
                  validate: value =>
                    value === password.current || "The password does not match"
                })} />
                <span className="cum-error">{errors.password_repeat?.message}</span>
              </Form.Group>
            {/* </Row> */}
            <Button id='conorder' type="submit" disabled={disabled} onClick={notify} >
              Sign up  {
                isPendings && <span className="loader-load"></span>
              }
            </Button>
            <p className="mb-5 pb-lg-2 mt-2" style={{ color: '#393f81' }}>Already have an account? <Link to='/signinpage' className="navlink-style" ><a style={{ color: '#393f81' }}>Login here</a></Link></p>

            <div className='d-flex flex-row justify-content-start'>
              <a href="#!" className="small text-muted me-1">Terms of use.</a>
              <a href="#!" className="small text-muted">Privacy policy</a>
            </div>
          </Form>
        </div>
        <div>
          <Footer />
        </div>
        {scrolltop()}
      </main>
    </>
  )
})
export default SignUpPage