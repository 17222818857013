import { BiLogOutCircle } from "react-icons/bi"
import { BsFillPersonFill } from "react-icons/bs"
import { Link, useNavigate, useNavigation } from "react-router-dom"
import logo from "../images/logo fm2.jpg"


import { CCollapse, CContainer, CNavLink, CNavbar, CNavbarBrand, CNavbarNav, CNavbarToggler } from '@coreui/react';
import { useState } from "react";



const GuestOrderNav = (()=>{
    const [visible, setVisible] = useState(false);

    return(
        <>
        <CNavbar expand="lg" className="control-backgroundnav" id="control-backgroundnavssss">
      <CContainer fluid>
     <Link to="/ecommerceupper" style={{textDecoration:"none", position:"absolute", zIndex:"100" }}><CNavbarBrand  className='colapse-nav-profileNav'><img src={logo} style={{ width: "20px", height: "20px" }} />   Ebeonadi E-commerce</CNavbarBrand></Link>
        {/* <CNavbarToggler
          aria-label="Toggle navigation"
          aria-expanded={visible}
          onClick={() => setVisible(!visible)}
          className='back-dropIcon'
          id="backDrop-Remove"
        /> */}

        <CCollapse className="navbar-collapse colapse-nav-profile" visible={visible}>
          <CNavbarNav component="nav" className='navcontrol-push'>
         <Link to="/signinpage" className="proHide-link"> <CNavLink  className='colapse-nav-profiless' > <li>Login</li></CNavLink></Link>
          <Link to="/signuppage" className="proHide-link"><CNavLink  active className='colapse-nav-profile'>
            Create account
            </CNavLink></Link> 
           
            {/* <CNavLink onClick={handleLogout} className='colapse-nav-profile'>Log out <BiLogOutCircle className="logout-take"/></CNavLink> */}
           
          </CNavbarNav>
        </CCollapse>
      </CContainer>
    </CNavbar>

        
        </>
    )
})
export default GuestOrderNav