import Newsfeed from "../layouts/newsfeed";
import MainNews from "./secondheader";
import Newspic1 from "../images/Newspapers-.jpg";
import sidewspic1 from "../images/news1.jpg";
import sidewspic2 from "../images/news2.jpg";
import sidewspic3 from "../images/news3.jpg";
import ReplyBox from "./replybox";
import Footer from "../layouts/footer";
import Changer from "../layouts/changer";
import ModeHeader from "../layouts/modeheader";
import FirstHeader from "../layouts/firstheader";

import { NavLink } from "react-router-dom";
import useFectch from "../adminpage/createbackend";
import Card from 'react-bootstrap/Card';
import moment from "moment";


const NewsPage =()=>{

  const {data:general,isPending,error} = useFectch('programs/getNews');

  const generalNews = 
  general && general.filter((local) => (
    local.newsType.includes('General') && local.onMainBody.includes("true") 

  ))
  

return(
  <>
  {/* <MainNews/>
  <Newsfeed/> */}
  <div className="overflowdiv">
  <Changer/>
        <ModeHeader/>
        <FirstHeader/>
        <Newsfeed/>


        
        <h2 className="sidenews-head">General News</h2>






         

    {/* <div className="newsdiv-container" > */}


    
  <div className="container-alt row row-cols-1 row-cols-md-4 g-4" >

  {isPending && <div className="loaderless"> <div className="lds-ripple"> News loading... <div className="blueload"></div><div className="darkorangeload"></div></div>
          </div>}
          {error && <div className="errorssample">{error}</div>}
   
   

    
  {

generalNews.length === 0 && !isPending ?(
<div className="noNewsText">Opps! no  general news to display, please check back later</div>
):(


        general && general.filter((local) => (
          local.newsType.includes('General') && local.onMainBody.includes("true") 

        )).map((news) => (

  <div className="card-alt"  key={news.newsId} id="mobilenews-display" >
    <div className="card__header">
            <img src={news.image} alt="card__image" className="card__image" id="imagenewsid" /> 
    </div>
    <NavLink  to={`/topnews/${news.newsId}`} className="newsnav-general">
    <div className="card__body" >
      <span className="tag tag-blue">{news.newsType}</span>
      <h4 className="newstext-alt">{news.newsTopic}</h4>
      
      <div className="user">{moment(news.startDateTime).fromNow()
                } </div>
   
      
    </div>
    </NavLink>
   
  </div>
  ))
  )
}
</div>




{/* 
    {isPending && <div className="loaderless"> <div className="lds-ripple"> Loading... <div></div><div></div></div>
          </div>}
          {error && <div className="errorssample">{error}</div>}

      {
        general && general.filter((local) => (
          local.newsType.includes('General')

        )).map((news) => (
          <div className="side-news-div" key={news.newsId} >
            
            <div className="new-container">
            <NavLink to={`/topnews/${news.newsId}`} className="newsimage-container">
            <div className="newsmobile-div-container"> 
            <div className="image-mobilediv">  <img className="newsimage" src={news.image} /></div> 
            <div className="topicmobile-div">  <p className="news-p"> {news.newsTopic} </p></div>
            </div>
              </NavLink>

              
              <hr className="underline" />
            </div>

            
          </div>
        ))

      }
      <br /><br /><br /><br />

 */}


    {/* </div> */}


    
    <Footer />
    </div>
  </>

)
}
export default NewsPage
