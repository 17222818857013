import EcommerceNav from "./ecommerce_nav"
import image1 from "../images/image-buys.png"
import EcommerceGoods from "./ecommerce-goods"
import EcommmerceFooter from "./ecocommercefooter"
import Footer from "../layouts/footer"
import { Link } from "react-router-dom"
import EcoNAvSearch from "./econavsearch"
import { useState } from "react"
import ModeHeader from "../layouts/modeheader"
import Changer from "../layouts/changer"



const EcommerceUpper = (() => {



    return (

        <>
            <main className='mainbookinpage-all'>
                <div>
                    <div>
                        {/* <Changer/> */}
                        <ModeHeader />

                    </div>
                    <div>
                        <EcoNAvSearch />
                    </div>
                </div>


                <div>

                    <EcommerceGoods />
                </div>

                <div>
                    <Footer />
                </div>
            </main>
        </>
    )
})
export default EcommerceUpper