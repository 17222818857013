import { MDBBtn, MDBContainer, MDBCard, MDBCardBody, MDBCardImage, MDBRow, MDBCol, MDBIcon, MDBInput, MDBCheckbox }
  from 'mdb-react-ui-kit';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import EcommerceNav from './ecommerce_nav';
import Footer from '../layouts/footer';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { mainaxios, fbaxios } from "../../utility/axios";
import { useDispatch, useSelector } from 'react-redux';
import { TiArrowBackOutline } from 'react-icons/ti';
import ModeHeader from '../layouts/modeheader';
import EcoNAvSearch from './econavsearch';
import Changer from '../layouts/changer';


let renderCount = 0;

const SignInPage = (() => {


  const { register, handleSubmit, formState: { errors } } = useForm();
  renderCount++;


  const handleSubmitClick = (() => {
    handleSubmit()

  })

  const navigate = useNavigate()
  const [isPendings, setIsPendings] = useState(false);
  const [errorMessage, setErrorMessage] = useState(' ')
  const [disabled, setDisabled] = useState(true)

  const { items } = useSelector((state) => state.cart);
  // const dispatch = useDispatch();


  const handleSubmited = async (data) => {
    setDisabled(false);
    setIsPendings(true);


    await mainaxios.post("registrations/login_ebeonadiEcommerce", data)

      .then((res) => {

        if (res.data.success === true) {
          const ecomUserData = res.data.userDetails
          localStorage.setItem("ecomUserData", JSON.stringify(ecomUserData))
          setIsPendings(false);
          setDisabled(true);
          if (items.length === 0) {
            navigate("/mainprofile", { state: {} })
          } else {
            navigate("/bookingpage", { state: {} })
          }
        } else if (res.data.success === false) {
          setIsPendings(false);
          setErrorMessage(res.data.message)
        } else {
          setIsPendings(false);

        }

      }).catch((err) => {

        setIsPendings(false);
        setDisabled(true);
      })
    // console.log("networkcall", networkcall)

  }
  const scrolltop = (() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"

    });
  })

  const goBack = (() => {
    navigate(-1, { state: {} })

  })

  return (

    <>
      <main className='mainbookinpage-all' id='mainbookinpage-allId'>

        <div>
          <ModeHeader />
          <Changer />
        </div>
        <div>
          <EcoNAvSearch />
        </div>
        <div className='iconBackBtn-div' onClick={goBack}> <TiArrowBackOutline size={35} className='iconBackBtn' /> </div>



        <MDBContainer fluid className='signupcontainer'>

          <MDBRow className='d-flex justify-content-center align-items-center h-100'>
            <MDBCol col='12'>

              <MDBCard className='bg-white my-5 mx-auto' style={{ borderRadius: '1rem', maxWidth: '500px' }}>
                <form onSubmit={handleSubmit((data, event) => {
                  event.target.reset()
                  handleSubmited(data)


                })}>
                  <MDBCardBody className='p-5 w-100 d-flex flex-column'>

                    <h2 className="fw-bold mb-2 text-center">Login</h2>
                    <p className="text-white-50 mb-3">Please enter your login and password!</p>
                    <label>Email</label>
                    <MDBInput wrapperClass='mb-4 w-100' id='formControlLg' type='email' size="lg"  {...register("emailAddress", { required: "This field is required" })} />
                    <span className="cum-error">{errors.emailAddress?.message}</span>
                    <label>Password</label>
                    <MDBInput wrapperClass='mb-4 w-100' id='formControlLg' type='password' size="lg"  {...register("password", { required: "Password is required" })} />
                    <span className="cum-error">{errors.password?.message}</span>
                    <Link to="/forgot_password"><span className='forgetPassword-btn'>Forgot password?</span></Link>

                    <MDBCheckbox name='flexCheck' id='flexCheckDefault' className='mb-4' label='Remember password' />
                    <p>Don't have an account <Link to="/signuppage"><span> Register Now</span></Link></p>

                    <button size='lg' className='login-btn-login w-100 btnlogin ' type='submit'
                      onClick={disabled}
                    >
                      {!isPendings ? <span>Login</span> : <span className="loader-load"></span>}
                    </button>

                    {errorMessage && <div className="errlogin">{errorMessage}</div>}


                  </MDBCardBody>
                </form>
              </MDBCard>

            </MDBCol>
          </MDBRow>

        </MDBContainer>

        <div>
          <Footer />
        </div>
      </main>
      {scrolltop()}
    </>
  )
})
export default SignInPage