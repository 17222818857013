

import { RiArrowDropDownLine } from "react-icons/ri"
import { useState } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useFectch from "./createbackend";
import Dropdown from 'react-bootstrap/Dropdown';
import { mainaxios, fbaxios } from "../../utility/axios"
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from "react";
import { MdOutlineCancel } from "react-icons/md"
import moment from "moment";

let renderCount = 0;
function ViewNews() {

  const { register, handleSubmit, reset } = useForm();
  renderCount++;

  const [formData, setFormData] = useState({});

  const [isPendings, setIsPendings] = useState(false);

  const [action, setAction] = useState('')
  const [show, setShow] = useState(false)
  const [lgShow, setLgShow] = useState(false);
  const [getNews, setGetNews] = useState([]);

  const actionButton = () => {
    setAction(!action)

  }
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showMore, setShowMore] = useState(false)
  const [trys, setTrys] = useState(false);
  const [shows, setShows] = useState(false)
  const [data, setData] = useState()
  const { data: news, isPending, error } = useFectch('programs/getNews')


  
  const update = () => {

    mainaxios.get("programs/getNews")
      .then((res) => {
        setGetNews(res.data);
      });
  };
  useEffect(update, [getNews]);





  const handleDelete = (newsId) => {
    const newData = data.filter((news) => news.newsId !== newsId);
    setData(newData)


  }
  const notify = () => toast("Processing your request...");

  const onSubmit = (data) => {

    setFormData(data)
    reset();

  }


  /////delete/////////////////////////////////////

  const [errorMess, setErrorMess] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [employeeData, setEmployeeData] = useState()
  const [newsID, setNewsID] = useState("")

  const handleSubmitDelete = (event) => {
    event.preventDefault();
    mainaxios.put(`programs/removeNews/${newsID}`)
      .then(response => {

        if (response) {
          setSuccessMessage("News Removed Successfully");
          
        } else {

          setSuccessMessage("News Removed Successfully");
        }
      })
      .catch(error => {
        console.log(error);
      });

    setLgShow(false)
  }

  const toggleDelete = (news) => {

    setNewsID(news.newsId)
    notify();
    setLgShow(true);
  }


  /////Update news//////////////////////////////////////////
  const [isPendingUpdate, setIsPendingUpdate] = useState("")

  const handleImageChange = (e) => {
    console.log(e.target.files)
    setImage(e.target.files[0]);
  };

  const toggleNews = (news) => {

    setNewsID(news.newsId)
    setEmployeeData(news)
    setShow(true)

  }
  const [topic, setTopic] = useState('');
  const [newsCategory, setNewsCategory] = useState('');
  const [scrollBar, setScrollBar] = useState('');
  const [startDateTime, setStartDateTime] = useState('');
  const [endDateTime, setEndDateTime] = useState('');
  const [onMainBody, setOnMainBody] = useState('');
  const [startDateAppearance, setStartDateAppearance] = useState('');
  const [endDateAppearance, setEndDateAppearance] = useState('');
  const [newsDetails, setNewsDetails] = useState('');
  const [newsType, setNewsType] = useState('');
  const [image, setImage] = useState(null);

  const initialValues = {
    topic: employeeData && employeeData.topic,
    newsCategory: employeeData && employeeData.newsCategory,
    scrollBar: employeeData && employeeData.scrollBar,
    startDateTime: employeeData && employeeData.startDateTime,
    endDateTime: employeeData && employeeData.endDateTime,
    onMainBody: employeeData && employeeData.onMainBody,
    startDateAppearance: employeeData && employeeData.startDateAppearance,
    endDateAppearance: employeeData && employeeData.endDateAppearance,
    newsType: employeeData && employeeData.newsType,
    newsDetails: employeeData && employeeData.newsDetails,
  }
  const [formValues, setFormValues] = useState(initialValues)

  const handleSubmits = async (data) => {
    setFormValues({ ...formValues })


    const formData = new FormData();
    formData.append("topic", topic);
    formData.append("newsCategory", newsCategory);
    formData.append("scrollBar", scrollBar);
    formData.append("startDateTime", startDateTime);
    formData.append("endDateTime", endDateTime);
    formData.append("onMainBody", onMainBody);
    formData.append("startDateAppearance", startDateAppearance);
    formData.append("endDateAppearance", endDateAppearance);
    formData.append("newsDetails", newsDetails);
    formData.append("newsType", newsType);
    formData.append("image", image);
    setIsPendings(true)


    await mainaxios.put(`programs/updateNews/${newsID}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {

        if (res.data.success) {
          setSuccessMessage("News Updated Successfully");
         
          setIsPendingUpdate(false)
          setIsPendings(false)
        } else {
          setSuccessMessage("News Updated");
          setIsPendingUpdate(false)
          setIsPendings(false)
        }

      }).catch((err) => {

        setErrorMess("Something went wrong. Please try again later.");
        setIsPendingUpdate(false)
        setIsPendings(false)
      })
  }
  const handleCloseUpdate = () => {
    handleSubmits()
    setShow(false)
    notify()

  }

  useEffect(() => {

    let timeout;
    if (successMessage) {
      timeout = setTimeout(() => {
        setSuccessMessage(false);
      }, 3000)
    }
    return () => {
      clearTimeout(timeout);
    };

  }, [successMessage])

  const closeErrorClick = (() => {
    setErrorMess(false)
  })




  return (

    <>


      <div>
        <ul className="action-bar">
          <li>Home /</li>
          <li>News/</li>
          <li className="createem">View News</li>
        </ul>
      </div>
      {
        successMessage && <div >

          <button className="btn btn-success toast-success-btn">{successMessage}</button>

        </div>
      }
      {errorMess && <div >
        <button className="btn btn-danger toast-success-btn">{errorMess} <span onClick={closeErrorClick} className="remove-erroricon"><MdOutlineCancel size={25} /></span></button>

      </div>
      }

      {/* {isPendingUpdate && <Alert onClose={() => {}} className="alert-display-positive">Processing...</Alert>} */}



      <h2 className="viewemployee-head">News List</h2>

      <Table striped bordered hover size="sm" className='adaogidi-table'>
        <thead>
          <tr>
            <th>S/N</th>
            <th>Topic</th>
            <th>News Type</th>
            <th>News Category</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Details</th>
            <th>Created by</th>

            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {isPending && <div className="loaderless"> <div className="lds-ripple"> Loading... <div></div><div></div></div>

          </div>}

          {getNews && getNews.map((news, i) => (

            <tr key={news.newsId} >
              <td>{i + 1}</td>
              <td>{news.newsTopic} </td>
              <td>{news.newsType}</td>
              <td>{news.newsCategory}</td>
              <td>{moment(news.startDateTime).format('lll')}</td>
              <td>{moment(news.endDateTime).format('lll')}</td>
              <td>{showMore ? news.newsDetails : news.newsDetails.substring(0, 100)}...{news.newsDetails.lenght > 80 && showMore && <span>show more...</span>}  </td>
              <td>{news.createdBy}</td>


              <Dropdown className="d-inline mx-2 firstline-drop " autoClose="outside"  >
                <Dropdown.Toggle id="dropdown-autoclose-outside">
                  Action
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdownshow-d">
                  <Dropdown.Item className="sideside" onClick={() => toggleNews(news)}  >Update</Dropdown.Item>
                  <Dropdown.Item className="sideside" onClick={() => toggleDelete(news)} >Delete News</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </tr>
          ))}
        </tbody>
      </Table>


      <form onSubmit={handleSubmits}>

        {isPendings &&
          <div style={{ display: isPendings ? 'flex' : 'none' }} className='modaled'>
            <div className='modal-contents'>
              <div className='loadered'></div>
              <div className='modal-texted'>Loading...</div>
            </div>
          </div>

        }

        <Modal show={show} onHide={handleClose} animation={false} className="employupdate-modal"
          onSubmit={handleSubmit(onSubmit)}

        >
          <Modal.Header closeButton>
            <Modal.Title>Update News</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label  > Topic</label> <br />
            <input className="contentname" name="topic"
              defaultValue={employeeData && employeeData.topic} onChange={(e) => setTopic(e.target.value)}

            />

            <p className="cum-error"></p>

            <label>News Type</label><br />
            <select className="newstopic-inputs" name="newsCategory"
              defaultValue={employeeData && employeeData.newsCategory} onChange={(e) => setNewsCategory(e.target.value)}
            >

              <option value="Paid news">Paid news</option>
              <option value="Company news">Company News</option>

            </select> <br /> <br />
            <label>Appear on Sroll bar?</label><br />
            <label className="news-radios" >Yes</label>
            <input className="radionews" type="checkbox" name="scrollBar" value={true}
              defaultValue={employeeData && employeeData.scrollBar} onChange={(e) => setScrollBar(e.target.value)}

            /><br />

            <label className="news-radio">No</label>
            <input className="radionews" type="checkbox" name="scrollBar" value={false}
              defaultValue={employeeData && employeeData.scrollBar} onChange={(e) => setScrollBar(e.target.value)}
            /><br />

            <label>Start Date Time</label><br />
            <input className="newstopic-inputs" type="datetime-local" name="startDateTime"
              defaultValue={employeeData && employeeData.startDateTime} onChange={(e) => setStartDateTime(e.target.value)}
            /><br />

            <label>End Date Time</label><br />
            <input className="newstopic-inputs" type="datetime-local" name="endDateTime"
              defaultValue={employeeData && employeeData.endDateTime} onChange={(e) => setEndDateTime(e.target.value)}
            /><br /><br />


            <input type="checkbox" className="markbody" name="onMainBody"
              defaultValue={employeeData && employeeData.onMainBody} onChange={(e) => setOnMainBody(e.target.value)}
            />
            <label>Appear on Main Body?</label>
            <br />

            <label>Start Date Time Appearance</label><br />
            <input className="newstopic-inputs" type="datetime-local" name="startDateAppearance"
              defaultValue={employeeData && employeeData.startDateAppearance} onChange={(e) => setStartDateAppearance(e.target.value)}

            /><br /><br />


            <label>End Date Time Appearance</label><br />
            <input className="newstopic-inputs" type="datetime-local" name="endDateDisappearance"
              defaultValue={employeeData && employeeData.endDateAppearance} onChange={(e) => setEndDateAppearance(e.target.value)}
            /><br /><br />


            <label>News Category</label><br />

            <select className="newstopic-inputs" name="newsType"
              defaultValue={employeeData && employeeData.firstName} onChange={(e) => setNewsType(e.target.value)}
            >
              <option value="General News">General News</option>
              <option value="Local News"  >Local News</option>
              <option value="tainmentNews" >Entertainment News</option>
              <option value="Sports"  >Sports</option>
              <option value="Politics" >Politics News</option>
              <option value="Cultural News"   >Culture News</option>
            </select><br />
            <label>News Details</label> <br />
            <textarea className="news-detailsed" name="newsDetails"
              defaultValue={employeeData && employeeData.newsDetails} onChange={(e) => setNewsDetails(e.target.value)}
            /> <br /> <br />
            <label>Change Image</label> <br />

            <input type="file" className="content" onChange={handleImageChange} required />
            <img className="edit-image-cont" src={employeeData && employeeData.image} />

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit" onClick={handleCloseUpdate}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </form>




      {/* remove news */}
      <form onSubmit={handleSubmitDelete}  >
        <Modal
          size="sm"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg"  >
              Remove
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are You Sure You Want to Remove the Selected News?</p>
            <button className="btn-remove btn btn-danger" type="submit" onClick={handleSubmitDelete} >Remove</button>
          </Modal.Body>



        </Modal>
      </form>
    </>
  );
}

export default ViewNews;









