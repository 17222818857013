import { MDBBtn, } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { BiMinus } from "react-icons/bi";
import { BsPlus, BsPlusLg } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../layouts/footer";
import { AiOutlineMinus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getCartTotal, increase, decrease, remove, clearCart, getCartItems } from "../../redux/counter";
import { current } from "@reduxjs/toolkit";
import { datas } from "./itemdata"
import { GiReturnArrow } from "react-icons/gi";
import { Button, Modal } from "react-bootstrap";
import { MdPerson3 } from "react-icons/md";


function PaymentMethods() {

    const [exceed, setExceed] = useState(false)
    const [modalShow, setModalShow] = useState(false);
    const [lgShow, setLgShow] = useState(false);
    const [disabled, setDisabled] = useState(false)

    const Navigate = useNavigate();
    const userData = JSON.parse(localStorage.getItem("ecomUserData"));


    const handleFirstModal = (() => {
        if (userData) {
            Navigate("/bookingpage", { state: {} })
        } else {
            setModalShow(true);
            setLgShow(false);
        }

  })
    // currency format//
    const symbol = Intl.NumberFormat('en-US', {
        style: 'currency',
        currencyDisplay: 'narrowSymbol',
        currency: 'NGN'
    })
        .formatToParts(0)
        .filter(part => part.type === 'currency')
        .map(part => part.value)
        .join('')
    // 

    const { items, totalAmount, hasError } = useSelector((state) => state.cart);
    const dispatch = useDispatch();

    const totalCount = useSelector((state) => {

        return state.cart.items.length

    })

    const [deactivate, setDeactivate] = useState()

    const [disable, setDisable] = useState(false)
    //console.log("disable>>", dispatch(decrease()));

    useEffect(() => {
        dispatch(getCartTotal())
    }, [items])


    if (items.amount === 1) {
        setDisable(true)
    }

    const handleDecrease = ((product) => {
        if (product.quantity > 1) {
            dispatch(decrease(product.comboId))
            setExceed(false)
        } else {

        }

    })

    const handleCurrentQuantity = ((product, action) => {
        let Error;
        const currentQuantity = product.fields.map((fields) => (
            fields.fieldItems.map((item) => (
                item.currentQuantity

            ))

        ))
    if (product.quantity < currentQuantity) {
            dispatch(increase(product.comboId))
            setExceed(false)
        } else {
            return null
        }

    })

    useEffect(() => {
        const disableBtn = items && items.map((product) => (
            product.fields && product.fields.map((field) => (
                field.fieldItems && field.fieldItems.map((item) => (
                    item.currentQuantity

                ))
            ))
        ))
        if (disableBtn === 0) {
            setDisabled(true)
        }

    }, [])

if (items.length === 0) {
        return (
            <>
                <div style={{ textAlign: "center" }} className="container-sp">
                    <h3 className="fs-bold mt-5 " style={{ textAlign: "center" }}>
                        Your shopping cart is empty
                    </h3>
                    {/* <MDBBtn onClick={()=>dispatch(getCartItems())}> Get items </MDBBtn> */}
                    <Link to="/ecommerceupper" className="remove-linkline">  <a className="bn13 " onClick={() => dispatch(getCartItems())}>Go back to shopping <GiReturnArrow /></a>   </Link>
                </div>
            </>
        )
    }
    return (

        <div className=" container-sp">
            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-md-9 inner-cartstyle">

                        <div className="ibox">
                            <div className="ibox-title">
                                <span className="pull-right">(<strong> {totalCount} </strong>) items</span>
                                <h5>Items in your cart</h5>
                            </div>
                            <div className="ibox-content">
                                {
                                    items && items.map((product) => (


                                        <div className="table-responsive" key={product.comboId} >

                                            <table className="table shoping-cart-table">
                                                <tbody>
                                                    <tr className="tr-container-product">
                                                        <div>
                                                            <img className="cart-product-imitation" src={product.picture} />
                                                        </div>
                                                        <div className="desc">
                                                            <h2> {product.comboName} </h2>
                                                            <div className="descriptioncover">
                                                                <h5>Description </h5>
                                                                <dd className="description-Text">{product.description} </dd>
                                                            </div>
                                                            <td className="price-back">
                                                                <span>
                                                                    Price:  <span className="boldtesting"> {symbol}  {product.price}  </span>
                                                                </span>

                                                            </td>


                                                            <div className="controlcounter" key={product.comboId}>
                                                                <div className="tractminus" key={product.comboId} onClick={() => handleDecrease(product)} disabled={disable} value={product.quantity} onChange={() => setDeactivate(product.quantity)}><i><AiOutlineMinus size={30} /></i></div>
                                                                <div className="incrementnum"> <span> {product.quantity} </span></div>
                                                                <div className="trackplus" key={product.comboId} onClick={() => handleCurrentQuantity(product)} > <i><BsPlus size={30} /></i></div>
                                                            </div>

                                                            <div className="AlertRed-quantity">

                                                            {
                                         product.fields?.[0]?.fieldItems?.[0]?.currentQuantity <= product.quantity ? (
                                       <span key={product.comboId}>Cannot exceed available quantity!</span>
                                            ) : null
                                                }
                                                            </div>
                                                            <div className="m-t-sm removeDiv" >

                                                                <a className="text-muted" onClick={() => dispatch(remove(product.comboId))} ><i className="fa fa-trash" ></i> Remove <span className="item-itemRemove">item</span></a>
                                                            </div>
                                                            <hr />

                                                        </div>


                                                    </tr>


                                                </tbody>
                                            </table>

                                        </div>

                                    ))
                                }

                            </div>
                            <hr />
                            <div className="ibox-content">

                                <Link to="/ecommerceupper"> <button className="btn btn-white continuebtn-shopping "><i className="fa fa-arrow-left"></i> Continue shopping</button></Link>



                                <button className="button-24" role="button" onClick={() => dispatch(clearCart())} >Clear Cart</button>

                            </div>
                        </div>




                    </div>
                    <div className="col-md-3">
                        <div className="ibox">
                            <div className="ibox-title">
                                <h5>Cart Summary</h5>
                            </div>
                            <div className="ibox-content">
                                <span className="totalspan">
                                    Total Amount
                                </span>
                                <h2 className="font-bold">
                                    {symbol}{totalAmount}
                                </h2>

                                <hr />

                                <div className="m-t-sm">
                                {
items?.[0]?.fields?.[0]?.fieldItems?.[0]?.currentQuantity === 0 ? (
<button className="button-6" role="button" disabled onClick={handleFirstModal}>
CHECKOUT
</button>
) : (
<button className="button-6" role="button" onClick={handleFirstModal}>
CHECKOUT
</button>
)
}




                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </div>

            <Modal
                size="lg"
                show={modalShow}
                onHide={() => setModalShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <h4>Checkout the selected product(s)</h4>
                    <p>

                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Link to="/signinpage"><a className="bn13 " >Login to continue<MdPerson3 /></a> </Link>
                    <Link to="/guestOrder"> <Button className='btn btn-secondary' >Continue as guest</Button></Link>
                </Modal.Footer>
            </Modal>



        </div>




    );
}
export default PaymentMethods